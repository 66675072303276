<template>
    <div class="search-view card">

        <loading-bar :loading="loading"/>

        <div class="card-header">
            <h4 class="card-title">
                Результаты поиска<span v-if="isResultsScoped"> по категории {{category.title}}</span><span v-if="query">: <em>{{ query }}</em></span>
            </h4>
        </div>

        <div class="card-body px-0">
            <tasks-table :tasks="results"
                         :highlight-query="query"
                         empty-text="Нет задач, соответствующих критериям поиска"
                         :show-silent-tasks="true"
                         :show-brief-column="true"
                         :show-category-column="true"
                         :show-creator-column="false"
                         :show-performer-column="false"
                         :default-sort="{prop:'weight', order:'asc'}"
            />
        </div>

        <div class="card-footer" v-show="showFetchMoreButton">
            <el-button :loading="loading" @click="fetchMore" class="btn-block">Ещё...</el-button>
        </div>

    </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import TasksTable from '../components/TasksTable/TasksTable';
  import LoadingBar from '../components/LoadingBar/LoadingBar';

  export default {
    name: "SearchView",
    components: {LoadingBar, TasksTable},
    computed: {
      ...mapGetters("search", ["results", "query", "loading", "fullyLoaded", "isResultsScoped"]),
      ...mapGetters("categories", {
        category: "loaded"
      }),
      showFetchMoreButton: state => state.query && !state.fullyLoaded,
      categoryId: state => state.$route.params.categoryId || null,
    },
    methods: mapActions("search", ["fetchMore"]),
    mounted() {
      if (this.categoryId) {
        this.$store.dispatch('categories/fetch', this.categoryId)
      }
    }
  }
</script>
