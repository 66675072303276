import _ from "lodash";
import {CoreApi, handleError} from "../../lib/HttpApi";

const TASKS_PUSH = "TASKS_PUSH";

const TASKS_PAGINATION = "TASKS_PAGINATION";
const TASKS_PAGINATION_ACTIVE = "TASKS_PAGINATION_ACTIVE";
const TASKS_PAGINATION_CLOSED = "TASKS_PAGINATION_CLOSED";

const TASKS_IN_PROGRESS_FETCH = "TASKS_IN_PROGRESS_FETCH";
const TASKS_IN_PROGRESS_FETCH_SUCCESS = "TASKS_IN_PROGRESS_FETCH_SUCCESS";
const TASKS_IN_PROGRESS_REFETCH_SUCCESS = "TASKS_IN_PROGRESS_REFETCH_SUCCESS";
const TASKS_IN_PROGRESS_FETCH_FAILED = "TASKS_IN_PROGRESS_FETCH_FAILED";

const TASKS_IN_PROGRESS_COUNT = "TASKS_IN_PROGRESS_COUNT";
const TASKS_IN_PROGRESS_COUNT_SUCCESS = "TASKS_IN_PROGRESS_COUNT_SUCCESS";
const TASKS_IN_PROGRESS_COUNT_FAILED = "TASKS_IN_PROGRESS_COUNT_FAILED";
const TASKS_REMOVE_FROM_COLLECTION = "TASKS_REMOVE_FROM_COLLECTION";
const TASKS_TASK_STATE_CHANGED = "TASKS_TASK_STATE_CHANGED";

const TASKS_FETCH = "TASKS_FETCH";
const TASKS_FETCH_SUCCESS = "TASKS_FETCH_SUCCESS";
const TASKS_FETCH_FAILED = "TASKS_FETCH_FAILED";

const TASKS_FETCH_ACTIVE = "TASKS_FETCH_ACTIVE";
const TASKS_FETCH_ACTIVE_SUCCESS = "TASKS_FETCH_ACTIVE_SUCCESS";
const TASKS_FETCH_ACTIVE_FAILED = "TASKS_FETCH_ACTIVE_FAILED";

const TASKS_FETCH_CLOSED = "TASKS_FETCH_CLOSED";
const TASKS_FETCH_CLOSED_SUCCESS = "TASKS_FETCH_CLOSED_SUCCESS";
const TASKS_FETCH_CLOSED_FAILED = "TASKS_FETCH_CLOSED_FAILED";

const TASKS_COUNT_ACTIVE = "TASKS_COUNT_ACTIVE";
const TASKS_COUNT_ACTIVE_SUCCESS = "TASKS_COUNT_ACTIVE_SUCCESS";
const TASKS_COUNT_ACTIVE_FAILED = "TASKS_COUNT_ACTIVE_FAILED";

const TASKS_COUNT_ACTIVE_CREATED = "TASKS_COUNT_ACTIVE_CREATED";
const TASKS_COUNT_ACTIVE_CREATED_SUCCESS = "TASKS_COUNT_ACTIVE_CREATED_SUCCESS";
const TASKS_COUNT_ACTIVE_CREATED_FAILED = "TASKS_COUNT_ACTIVE_CREATED_FAILED";

const TASKS_COUNT_ACTIVE_PERFORMED = "TASKS_COUNT_ACTIVE_PERFORMED";
const TASKS_COUNT_ACTIVE_PERFORMED_SUCCESS = "TASKS_COUNT_ACTIVE_PERFORMED_SUCCESS";
const TASKS_COUNT_ACTIVE_PERFORMED_FAILED = "TASKS_COUNT_ACTIVE_PERFORMED_FAILED";

const state = {
    pagination: {
        active: {
            loaded: false,
            perPage: 10,
            currentPage: 1,
            lastPage: null,
            total: null,
            from: null,
            to: null
        },
        closed: {
            loaded: false,
            perPage: 10,
            currentPage: 1,
            lastPage: null,
            total: null,
            from: null,
            to: null
        }
    },
    errors: [],
    subscriptions: {
        inProgress: null,
        events: null
    },
    collection: [],
    collections: {
        active: [],
        closed: [],
        inProgress: []
    },
    counters: {
        performedByMe: 0,
        createdByMe: 0,
        inProgress: 0
    },
    isLoaded: false,
    loading: false
};

const getters = {
    pagination: state => state.pagination,
    activePagination: state => state.pagination.active,
    closedPagination: state => state.pagination.closed,
    loading: state => state.loading,
    isLoaded: state => state.isLoaded,
    counters: state => ({
        performedByMe: state.counters.performedByMe,
        createdByMe: state.counters.createdByMe,
        inProgress: _.uniqBy(state.collections.inProgress.filter(task => task.current_state && !task.current_state.is_terminal && (!task.parent || task.parent.performer_id !== task.performer_id))
            .reverse(), "id").length
            ? _.uniqBy(state.collections.inProgress.filter(task => task.current_state && !task.current_state.is_terminal && (!task.parent || task.parent.performer_id !== task.performer_id))
                .reverse(), "id").length
            : state.counters.inProgress
    }),
    errors: state => state.errors,
    tasks: state => state.collection,
    collection: state => _.uniqBy(state.collection.reverse(), "id"),
    collections: state => state.collections,
    activeCollection: state => _.uniqBy(state.collections.active.filter(task => task.current_state && !task.current_state.is_terminal).reverse(), "id"),
    closedCollection: state => _.uniqBy(state.collections.closed.filter(task => task.current_state && task.current_state.is_terminal).reverse(), "id"),
    inProgressCollection: state => {
        const activeTasks = state.collections.inProgress.filter(task => task.current_state && !task.current_state.is_terminal);
        const uniqueTasks = _.uniqBy(activeTasks.reverse(), "id");
        const orderedTasks = _.orderBy(uniqueTasks, ['id'], ['desc']);
        const parents = _.uniq(orderedTasks.map(task => {
            const hasParent = task.parents && task.parents.length && _.has(task.parents[0], 'id');
            return (hasParent && task.parents[0].performer_id === task.performer_id) ? task.parents[0].id : null;
        }));
        return orderedTasks.filter(task => !parents.includes(task.id));
    },
};

const mutations = {

    [TASKS_FETCH](state) {
        state.errors = [];
        state.isLoaded = false;
        state.loading = true;
    },

    [TASKS_FETCH_SUCCESS](state, collection) {
        state.errors = [];
        state.collection = [...state.collection, ...collection];
        const uniqueCollection = [];
        const uniqueIds = [];
        for (const item of state.collection) {
            if (uniqueIds.includes(item.id)) {
                continue;
            }
            uniqueIds.push(item.id);
            uniqueCollection.push(item);
        }
        state.collection = uniqueCollection;
        state.isLoaded = true;
        state.loading = false;
    },

    [TASKS_FETCH_FAILED](state, {errors}) {
        state.errors = [...errors];
        state.isLoaded = false;
        state.loading = false;
    },

    [TASKS_REMOVE_FROM_COLLECTION](state, taskId) {
        const index = _.findIndex(state.collections.inProgress, {id: taskId});
        if (index >= 0) {
            state.collections.inProgress.splice(index, 1);
        }
    },

    [TASKS_TASK_STATE_CHANGED](State, {task, state, actions}) {
        if (_.findIndex(State.collections.inProgress, {id: task.id}) >= 0) {
            _.find(State.collections.inProgress, {id: task.id}).current_state = state;
            _.find(State.collections.inProgress, {id: task.id}).actions = actions;
        }

        if (_.findIndex(State.collections.active, {id: task.id}) >= 0) {
            _.find(State.collections.active, {id: task.id}).current_state = state;
            _.find(State.collections.active, {id: task.id}).actions = actions;
        } else {
            State.collections.active.push(task);
        }

        if (_.findIndex(State.collections.closed, {id: task.id}) >= 0) {
            _.find(State.collections.closed, {id: task.id}).current_state = state;
            _.find(State.collections.closed, {id: task.id}).actions = actions;
        } else {
            State.collections.closed.push(task);
        }
        State.collections.active = _.filter(State.collections.active, task => task.current_state ? !task.current_state.is_terminal : false);
        State.collections.closed = _.filter(State.collections.closed, task => task.current_state ? task.current_state.is_terminal : false);

    },


    [TASKS_IN_PROGRESS_FETCH](state) {
        state.errors = [];
        state.isLoaded = false;
        state.loading = true;
    },

    [TASKS_IN_PROGRESS_REFETCH_SUCCESS](state, collection) {
        state.errors = [];
        state.collections.inProgress = collection;
        state.isLoaded = true;
        state.loading = false;
    },

    [TASKS_IN_PROGRESS_FETCH_SUCCESS](state, collection) {
        state.errors = [];
        state.collections.inProgress = [
            ...collection,
            ...state.collections.inProgress
        ];
        state.isLoaded = true;
        state.loading = false;
    },

    [TASKS_IN_PROGRESS_FETCH_FAILED](state, {errors}) {
        state.errors = [...errors];
        state.isLoaded = false;
        state.loading = false;
    },

    [TASKS_IN_PROGRESS_COUNT]() {
    },

    [TASKS_IN_PROGRESS_COUNT_SUCCESS](state, count) {
        state.counters.inProgress = count;
    },

    [TASKS_IN_PROGRESS_COUNT_FAILED]() {
    },

    [TASKS_FETCH_ACTIVE](state) {
        state.errors = [];
        state.isLoaded = false;
        state.loading = true;
    },

    [TASKS_FETCH_ACTIVE_SUCCESS](state, collection) {
        state.errors = [];
        state.collection = [...state.collection, ...collection];
        state.collections.active = [...state.collections.active, ...collection];
        state.isLoaded = true;
        state.loading = false;
    },

    [TASKS_FETCH_ACTIVE_FAILED](state, {errors}) {
        if (state.errors) {
            state.errors = Array.isArray(errors) ? [...errors] : [errors];
        }
        state.isLoaded = false;
        state.loading = false;
    },

    [TASKS_FETCH_CLOSED](state) {
        state.errors = [];
        state.isLoaded = false;
        // state.loading = true;
    },

    [TASKS_FETCH_CLOSED_SUCCESS](state, collection) {
        state.errors = [];
        state.collection = [...state.collection, ...collection];
        state.collections.closed = [...state.collections.closed, ...collection];
        state.isLoaded = true;
        state.loading = false;
    },

    [TASKS_FETCH_CLOSED_FAILED](state, {errors}) {
        state.errors = [...errors];
        state.isLoaded = false;
        state.loading = false;
    },

    [TASKS_COUNT_ACTIVE]() {
    },

    [TASKS_COUNT_ACTIVE_SUCCESS](state, count) {
        state.counters.inCategory = count;
    },

    [TASKS_COUNT_ACTIVE_FAILED]() {
    },

    [TASKS_COUNT_ACTIVE_CREATED]() {
    },

    [TASKS_COUNT_ACTIVE_CREATED_SUCCESS](state, count) {
        state.counters.createdByMe = count;
    },

    [TASKS_COUNT_ACTIVE_CREATED_FAILED]() {
    },

    [TASKS_COUNT_ACTIVE_PERFORMED]() {
    },

    [TASKS_COUNT_ACTIVE_PERFORMED_SUCCESS](state, count) {
        state.counters.performedByMe = count;
    },

    [TASKS_COUNT_ACTIVE_PERFORMED_FAILED]() {
    },

    [TASKS_PAGINATION]() {
    },

    [TASKS_PAGINATION_ACTIVE](state, pagination) {
        state.pagination.active.perPage = pagination.per_page;
        state.pagination.active.from = pagination.from;
        state.pagination.active.to = pagination.to;
        state.pagination.active.total = pagination.total;
        state.pagination.active.currentPage = pagination.current_page;
        state.pagination.active.lastPage = pagination.last_page;
        state.pagination.active.loaded = pagination.last_page <= pagination.current_page;
    },

    [TASKS_PAGINATION_CLOSED](state, pagination) {
        state.pagination.closed.perPage = pagination.per_page;
        state.pagination.closed.from = pagination.from;
        state.pagination.closed.to = pagination.to;
        state.pagination.closed.total = pagination.total;
        state.pagination.closed.currentPage = pagination.current_page;
        state.pagination.closed.lastPage = pagination.last_page;
        state.pagination.closed.loaded = pagination.last_page <= pagination.current_page;
    },

    [TASKS_PUSH](state, task) {
        state.collection.push(task);
        if (task.is_closed || (task.current_state && task.current_state.is_terminal)) {
            state.collections.closed.push(task);
        } else {
            state.collections.active.push(task);
        }
    }

};

const actions = {
    /**
     * Subscribe to user updates channels
     *
     * Здесь мы слушаем все события, которые сервер вещает
     * в канал событий пользователя.
     *
     * Когда запрошен аппрув (событие), вызываются методы
     * получения активных аппрувов пользователя и обновления
     * счетчика активных аппрувов пользователя.
     *
     * Когда создана новая задача (событие), вызываются методы
     * получения текущих задач пользователя, созданных пользователем задач
     * и задач, в которых пользователь является исполнителем,
     * а также соответствующих счетчиков.
     *
     * Когда у задачи меняется заказчик/исполнитель, вызываются методы
     * получения текущих задач пользователя, созданных пользователем задач
     * и задач, в которых пользователь является исполнителем,
     * а также соответствующих счетчиков.
     *
     *
     */
    subscribe({commit, dispatch, state}, userId) {
        if (!userId) {
            console.error("Subscribing to user events failed. No userId set.");
            return;
        }

        // For approvals
        if (!state.subscriptions.events) {
            // console.debug(`Subscribing to user-${userId}-events`);
            state.subscriptions.events = window.Echo.channel(`user-${userId}-events`);

            // Listen for requested/granted approvals
            state.subscriptions.events
                .listen(".ApprovalRequestedEvent", ({
                                                    approvalId, userId, taskId, approveAbilityId
                                                }) => {
                    console.debug("New approval requested", {
                        approvalId,
                        userId,
                        taskId,
                        approveAbilityId
                    });
                    commit("approvals/APPROVAL_REQUESTED", {
                        approvalId,
                        userId,
                        taskId,
                        approveAbilityId
                    }, {root: true});
                    dispatch("approvals/fetchMyApprovals", null, {root: true});
                    dispatch("approvals/countMyApprovals", null, {root: true});
                })
                .listen(".ApprovalGrantedEvent", ({approvalId, userId, taskId}) => {
                    console.debug("Approval granted", {
                        approvalId,
                        userId,
                        taskId
                    });
                    commit("approvals/APPROVAL_GRANTED", {
                        approvalId,
                        userId,
                        taskId
                    }, {root: true});
                    dispatch("approvals/fetchMyApprovals", null, {root: true});
                    dispatch("approvals/countMyApprovals", null, {root: true});
                });
        }

        // For tasks created
        state.subscriptions.events
            .listen(".TaskCreatedEvent", () => {
                console.debug("New task created");
                dispatch("countTasksInProgress");
                dispatch("countTasksCreatedByMe");
                dispatch("countTasksPerformedByMe");
                dispatch("fetchTasksInProgress");
                dispatch("fetchActiveByCreator");
                dispatch("fetchActiveByPerformer");
            })
            .listen(".TaskCreatorChangedEvent", (task) => {
                console.debug("Task creator changed in task", task);
                dispatch("countTasksInProgress");
                dispatch("countTasksCreatedByMe");
                dispatch("countTasksPerformedByMe");
                dispatch("fetchTasksInProgress");
                dispatch("fetchActiveByCreator");
                dispatch("fetchActiveByPerformer");
            })
            .listen(".TaskPerformerChangedEvent", (task) => {
                console.debug("Task performer changed in task", task);
                dispatch("countTasksInProgress");
                dispatch("countTasksCreatedByMe");
                dispatch("countTasksPerformedByMe");
                dispatch("fetchTasksInProgress");
                dispatch("fetchActiveByCreator");
                dispatch("fetchActiveByPerformer");
            });
    },

    /**
     * @param commit
     * @param state
     * @param categoryId
     * @returns {Promise<any>}
     */
    countActive: ({commit}, categoryId) => {
        commit(TASKS_COUNT_ACTIVE);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/categories/${categoryId}/tasks/count?only=active`)
                .then(({data}) => {
                    commit(TASKS_COUNT_ACTIVE_SUCCESS, data.count);
                    resolve(data.count);
                })
                .catch(({response}) => {
                    commit(TASKS_COUNT_ACTIVE_FAILED, {errors: response.data.errors});
                    reject(response.data.errors);
                });
        });
    },

    // fetchAllCategoryTasks: ({commit}, {categoryId}) => {
    //     commit(TASKS_FETCH);
    //     return new Promise((resolve, reject) => {
    //         CoreApi.get(`/categories/${categoryId}/tasks/all?only=active`)
    //             .then(({data}) => {
    //                 commit(TASKS_FETCH_SUCCESS, data.data);
    //                 commit(TASKS_PAGINATION, data.meta);
    //                 resolve(data.data);
    //             })
    //             .catch(({response}) => {
    //                 commit(TASKS_FETCH_FAILED, {errors: response.data.errors});
    //                 reject(response.data.errors);
    //             });
    //     });
    // },

    fetchAttachableTasksBySubtaskRule: ({commit}, {subtaskRuleId, parentTaskId}) => {
        commit(TASKS_FETCH);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/tasks/assignable/${subtaskRuleId}/${parentTaskId}`)
                .then(({data}) => {
                    commit(TASKS_FETCH_SUCCESS, data.data);
                    if (undefined !== data.meta) {
                        commit(TASKS_PAGINATION, data.meta);
                    }
                    resolve(data.data);
                })
                .catch(({response}) => {
                    commit(TASKS_FETCH_FAILED, {errors: response.data.errors});
                    reject(response.data.errors);
                });
        });
    },

    /**
     * @param commit
     * @param categoryId
     * @param page
     * @param items
     * @param params
     */
    fetchCategoryTasks: ({commit}, {category_id, page, only, query, current_states_ids}) => {
        commit(TASKS_FETCH);
        let url = `/categories/${category_id}/tasks?page=${page || 1}`;

        if (only) {
            url += `&only=${only}`;
        }

        if (query) {
            url += `&query=${query}`;
        }

        if (current_states_ids && typeof current_states_ids === 'object') {
            current_states_ids.forEach(id => {
                url += `&current_states_ids[]=${id}`;
            })
        }

        return new Promise((resolve, reject) => {
            CoreApi.get(url)
                .then(({data}) => {
                    commit(TASKS_FETCH_SUCCESS, data.data);
                    resolve({data: data.data, meta: data.meta});
                })
                .catch(({response}) => {
                    commit(TASKS_FETCH_FAILED, {errors: response.data.errors});
                    reject(response.data.errors);
                });
        });
    },

    /**
     *
     * @param commit
     * @param categoryId
     * @param page
     * @returns {Promise<any>}
     */
    fetchActive: ({commit}, {categoryId, page}) => {
        commit(TASKS_FETCH_ACTIVE);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/categories/${categoryId}/tasks?page=${page || 1}&only=active`)
                .then(({data}) => {
                    commit(TASKS_FETCH_ACTIVE_SUCCESS, data.data);
                    commit(TASKS_PAGINATION_ACTIVE, data.meta);
                    resolve(data.data);
                })
                .catch((response) => {
                    if (response && response.data) {
                        commit(TASKS_FETCH_ACTIVE_FAILED, {errors: response.data.errors});
                        reject(response.data.errors);
                    }
                });
        });
    },

    /**
     *
     * @param commit
     * @param categoryId
     * @param page
     * @param items
     * @returns {Promise<any>}
     */
    fetchClosed: ({commit}, {categoryId, page}) => {
        commit(TASKS_FETCH_CLOSED);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/categories/${categoryId}/tasks?page=${page || 1}&only=closed`)
                .then(({data}) => {
                    commit(TASKS_FETCH_CLOSED_SUCCESS, data.data);
                    commit(TASKS_PAGINATION_CLOSED, data.meta);
                    resolve(data.data);
                })
                .catch(({response}) => {
                    commit(TASKS_FETCH_CLOSED_FAILED, {errors: response.data.errors});
                    reject(response.data.errors);
                });
        });
    },

    /**
     * @param commit
     * @param page
     * @param params
     */
    fetchByCreator: ({commit}, page) => {
        page = page || 1;
        commit(TASKS_FETCH);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/tasks/performed_by_me?page=${page}`)
                .then(({data}) => {
                    commit(TASKS_FETCH_SUCCESS, data.data);
                    commit(TASKS_PAGINATION, data.meta);
                    resolve(data.data);
                })
                .catch(({response}) => {
                    commit(TASKS_FETCH_FAILED, {errors: response.data.errors});
                    reject(response.data.errors);
                });
        });
    },

    /**
     * @param commit
     * @param page
     * @param params
     */
    fetchActiveByCreator: ({commit}, page) => {
        page = page || 1;
        commit(TASKS_FETCH_ACTIVE);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/tasks/created_by_me?page=${page}&only=active`)
                .then(({data}) => {
                    commit(TASKS_FETCH_ACTIVE_SUCCESS, data.data);
                    commit(TASKS_PAGINATION_ACTIVE, data.meta);
                    resolve(data.data);
                })
                .catch((response) => {
                    if ("data" in response && "errors" in response.data) {
                        commit(TASKS_FETCH_ACTIVE_FAILED, {errors: response.data.errors});
                        reject(response.data.errors);
                    } else {
                        console.error(response);
                    }
                });
        });
    },

    /**
     * @param commit
     * @param page
     * @param params
     */
    fetchClosedByCreator: ({commit}, page) => {
        page = page || 1;
        commit(TASKS_FETCH_CLOSED);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/tasks/created_by_me?page=${page}&only=closed`)
                .then(({data}) => {
                    commit(TASKS_FETCH_CLOSED_SUCCESS, data.data);
                    commit(TASKS_PAGINATION_CLOSED, data.meta);
                    resolve(data.data);
                })
                .catch((response) => {
                    if ("data" in response && "errors" in response.data) {
                        commit(TASKS_FETCH_CLOSED_FAILED, {errors: response.data.errors});
                        reject(response.data.errors);
                    } else {
                        console.error(response);
                    }
                });
        });
    },

    /**
     * @param commit
     * @param page
     * @param params
     */
    fetchByPerformer: ({commit}, page) => {
        page = page || 1;
        commit(TASKS_FETCH);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/tasks/performed_by_me?page=${page}`)
                .then(({data}) => {
                    commit(TASKS_FETCH_SUCCESS, data.data);
                    commit(TASKS_PAGINATION, data.meta);
                    resolve(data.data);
                })
                .catch(({response}) => {
                    commit(TASKS_FETCH_FAILED, {errors: response.data.errors});
                    reject(response.data.errors);
                });
        });
    },

    /**
     * @param commit
     * @param page
     * @param params
     */
    fetchActiveByPerformer: ({commit}, page) => {
        page = page || 1;
        commit(TASKS_FETCH_ACTIVE);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/tasks/performed_by_me?page=${page}&only=active`)
                .then(({data}) => {
                    commit(TASKS_FETCH_ACTIVE_SUCCESS, data.data);
                    commit(TASKS_PAGINATION_ACTIVE, data.meta);
                    resolve(data.data);
                })
                .catch((response) => {
                    if ("data" in response && "errors" in response.data) {
                        commit(TASKS_FETCH_ACTIVE_FAILED, {errors: response.data.errors});
                        reject(response.data.errors);
                    } else {
                        console.error(response);
                    }
                });
        });
    },

    /**
     * @param commit
     * @param page
     * @param params
     */
    fetchClosedByPerformer: ({commit}, page) => {
        page = page || 1;
        commit(TASKS_FETCH_CLOSED);
        return new Promise((resolve, reject) => {
            CoreApi.get(`/tasks/performed_by_me?page=${page}&only=closed`)
                .then(({data}) => {
                    commit(TASKS_FETCH_CLOSED_SUCCESS, data.data);
                    commit(TASKS_PAGINATION_CLOSED, data.meta);
                    resolve(data.data);
                })
                .catch((response) => {
                    if ("data" in response && "errors" in response.data) {
                        commit(TASKS_FETCH_CLOSED_FAILED, {errors: response.data.errors});
                        reject(response.data.errors);
                    } else {
                        console.error(response);
                    }
                });
        });
    },

    refetchTasksInProgress: ({commit}) => {
        //console.debug("Refetching inProgress collection");

        commit(TASKS_IN_PROGRESS_FETCH);
        return new Promise((resolve, reject) => {
            CoreApi.get("/tasks/in_progress")
                .then(({data}) => {
                    commit(TASKS_IN_PROGRESS_REFETCH_SUCCESS, data.data);
                    resolve(data.data);
                })
                .catch((response) => {
                    if ("data" in response && "errors" in response.data) {
                        commit(TASKS_IN_PROGRESS_FETCH_FAILED, {errors: response.data.errors});
                        reject(response.data.errors);
                    } else {
                        console.error(response);
                    }
                });
        });
    },

    fetchTasksInProgress: ({commit}) => {
        commit(TASKS_IN_PROGRESS_FETCH);
        return new Promise((resolve, reject) => {
            CoreApi.get("/tasks/in_progress")
                .then(({data}) => {
                    commit(TASKS_IN_PROGRESS_FETCH_SUCCESS, data.data);
                    resolve(data.data);
                })
                .catch((response) => {
                    if ("data" in response && "errors" in response.data) {
                        commit(TASKS_IN_PROGRESS_FETCH_FAILED, {errors: response.data.errors});
                        reject(response.data.errors);
                    } else {
                        console.error(response);
                    }
                });
        });
    },

    /**
     * @param commit
     * @returns {Promise<any>}
     */
    countTasksCreatedByMe({commit}) {
        commit(TASKS_COUNT_ACTIVE_CREATED);
        return new Promise((resolve) => {
            CoreApi.get("/tasks/created_by_me/count?only=active")
                .then(({data}) => {
                    commit(TASKS_COUNT_ACTIVE_CREATED_SUCCESS, data.count);
                    resolve(data.count);
                })
                .catch(({response}) => {
                    const error = handleError(response);
                    commit(TASKS_COUNT_ACTIVE_CREATED_FAILED, error);
                    console.error(error);
                });
        });
    },

    /**
     * @param commit
     * @returns {Promise<any>}
     */
    countTasksPerformedByMe({commit}) {
        commit(TASKS_COUNT_ACTIVE_PERFORMED);
        return new Promise((resolve) => {
            CoreApi.get("/tasks/performed_by_me/count?only=active")
                .then(({data}) => {
                    commit(TASKS_COUNT_ACTIVE_PERFORMED_SUCCESS, data.count);
                    resolve(data.count);
                })
                .catch(({response}) => {
                    const error = handleError(response);
                    commit(TASKS_COUNT_ACTIVE_PERFORMED_FAILED, error);
                    console.error(error);
                });
        });
    },

    /**
     * @param commit
     * @returns {Promise<any>}
     */
    countTasksInProgress({commit}) {
        commit(TASKS_IN_PROGRESS_COUNT);
        return new Promise((resolve, reject) => {
            CoreApi.get("/tasks/in_progress/count")
                .then(({data}) => {
                    commit(TASKS_IN_PROGRESS_COUNT_SUCCESS, data.count);
                    resolve(data.count);
                })
                .catch((response) => {
                    if ("data" in response && "errors" in response.data) {
                        commit(TASKS_IN_PROGRESS_COUNT_FAILED, {errors: response.data.errors});
                        reject(response.data.errors);
                    } else {
                        console.error(response);
                    }
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
