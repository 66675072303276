<template>
  <div class="task-log">
    <el-table :data="task.log">
      <el-table-column label="Дата и время" width="180">
        <template slot-scope="{row}">
          {{row.created_at | moment("D.M.YYYY в H:mm") }}
        </template>
      </el-table-column>
      <el-table-column prop="user_contents" label="Пользователь" width="200">
      </el-table-column>
      <el-table-column prop="action_label" label="Событие">
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
  export default {
    name: 'TaskLog',
    props: ['task']
  }
</script>

<style scoped>
.task-log {
  margin: 0 -16px;
}
</style>