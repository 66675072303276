<template>
  <div class="task-subtasks-wrapper">
    <Presentation v-cloak v-if="subtaskCategoryPresentationId !== null"
                  :category="subtaskRule.subtask_category"
                  :viewName="subtaskRule.subtask_category_presentation ?
                  subtaskRule.subtask_category_presentation.name : null"
                  :viewPreloaded="subtaskRule.subtask_category_presentation"
                  :key="subtaskRule.subtask_category_presentation_id"
                  :presentation-id="subtaskRule.subtask_category_presentation_id"
                  :dataset="subtasks"
                  :click-callback="clickCallback"
                  :embed-mode="true"
                  :selection-mode="pickMode"
                  ref="presentation"
                  @selected="selectedCallback"
    />
    <tasks-table v-cloak v-else-if="subtaskCategoryPresentationId === null"
                 :tasks="subtasks"
                 :show-category-column="true"
                 :click-callback="clickCallback"
                 ref="table"
    />
  </div>
</template>
<script>
  import {mapGetters} from "vuex";
  import TasksTable from '../TasksTable/TasksTable';
  import Presentation from "../Presentation/Presentation";

  export default {
    name: 'TaskSubtasks',
    components: {
      TasksTable,
      Presentation
    },
    props: {
      subtasks: {
        type: Array,
      },
      mode: {
        type: String,
        default: () => null
      },
      subtaskRule: {
        type: Object,
        default: () => ({})
      },
      clickCallback: {
        type: Function,
        default: () => null
      },
      selectedCallback: {
        type: Function,
        default: () => null
      },
      pickMode: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      ...mapGetters("task", {tasksLoading: 'loading'}),
      ...mapGetters("categories", {categoriesLoading: 'loading'}),
      loading: state => state.tasksLoading || state.categoriesLoading,
      subtaskCategoryPresentationId: state => state.subtaskRule ? state.subtaskRule.subtask_category_presentation_id : null,
    }
  }
</script>
