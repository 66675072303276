<template>
	<div class="page">

		<!-- Tasks created by me -->
		<div class="card tasks-in-progress" v-show="(tasksInProgressCreatedByMe && tasksInProgressCreatedByMe.length )||(
tasksInProgressPerformedByMe && tasksInProgressPerformedByMe.length )||(
tasksWithApprovals && tasksWithApprovals.length) || loading">

			<loading-bar :loading="loading"></loading-bar>

			<div class="card-header" v-show="tasksInProgressCreatedByMe && tasksInProgressCreatedByMe.length">
				<h4 class="card-title">Поставленные мной</h4>
			</div>
			<div class="card-body px-0" v-show="tasksInProgressCreatedByMe && tasksInProgressCreatedByMe.length">
				<tasks-table :tasks="tasksInProgressCreatedByMe"
                     :default-sort="{prop:'expiring_at', order: 'asc'}"
                     :show-silent-tasks="false" :show-category-column="true"
                     :show-creator-column="false" :show-performer-column="true"/>
			</div>

			<!-- Tasks performed by me -->

			<div class="card-header" v-show="tasksInProgressPerformedByMe && tasksInProgressPerformedByMe.length">
				<h4 class="card-title">Поставленные мне</h4>
			</div>
			<div class="card-body px-0" v-show="tasksInProgressPerformedByMe && tasksInProgressPerformedByMe.length">
				<tasks-table :tasks="tasksInProgressPerformedByMe" :show-silent-tasks="false"
                     :default-sort="{prop:'expiring_at', order: 'asc'}"
                     :show-creator-column="true" :show-performer-column="false" :show-category-column="true"/>
			</div>

			<!-- Approvals -->
			<div class="card-header" v-show="tasksWithApprovals && tasksWithApprovals.length">
				<h4 class="card-title">Ожидают моего утверждения</h4>
			</div>
			<div class="card-body px-0" v-show="tasksWithApprovals && tasksWithApprovals.length">
				<tasks-table :tasks="tasksWithApprovals"
                     :default-sort="{prop:'expiring_at', order: 'asc'}"
                     :show-silent-tasks="false" :show-category-column="true" :show-approval-column="true"/>
			</div>
		</div>

		<card class="small text-center" v-if="!loading && tasksInProgressCreatedByMe && !tasksInProgressCreatedByMe.length &&
tasksInProgressPerformedByMe && !tasksInProgressPerformedByMe.length &&
tasksWithApprovals && !tasksWithApprovals.length">
			<span class="">Нет задач, требующих вашей реакции</span>
		</card>
	</div>
</template>
<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import $ from "jquery";
    import moment from "moment";
    import LoadingBar from "../../components/LoadingBar/LoadingBar";
    import TasksTable from "../../components/TasksTable/TasksTable";

    export default {
        name      : "TasksApprovedByMe",
        components: {
            TasksTable,
            LoadingBar
        },
        data      : () => ( {
            subscribed: false
        } ),
        computed  : {
            ...mapGetters("auth", ["userData", "userId", "departmentId", "isSupervisor", "roles"]),
            ...mapGetters("approvals", {
                // approvals collection
                approvals       : "collection",
                // approvals loading flag
                approvalsLoading: "loading"
            }),
            ...mapGetters("tasks", {
                // tasks collection
                tasksInProgress: "inProgressCollection",
                // tasks loading flag
                tasksLoading   : "loading"
            }),
            tasksWithApprovals(){
                return this.approvals
                           .filter(approval => {
                               return approval && approval.task && approval.task.id && approval.is_requested && !approval.is_granted;
                           })
                           .map(approval => {
                               const task = approval.task;
                               if( task && task.id ) {
                                   this.approvals[task.id] = approval;
                                   return task;
                               }
                           });
            },
			tasksInProgressCreatedByMe() {
				return this.tasksInProgress.filter(task =>
						(task.creator_id === this.userId && task.creator_id !== task.performer_id)
				);
            },
            tasksInProgressPerformedByMe(){
				return this.tasksInProgress.filter(task => task.performer_id === this.userId);
            },
            // common loading flag
            loading: state => ( state.approvalsLoading || state.tasksLoading )
        },
        watch     : {
            userId: {
                handler: function( userId ){
                    if( userId ) {
                        this.subscribe(userId);
                    }
                },
                initial: true
            }
        },
        methods   : {

            /**
             * Subscribe to user updates channels
             */
            subscribe( userId ){
                this.$store.dispatch("tasks/subscribe", userId).catch();
            },

            requestedApprovalOfTask( taskId ){
                return this.approvals[taskId];
            },

            /**
             * Grant approval
             * @param event
             * @param approval
             */
            grantApproval( event, approval ){
                const element = $(event.target)
                                    .is("button")
                                ? $(event.target)
                                : $(event.target)
                                    .parent("button");
                this.$store.dispatch("approvals/grant", {
                        approval_id: approval.id,
                        task_id    : approval.task_id
                    })
                    .then(() => {
                        element.remove();
                        this.$store.dispatch("approvals/fetchMyApprovals").catch();
                        this.$notify({
                            icon : "fa fa-check",
                            group: "TasksApprovedByMe",
                            title: "Утверждено",
                            type : "success"
                        });
                    })
                    .catch(errors => {
                        this.$notify({
                            icon : "fa fa-times",
                            group: "TasksApprovedByMe",
                            title: _.isArray(errors) ? errors.join(",") : errors,
                            type : "danger"
                        });
                    });
            },
            isToday( expiringAt ){
                return moment(expiringAt)
                    .startOf("day")
                    .isSame(moment()
                        .startOf("day"));
            },

            /**
             * Format expiration date
             * @param expiringAt
             * @returns {boolean}
             */
            expiring( expiringAt ){
                return moment(new Date())
                    .isAfter(expiringAt);
            },

            /**
             * Check permission to grant
             * @param approval
             * @returns {boolean|*}
             */
            hasPermissionToGrant( approval ){
                const type = approval.approval_rule.approver_type;
                const approverId = approval.approval_rule.approver_id;
                return (
                    ( type === "APPROVAL_RULE_APPROVER_DEPARTMENT" && this.departmentId === approverId ) ||
                    // @todo fix this
                    // ( type === "APPROVAL_RULE_APPROVER_SUPERVISOR" && this.departmentId === approverId && is_supervisor ) ||
                    ( type === "APPROVAL_RULE_APPROVER_USER" && this.userId === approverId ) ||
                    ( type === "APPROVAL_RULE_APPROVER_ROLE" && approverId in ( this.roles.length ? this.roles.map(item => item.id) : [] ) )
                );
            }
        },
        mounted(){
            this.$store.dispatch("approvals/fetchMyApprovals").catch();
            this.$store.dispatch("tasks/fetchTasksInProgress").catch();
            this.subscribe(this.userId);
        }
    };
</script>
