import types from "./types.js";

export default {

  [types.TASK_SUBSCRIBED_FOR_UPDATES](state, taskId) {
    state.subscriptions.push(taskId);
  },

  [types.TASK_CREATOR_CHANGED]() {
  },

  [types.TASK_PERFORMER_CHANGED]() {
  },

  [types.TASK_FIELD_UPDATED]() {
    // state.task.field_values = _.uniqBy([
    //   value,
    //   ...state.task.field_values
    // ], "id");
    // state.task.data[value.field.name] = value.value;
  },

  [types.TASK_COMMENT_ADDED](state, {comment}) {
    state.task.comments.push(comment)
  },

  [types.TASK_ATTACHMENT_UPLOADED](state, {attachment}) {
    state.task.attachments.push(attachment)
  },

  [types.TASK_STATE_CHANGED](State, {task, state, actions}) {
    console.log("TASK_STATE_CHANGED", {
      task,
      state,
      actions
    });

    if (undefined !== State.task) {
      State.task.current_state = state;
      State.task.actions = actions;
    }
  },

  [types.TASK_CLOSED](state) {
    state.task.is_closed = true;
  },

  [types.TASK_CHANGE_CREATOR](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_CHANGE_CREATOR_SUCCESS](state, {task}) {
    state.errors = [];
    if (state.task && task && task.creator) {
      state.task.creator = task.creator;
    }
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_CHANGE_CREATOR_FAILED](state) {
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_CHANGE_PERFORMER](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_CHANGE_PERFORMER_SUCCESS](state, {task}) {
    state.errors = [];
    if (state.task && task && task.performer) {
      state.task.performer = task.performer;
    }
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_CHANGE_PERFORMER_FAILED](state) {
    state.loading = false;
    state.saving = false;
  },
  
  [types.TASK_CHANGE_SPECTATORS](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_CHANGE_SPECTATORS_SUCCESS](state, {task}) {
    state.errors = [];
    if (state.task && task && task.spectators) {
      state.task.spectators = task.spectators;
    }
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_CHANGE_SPECTATORS_FAILED](state) {
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_ASSIGN_TO_ME](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_ASSIGN_TO_ME_SUCCESS](state, {task}) {
    state.errors = [];
    state.task = task;
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_ASSIGN_TO_ME_FAILED](state) {
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_ASSIGN_SUBTASK](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_ASSIGN_SUBTASK_SUCCESS](state, {task}) {
    state.errors = [];
    state.task = task;
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_ASSIGN_SUBTASK_FAILED](state) {
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_REMOVE_SUBTASK](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_REMOVE_SUBTASK_SUCCESS](state, {task}) {
    state.errors = [];
    state.task = task;
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_REMOVE_SUBTASK_FAILED](state) {
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_UPDATE_BRIEF](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_UPDATE_BRIEF_SUCCESS](state, {task}) {
    state.errors = [];
    state.task = task;
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_UPDATE_SUBJECT_FAILED](state) {
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_UPDATE_SUBJECT](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_UPDATE_SUBJECT_SUCCESS](state, {task}) {
    state.errors = [];
    state.task = task;
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_UPDATE_BRIEF_FAILED](state) {
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_UPDATE_EXPIRED_AT](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_UPDATE_EXPIRED_AT_SUCCESS](state, {task}) {
    state.errors = [];
    state.task = task;
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_UPDATE_EXPIRED_AT_FAILED](state) {
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_TRANSIT](state) {
    state.transitionErrors = [];
    state.saving = true;
  },

  [types.TASK_TRANSIT_SUCCESS](state, {task}) {
    state.transitionErrors = [];
    state.task = task;
    state.saving = false;
  },

  [types.TASK_TRANSIT_FAILED](state, error) {
    state.saving = false;
    state.transitionErrors.push(error);
  },

  [types.TASK_CREATE](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_CREATE_SUCCESS](state, {task}) {
    state.errors = [];
    state.task = task;
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_CREATE_FAILED](state, error) {
    state.loading = false;
    state.saving = false;
    state.errors = typeof error === 'string' ? [error] : error;
  },

  [types.TASK_DELETE](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_DELETE_SUCCESS](state) {
    state.errors = [];
    state.task = null;
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_DELETE_FAILED](state, error) {
    state.loading = false;
    state.saving = false;
    state.errors = error;
  },

  [types.TASK_CREATE_COMMENT](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_CREATE_COMMENT_SUCCESS](state, {comment}) {
    state.errors = [];
    state.task.comments.push(comment);
    state.loading = false;
    state.saving = false;
  },

  [types.TASK_CREATE_COMMENT_FAILED](state, {errors}) {
    state.loading = false;
    state.saving = false;
    state.errors = errors;
  },

  [types.TASK_FIELD_UPDATE](state) {
    state.errors = [];
    state.saving = true;
  },

  [types.TASK_FIELD_UPDATE_SUCCESS](state, {fieldValue}) {
    state.errors = [];
    if (undefined !== state.task) {
      if ('FIELD_TYPE_TASK_RELATION' !== fieldValue.field.type) {
        state.task.data[fieldValue.field.name] = fieldValue.value;
        state.task[fieldValue.field.name] = fieldValue.value;
      }
      // @deprecated
      // state.task.field_values = _.uniqBy([
      //   fieldValue,
      //   ...state.task.field_values
      // ], "id");
    }
    state.saving = false;
  },

  [types.TASK_FIELD_UPDATE_FAILED](state, error) {
    state.saving = false;
    state.errors.push(error);
  },

  [types.TASK_CREATE_ATTACHMENT](state) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
  },

  [types.TASK_CREATE_ATTACHMENT_PROGRESS](state, {progress}) {
    state.errors = [];
    state.loading = true;
    state.saving = true;
    state.progress = progress;
  },

  [types.TASK_CREATE_ATTACHMENT_SUCCESS](state, attachment) {
    state.errors = [];
    if (undefined === state.task.attachments) {
      state.task.attachments = [];
    }
    state.task.attachments.push(attachment);
    state.loading = false;
    state.saving = false;
    state.progress = 100;
  },

  [types.TASK_CREATE_ATTACHMENT_FAILED](state, {errors}) {
    state.loading = false;
    state.saving = false;
    state.errors = errors;
  },

  [types.TASK_DELETE_ATTACHMENT](state) {
    state.errors = [];
    state.loading = true;
  },

  [types.TASK_DELETE_ATTACHMENT_SUCCESS](state, {removedId}) {
    state.errors = [];
    state.task.attachments = state.task.attachments.filter(item => item.id !== removedId);
    state.loading = false;
    state.progress = 100;
  },

  [types.TASK_DELETE_ATTACHMENT_FAILED](state, {errors}) {
    state.loading = false;
    state.errors = errors;
  },

  [types.TASK_FETCH](state) {
    state.errors = [];
    state.task = null;
    state.loading = true;
    state.notFound = false;
  },

  [types.TASK_FETCH_SUCCESS](state, {task}) {
    state.errors = [];
    state.task = task;
    state.loading = false;
    state.notFound = false;
  },

  [types.TASK_FETCH_FAILED](state, {errors}) {
    state.errors = [...errors];
    state.loading = false;
  },

  [types.TASK_FETCH_NOT_FOUND](state) {
    state.loading = false;
    state.notFound = true;
  },

  [types.TASK_COMMENT_PUSHED](state, comment) {
    state.tasks[comment.task_id].comments.push(comment);
  },

  [types.TASK_ATTACHMENT_PUSHED](state, attachment) {
    state.tasks[attachment.task_id].attachments.push(attachment);
  },

  [types.TASK_FETCH_SUBTASKS](state) {
    state.loading = true;
  },

  [types.TASK_FETCH_SUBTASKS_SUCCESS](state) {
    state.loading = false;
  },

  [types.TASK_FETCH_SUBTASKS_FAILED](state) {
    state.loading = false;
  },

  [types.TASK_PRELOAD](state, task) {
    state.preloadedTask = task;
  },
};
