<template>
  <div class="tasks-board">

    <b-modal
        id="transition-picker"
        no-close-on-backdrop
        hide-header
        hide-footer
        centered
        v-click-outside="cancelTransition">
        <ul class="task-actions list-inline list-unstyled mb-0">
          <li v-for="action in availableTransitions" :key="action.id" class="m-0 p-0 d-block">
            <button class="btn btn-primary btn-wd btn-block" @click="transitById(action.id)">
              {{ action.label }}
            </button>
          </li>
          <li class="m-0 p-0 d-block">
            <button class="btn btn-link btn-wd btn-block" @click="cancelTransition">
              Отмена
            </button>
          </li>
        </ul>
    </b-modal>

    <div class="board-title-row">
      <div class="col presentation-board-column" v-for="state of presentation.states" :key="state.id">
        <h5 class="board-title">{{ state.title }}</h5>
      </div>
    </div>

    <hr class="p-0 m-0" :style="fullwidthStyle"/>

    <div v-if="presentation.group_by === null" class="board-body-row">
      <draggable class="col presentation-board-column list-unstyled"
                 :class="{'is-dragging': isDragging}"
                 :list="getTasksOfState(state, data)"
                 v-for="state of presentation.states" :key="state.id"
                 @start="isDragging = true"
                 @end="isDragging = false"
                 @change="dropped($event, state)"
                 group="a">
        <li v-for="task of getTasksOfState(state, data)" :key="task.id">
          <presentation-board-card
              :category="category"
              :task="task"
              :presentation="presentation"
              :goto="goto"
              :get-field-value="getFieldValue"/>
        </li>
      </draggable>
    </div>

    <div v-else :style="fullwidthStyle">
      <div v-for="(group, groupName) of tasksGrouped" :key="groupName" class="collapsible" :style="fullwidthStyle">
        <button class="group-title text-left" v-b-toggle="slug(groupName)" :style="fullwidthStyle">
          <span class="icon">
            <font-awesome-icon icon="chevron-right" class="closed"/>
            <font-awesome-icon icon="chevron-down" class="opened"/>
          </span>
          <span class="text">
            {{ groupName }}
          </span>
        </button>
        <b-collapse visible class="board-body-row collapsible-content" :id="slug(groupName)">
          <draggable v-for="state of presentation.states" :key="state.id"
                     :list="getTasksOfState(state, group)"
                     @start="isDragging = true"
                     @end="isDragging = false"
                     class="col presentation-board-column list-unstyled"
                     :class="{'is-dragging': isDragging}"
                     @change="dropped($event, state)"
                     :group="groupName">
            <li v-for="task of getTasksOfState(state, group)" :key="task.id">
              <presentation-board-card
                  :category="category"
                  :task="task"
                  :presentation="presentation"
                  :goto="goto"
                  :get-field-value="getFieldValue"/>
            </li>
          </draggable>
        </b-collapse>
      </div>
    </div>

  </div>
</template>

<script>
import _ from "lodash"
import PresentationBoardCard from "./Board/PresentationBoardCard";
import draggable from "vuedraggable";
import {CoreApi} from "../../lib/HttpApi";
import makeTransition from "../../mixins/makeTransition";

export default {
  name: "PresentationBoard",
  components: {PresentationBoardCard, draggable},
  mixins: [makeTransition],
  data: () => ({
    collapsed: {},
    isDragging: false,
    availableTransitions: [],
    prevStateId: null,
    draggingTaskId: null,
  }),
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    category: {
      type: Object,
      default: () => ({})
    },
    presentation: {
      type: Object,
      default: () => ({})
    },
    goto: {
      type: Function
    },
    getFieldValue: {
      type: Function
    },
  },
  computed: {
    tasksGrouped: state => {
      if (!state.presentation) {
        return []
      }
      const groupBy = state.presentation.group_by
      const grouped = _.groupBy(state.data, groupBy) || []
      const field = _.find(state.category.fields, {name: groupBy})
      const options = field.options || []
      const sorted = {}
      for (const optionIndex in options) {
        const option = options[optionIndex]
        if (option in grouped) {
          sorted[option] = grouped[option]
        }
      }
      return sorted
    },
    fullwidthStyle: state => {
      const singleColumnWidth = 270
      const width = state.presentation.states.length * singleColumnWidth
      return {
        width: width + 'px',
        minWidth: '100%'
      }
    }
  },
  methods: {

    getTasksOfState(state, data) {
      return data.filter(task => task.current_state_id === state.id) || [];
    },

    dropped(event, state) {
      if (!('added' in event && 'element' in event.added)) {
        return
      }
      const task = event.added.element
      const taskId = task.id
      const taskObject = _.find(this.data, {id: taskId})
      const stateId = state.id
      const url = `/tasks/${taskId}/transit/${stateId}`

      this.prevStateId = task.current_state_id
      task.current_state_id = stateId
      CoreApi.post(url)
        .then(response => {
          const updatedTask = response.data
          _.assign(taskObject, updatedTask)
          this.prevStateId = null
        })
        .catch(error => {
          const statusCode = error.response.status
          if (300 === statusCode) {
            this.showTransitionPicker(error.response.data.data.transitions, task, stateId)
            return;
          }

          task.current_state_id = this.prevStateId

          const errorsBag = typeof errors === 'object'
              ? error.response.data.errors
              : [error.response.data.errors]

          this.$notify({
            icon: "fa fa-times",
            group: "TaskTransitionActions",
            title: errorsBag.join("<br>"),
            type: "danger"
          });
        })

    },

    showTransitionPicker(transitions, task, stateId) {
      this.$bvModal.show('transition-picker')

      this.draggingTaskId = task.id

      task.current_state_id = stateId

      this.availableTransitions = []
      for (const id in transitions) {
        const label = transitions[id]
        this.availableTransitions.push({id, label})
      }
    },

    transitById(transitionId) {
      this.makeTransition(transitionId,  this.draggingTaskId, () => {
        this.draggingTaskId = null
        this.prevStateId = null
        this.availableTransitions = []
        this.$bvModal.hide('transition-picker')
      })
    },

    cancelTransition() {
      _.find(this.data, {id: this.draggingTaskId}).current_state_id = this.prevStateId
      this.draggingTaskId = null
      this.prevStateId = null
      this.availableTransitions = []
      this.$bvModal.hide('transition-picker')
    },

    slug(name) {
      return name.replace(/[^A-Za-z]/, '')
    }
  }
}
</script>

<style>

</style>