import DashboardLayout from "../components/Layout/DashboardLayout.vue";
import NotFound from "../pages/NotFoundPage.vue";
import NotificationsPage from "../pages/NotificationsPage.vue";
import UserProfile from "../pages/UserProfile.vue";
import EditProfileForm from "../pages/UserProfile/EditProfileForm.vue";
import CategoryView from "../pages/CategoryView.vue";
import TaskView from "../components/TaskView/TaskView.vue";
import TaskCreate from "../components/TaskCreate/TaskCreate.vue";
import TasksCreatedByMe from "../pages/Tasks/TasksCreatedByMe.vue";
import TasksPerformedByMe from "../pages/Tasks/TasksPerformedByMe.vue";
import TasksInProgress from "../pages/Tasks/TasksInProgress.vue";
import Login from "../pages/Login.vue";
import SearchView from "../pages/SearchView.vue";
import StatisticsView from "../pages/StatisticsView.vue";
import FilePreview from "../pages/FilePreview.vue";
// import TestView from "../pages/GeneralViews/TestView.vue";

const myTasksMenu = {
  path: "/",
  component: DashboardLayout,
  redirect: "/waiting",
  children: [
    {
      path: "control",
      name: "TasksCreatedByMe",
      meta: {
        title: "Контроль задач"
      },
      component: TasksCreatedByMe
    },
    {
      path: "working",
      name: "TasksPerformedByMe",
      meta: {
        title: "Выполнение задач"
      },
      component: TasksPerformedByMe
    },
    {
      path: "waiting",
      name: "TasksInProgress",
      meta: {
        title: "Требует реакции"
      },
      component: TasksInProgress
    },

    {
      path: "/category/:categoryId",
      name: "Category",
      meta: {
        title: "Категория"
      },
      component: CategoryView
    },
    {
      path: "/category/:categoryId/task/create",
      name: "TaskCreate",
      component: TaskCreate
    },
    {
      path: "/category/:categoryId/task/:taskId",
      name: "TaskView",
      component: TaskView
    },
    {
      path: "/category/:categoryId/task/:parentTaskId/create/:subtaskRuleId",
      name: "SubtaskCreate",
      component: TaskCreate
    },
    {
      path: "/category/:categoryId/:viewName",
      name: "CategoryView",
      component: CategoryView
    },
    {
      path: "/statistics/:categoryId",
      name: "StatisticsView",
      meta: {
        title: "Статистика категории"
      },
      component: StatisticsView
    },
    {
      path: "/statistics/:categoryId/:viewName",
      name: "StatisticsViewView",
      meta: {
        title: "Статистика категории"
      },
      component: StatisticsView
    },
    {
      path: "/profile",
      name: "UserProfile",
      meta: {
        title: "Мой профиль"
      },
      component: UserProfile
    },
    {
      path: "/profile/edit",
      name: "EditProfileForm",
      meta: {
        title: "Мой профиль"
      },
      component: EditProfileForm
    },
    {
      path: "/notifications",
      name: "NotificationsPage",
      meta: {
        title: "Уведомления"
      },
      component: NotificationsPage
    },

    {
      path: "/search",
      name: "SearchView",
      meta: {
        title: "Поиск"
      },
      component: SearchView
    },

    {
      path: "/search/:categoryId",
      name: "SearchView",
      meta: {
        title: "Поиск по категории"
      },
      component: SearchView
    },
    // {
    //   path: "/test",
    //   name: "TestView",
    //   meta: {
    //     title: "Тест"
    //   },
    //   component: TestView
    // }
  ]
};

const loginPage = {
  path: "/login",
  name: "Login",
  meta: {
    name: "Вход"
  },
  component: Login
};

const imagePreview = {
  path: "/preview/:path(.*)",
  name: "FilePreview",
  meta: {
    title: "Просмотр"
  },
  component: FilePreview
};

const routes = [
  loginPage,
  imagePreview,
  myTasksMenu,
  {
    path: "*",
    component: NotFound
  }
];

export default routes;
