<template>
  <img v-bind="$attrs" :src="imageContent" v-if="isImage"/>
  <embed v-else
         :src="imageContent"
         :type="imageType"
         v-bind="$attrs">
</template>

<script>
import {CoreApiBlob} from "../lib/HttpApi";

export default {
  name: "Preview",
  props: ['className', 'alt', 'title', 'src'],
  data: () => ({
    imageContent: ''
  }),
  computed: {
    imageType: state => state.imageContent.replace(/^data:(\w+\/\w+).+$/, "$1"),
    isImage: state => state.imageType.indexOf("image/") === 0,
  },
  methods: {
    imageToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageContent = reader.result;
      }
      reader.onerror = function (error) {
        console.log('Error: ', error);
      }
    }
  },
  mounted() {
    CoreApiBlob.get(this.src).then(response => {
      this.imageToBase64(response.data);
    })
  }
}
</script>
