var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tasks-table-wrapper"},[_c('el-table',{ref:"table",attrs:{"data":_vm.dataset,"default-sort":_vm.defaultSorting,"empty-text":_vm.emptyText,"header-cell-class-name":_vm.headerCellClassName,"row-class-name":_vm.rowClassName,"cell-class-name":_vm.cellClassName},on:{"current-change":_vm.rowClick}},[_c('el-table-column',{attrs:{"sortable":false,"prop":"id","label":"#","width":"60"}}),_c('el-table-column',{attrs:{"resizable":true,"prop":"subject","label":"Тема"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{ name: 'TaskView',  params :  {categoryId:row.category_id, taskId: row.id} }}},[_vm._v(" "+_vm._s(row.subject)+" ")])]}}])}),(_vm.showBriefColumn)?_c('el-table-column',{attrs:{"prop":"brief","type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"cell-contents-wrapper"},[_c('p',{staticClass:"in-place-brief",domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(props.row.brief, _vm.highlightQuery))}}),_c('field-list',{staticClass:"in-place-fields",attrs:{"task":props.row,"items":props.row.category.fields,"highlight-query":_vm.highlightQuery,"allow-edit":false}}),(props.row.hasOwnProperty('subtasks') && props.row.subtasks.length)?_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v(" Подзадачи ")]):_vm._e(),(props.row.hasOwnProperty('subtasks') && props.row.subtasks.length)?_c('ul',_vm._l((props.row.subtasks),function(subtask){return _c('li',{key:subtask.id},[_c('router-link',{attrs:{"to":_vm.subtaskRoute(subtask)}},[_vm._v(" "+_vm._s(subtask.subject)+" ")]),_vm._v(" (#"+_vm._s(subtask.id)+") ")],1)}),0):_vm._e()],1)]}}],null,false,1792135723)}):_vm._e(),_c('el-table-column',{attrs:{"sortable":false,"label":"Статус","prop":"current_state","filter-method":_vm.filter,"filter-multiple":true,"filters":_vm.uniqueColumnValues('current_state', function (state) { return state ? state.title : '--'; }, function (state) { return state ? state.id : null; })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.current_state)?_c('el-tag',{attrs:{"size":"small","type":"default"}},[_vm._v(" "+_vm._s(row.current_state.title)+" ")]):_vm._e()]}}])}),(_vm.showCategoryColumn)?_c('el-table-column',{attrs:{"resizable":true,"label":"Категория","prop":"category","filter-method":_vm.filter,"filter-multiple":true,"filters":_vm.uniqueColumnValues('category', function (cat) { return cat ? cat.title : ''; }, function (cat) { return cat ? cat.id : null; })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row && row.category ? row.category.title : '')+" ")]}}],null,false,2859254138)}):_vm._e(),(_vm.showCreatorColumn !== false)?_c('el-table-column',{attrs:{"sortable":false,"resizable":true,"label":"Заказчик","prop":"creator","filter-multiple":true,"filter-method":_vm.filter,"filters":_vm.uniqueColumnValues('creator',
                            function (user) { return user.hasOwnProperty('name') ? user.name : ''; },
                            function (user) { return user.id; })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var row = ref.row;
return [(row.creator !== undefined && row.creator !== null)?_c('div',[_c('span',[_vm._v(_vm._s(row.creator.hasOwnProperty('name') ? row.creator.name : ''))])]):_vm._e()]}}],null,false,1286336573)}):_vm._e(),(_vm.showPerformerColumn !== false)?_c('el-table-column',{attrs:{"sortable":false,"resizable":true,"label":"Исполнитель","prop":"performer","filter-multiple":true,"filter-method":_vm.filter,"filters":_vm.uniquePerformerValues()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var row = ref.row;
return [(!_vm.performerIsAssignable(row, _vm.userId) && row.performer_id && row.performer !== undefined
                 && row.performer !== null)?_c('div',[_c('span',[_vm._v(_vm._s(row.performer.hasOwnProperty('name') ? row.performer.name : ''))])]):_vm._e(),(_vm.performerIsAssignable(row, _vm.userId))?_c('button',{staticClass:"btn btn-xs btn-primary",attrs:{"disabled":_vm.assigning},on:{"click":function($event){return _vm.assignToMe(row)}}},[_vm._v("Взять ")]):_vm._e()]}}],null,false,374806689)}):_vm._e(),(_vm.showExpiredColumn === true)?_c('el-table-column',{attrs:{"sortable":true,"resizable":true,"label":"Срок","prop":"expired_at","filters":_vm.uniqueColumnValues('expired_at', _vm.dateFormat, function (date) { return date; } ),"filter-method":_vm.filter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var row = ref.row;
return [_c('span',{class:{expiring : row.is_active && _vm.expiring(row.expiring_at)}},[(_vm.isToday(row.expired_at))?_c('span',{class:{expiring: !_vm.isExpired(row.expired_at)}},[(null === row.category.default_period_days)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(row.expired_at, 'HH:mm')))]):_c('span',[_vm._v("Сегодня")])]):_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row.expired_at, 'D.MM.YYYY'))+" ")])])]}}],null,false,1910143330)}):_vm._e(),(_vm.showClosedColumn === true)?_c('el-table-column',{attrs:{"sortable":true,"resizable":true,"label":"Закрыта","prop":"closed_at","filters":_vm.uniqueColumnValues('closed_at', _vm.dateFormat, function (date) { return date; } ),"filter-method":_vm.filter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var row = ref.row;
return [(_vm.isToday(row.closed_at))?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row.closed_at, 'HH:mm'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row.closed_at, 'D.MM.YYYY'))+" ")])]}}],null,false,519733316)}):_vm._e(),_vm._l((_vm.extraFields),function(field){return (field.display)?_c('el-table-column',{key:field.id,attrs:{"label":field.name,"filter-multiple":true,"filter-method":_vm.filterExtraColumn,"prop":field.name,"filters":_vm.uniqueExtraColumnValues(field)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var row = ref.row;
return [_c('field-item-value',{attrs:{"data":_vm.dataValueOf(row, field),"field":field}})]}}],null,true)}):_vm._e()}),(_vm.showApprovalColumn)?_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var row = ref.row;
return _vm._l(([_vm.requestedApprovalOfTask(row.id)]),function(approval){return _c('div',{key:approval.id},[(approval && approval.is_requested && !approval.is_granted)?_c('el-button',{on:{"click":function($event){return _vm.grantApproval($event, approval)}}},[_vm._v("Утвердить")]):_vm._e()],1)})}}],null,false,2122801056)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }