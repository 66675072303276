<template>
    <breadcrumb v-if="category" class="text-muted">
        <breadcrumb-item v-if="category.set">
            {{ category.set.title }}
        </breadcrumb-item>
        <breadcrumb-item v-if="category.parent && category.parent.parent">
            {{ category.parent.parent.title }}
        </breadcrumb-item>
        <breadcrumb-item v-if="category.parent">
            {{ category.parent.title }}
        </breadcrumb-item>
        <breadcrumb-item v-if="category">
          <router-link :to="`/category/${category.id}`" class="text-muted">
            {{ category.title }}
          </router-link>
        </breadcrumb-item>
    </breadcrumb>
</template>
<script>
    import Breadcrumb from "../Breadcrumb/Breadcrumb"
    import BreadcrumbItem from "../Breadcrumb/BreadcrumbItem"
    export default {
        name: 'category-breadcrumbs',
        components: {Breadcrumb, BreadcrumbItem},
        props: ['category']
    }
</script>
