<template>
    <div class="loading">
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
                 aria-valuemin="0" aria-valuemax="100" style="width: 100%" v-show="loading"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingBar',
    props: ['loading']
}
</script>