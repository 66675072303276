<template>
    <div class="task task-view page" ref="taskView">
      <div v-if="hasPermissionToSee === false" class="alert alert-danger">
        <span>У вас недостаточно прав для просмотра задач данной категории.</span>
      </div>

      <div class="not-found" v-if="notFound">
        <h3 class="mt-0">Ошибка 404</h3>
        <div>Страница не найдена</div>
      </div>

      <div v-if="hasAnyPermission === true">
            <div class="card">
                <loading-bar :loading="loading"/>

                <div class="card-header with-border" v-if="task && task.subject">
                    <h4 class="card-title">

                        <TaskSubject :task="task" :deleteTask="deleteTask" :can-delete="hasPermissionToDelete"/>

                        <button class="btn btn-link float-right"
                                v-if="task.current_state.description">
                            <font-awesome-icon icon="info"
                                               v-b-popover.hover="task.current_state.description"/>
                        </button>
                    </h4>
                    <div class="card-category">
                        <category-breadcrumbs :category="category"/>
                    </div>
                </div>

                <task-body :task="task" v-show="category && task && task.subject"
                           :has-permission-to-perform="hasPermissionToPerform"/>
            </div>

            <!-- Transitions -->
            <task-transition-actions :task="task" :actions="actions"
                                     :is-performer="isPerformer"
                                     :is-creator="isCreator"
                                     :has-permission-to-create="hasPermissionToCreate"
                                     :has-permission-to-perform="hasPermissionToPerform"/>

            <!-- Pick-a-task modal -->
            <b-modal class="subtask-assign-modal"
                     ref="pickTaskModal"
                     :title="popupTitle"
                     size="lg" body-class="p-0" hide-footer
                     v-if="pickedSubtaskRule">
                <LoadingBar :loading="loadingSubtasks" class="m-0"/>
                <task-subtasks v-show="!loadingSubtasks"
                               class="m-0 p-0"
                               :click-callback="pickTask"
                               :selected-callback="pickManyTasks"
                               :subtask-rule="pickedSubtaskRule"
                               :subtasks="tasksToPick"
                               :pickMode="true"
                />
            </b-modal>

            <!-- Subtasks -->
            <div v-if="task && taskCategory" class="mb-4">
              <div v-for="subtaskGroup of taskCategory.subtask_groups" :key="subtaskGroup.id">
                <h5 class="pl-3 pt-4">{{ subtaskGroup.title }}</h5>
                <b-tabs class="subtask-tabs">
                  <b-tab :title="subtaskRule.name"
                         class="p-0 m-0"
                         v-for="subtaskRule of subtaskGroup.subtask_rules" :key="subtaskRule.id">
                      <div class="p-0 m-0">
                        <task-subtasks
                            :key="subtaskRule.id"
                            ref="subtasks"
                            :subtask-rule="subtaskRule"
                            :subtasks="subtasksByRules[subtaskRule.id]"
                            mode="separate"/>
                      </div>
                      <div v-if="canCreateManualSubtask(subtaskRule) || canAssignSubtask(subtaskRule)">
                        <router-link v-if="canCreateManualSubtask(subtaskRule)"
                                     :to="getCreateSubtaskPath(subtaskRule)" tag="button"
                                     class="btn btn-wd btn-info btn-outline mr-2">
                          {{ subtaskRule.subtask_category.create_subtask_label || 'Создать подзадачу' }}
                        </router-link>
                        <button v-if="canAssignSubtask(subtaskRule)"
                                @click="assignSubtaskByRule(subtaskRule)"
                                class="btn btn-wd btn-info btn-outline">
                          {{ subtaskRule.subtask_category.attach_subtask_label || 'Прикрепить подзадачу' }}
                        </button>
                      </div>

                  </b-tab>
                </b-tabs>
              </div>

            </div>
            <!-- Tabs -->
            <task-tabs v-show="task && task.subject"
                       :task="task"
                       :subtasks="orphanSubtasks"
                       :is-creator="isCreator"
                       :is-performer="isPerformer"
                       ref="taskTabs"/>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LoadingBar from "../LoadingBar/LoadingBar.vue";
import TaskTransitionActions from "./TaskTransitionActions";
import TaskSubject from "./TaskSubject";
import TaskBody from "./TaskBody";
import TaskTabs from "./TaskTabs";
import TaskSubtasks from "./TaskSubtasks";
import CategoryBreadcrumbs from "./CategoryBreadcrumbs";
import _ from "lodash";

export default {
    name: "TaskView",
    components: {
        CategoryBreadcrumbs,
        TaskSubtasks,
        TaskTabs,
        TaskSubject,
        TaskBody,
        TaskTransitionActions,
        LoadingBar
    },
    data: () => ({
        subscribed: false,
        popupTitle:  'Выберите подазадчу',
        tasksToPick: [],
        pickedSubtaskRule: {},
        subtaskCategory: {},
        subtaskCategoryPresentation: {},
        loadingSubtasks: false,
        taskCategory: null,
        subtasksByRules: {},
        isLoadingSubtasks: null,
    }),
    computed: {
        ...mapGetters("users", {
            users: "collection",
            find: "find"
        }),
        ...mapGetters("auth", ["userData", "userId"]),
        ...mapGetters("task", {
          errors: 'errors',
          loading: 'loading',
          transitionErrors: 'transitionErrors',
          preloadedTask: 'preloadedTask',
          loadedTask: 'task',
          notFound: 'notFound',
        }),
        ...mapGetters("categories", [
            "category",
            "hasAnyPermission",
            "hasPermissionToSee",
            "hasPermissionToView",
            "hasPermissionToDelete",
            "hasPermissionToCreate",
            "hasPermissionToPerform",
            "hasPermissionToChangeCreator",
            "hasPermissionToChangePerformer",
        ]),
        task: state => {
          if (null !== state.loadedTask && state.taskId === state.loadedTask.id) {
            return state.loadedTask
          }

          return state.preloadedTask
        },
        categoryId: state => state.$route.params["categoryId"],
        taskId: state => parseInt(state.$route.params["taskId"]),
        isCreator: state => state.task ? state.task.creator_id === state.userId : null,
        isPerformer: state => state.task ? state.task.performer_id === state.userId : null,
        performer: state => state.task ? state.task.performer : null,
        creator: state => state.task ? state.task.creator : null,
        actions: state => {
            if (state.task && state.task.actions) {
                return state.task.actions.filter(action => state.actionIsAllowed(action));
            }
        },
        categorySeparateSubtaskRules: state => (state.category && _.has(state.category, 'subtask_rules'))
                ? state.category.subtask_rules.filter(rule => rule.show_separately)
                : [],
        orphanSubtaskRules: state => {
            if (!state.taskCategory) {
              return []
            }

            const groupedRulesIds = []
            for (const group of state.taskCategory.subtask_groups) {
              for (const rule of group.subtask_rules) {
                groupedRulesIds.push(rule.id)
              }
            }

            return state.taskCategory.subtask_rules.filter(rule => !groupedRulesIds.includes(rule.id))
        },
        orphanSubtasks: state => {
            const subtasks = []
            for (const rule of state.orphanSubtaskRules) {
              const tasksByRule = state.subtasksByRules[rule.id] || []
              console.log({tasksByRule, rule})
              for (const task of tasksByRule) {
                subtasks.push(task)
              }
            }
            return subtasks
        }
    },
    methods: {
        ...mapActions("notifications", ["readTaskNotifications"]),
        ...mapActions("task", ["fetchSubtasks"]),

        actionIsAllowed(action) {
            return (action.access === "TRANSITION_ACCESS_ANY" && (this.isPerformer || this.isCreator)) ||
                    (action.access === "TRANSITION_ACCESS_PERFORMER" && this.isPerformer) ||
                    (action.access === "TRANSITION_ACCESS_CREATOR" && this.isCreator);
        },

        /**
         * @param subtaskRule
         * @return {array}
         */
        getSubtasksByRule(subtaskRule) {
          return this.subtasksByRules[subtaskRule.id]
        },

        /**
         * @param subtaskRule
         * @return {string}
         */
        getCreateSubtaskPath(subtaskRule) {
            if (this.category && _.has(this.category, 'id') &&
                    this.task && _.has(this.task, 'id') &&
                    subtaskRule && _.has(subtaskRule, 'id')) {
                return `/category/${this.category.id}/task/${this.task.id}/create/${subtaskRule.id}`;
            } else {
                return '';
            }
        },

        /**
         * @param rule
         * @return {boolean}
         */
        canCreateManualSubtask(rule) {
            const parentCategoryStates = rule.parent_category_states;
            const task = this.task;
            if (undefined !== parentCategoryStates && null !== parentCategoryStates && parentCategoryStates.length > 0) {
                const currentStateId = task.current_state_id;
                let parentTaskStateIsAllowed = false;
                for (const state of parentCategoryStates) {
                    if (currentStateId === state.id) {
                        parentTaskStateIsAllowed = true;
                    }
                }
                if (!parentTaskStateIsAllowed) {
                    return false;
                }
            }

            if (this.task && _.has(this.task, 'is_closed') && this.task.is_closed) {
                return false;
            }

            if (rule.multiple && rule.enable_manual_tasks) {
                return true;
            }

            if (!rule.multiple && !this.getSubtasksByRule(rule).length) {
                return true;
            }

            return false;
        },

        /**
         * @param rule
         * @return {boolean}
         */
        canAssignSubtask(rule) {
            const parentCategoryStates = rule.parent_category_states;
            const task = this.task;
            if (undefined !== parentCategoryStates && null !== parentCategoryStates && parentCategoryStates.length > 0) {
                const currentStateId = task.current_state_id;
                let parentTaskStateIsAllowed = false;
                for (const state of parentCategoryStates) {
                    if (currentStateId === state.id) {
                        parentTaskStateIsAllowed = true;
                    }
                }
                if (!parentTaskStateIsAllowed) {
                    return false;
                }
            }

            if (this.task && _.has(this.task, 'is_closed') && this.task.is_closed) {
                return false;
            }

            if (rule.multiple && rule.enable_task_assign) {
                return true;
            }

            if (!rule.multiple && !this.getSubtasksByRule(rule).length) {
                return true;
            }

            return false;
        },

        /**
         * @param subtaskRule
         */
        async assignSubtaskByRule(subtaskRule) {
            const modal = this.$refs.pickTaskModal;
            modal.show();

            this.loadingSubtasks = true;

            const tasks = await this.$store.dispatch(
                    'tasks/fetchAttachableTasksBySubtaskRule',
                    {subtaskRuleId: subtaskRule.id, parentTaskId: this.taskId}
            );

            this.loadingSubtasks = false;
            this.pickedSubtaskRule = subtaskRule;
            this.tasksToPick = tasks;
            this.popupTitle = subtaskRule.subtask_category.attach_subtask_label || 'Выберите подазадчу';
        },

        /**
         * @param {object} subtask
         */
        pickTask(subtask) {
            if (subtask !== undefined && subtask !== null) {
                // Remove subtask from list
                this.tasksToPick = this.tasksToPick.filter(task => {
                    return task !== null && _.has(task, 'id') && task.id !== subtask.id;
                });

                // Assign subtask
                this.assignTaskAsSubtask(this.task, this.pickedSubtaskRule, subtask);
            }
        },

        pickManyTasks(subtasks) {
          for (const subtask of subtasks) {
            this.pickTask(subtask)
          }
          this.pickedSubtaskRule = null
        },

        /**
         * @param {object} parentTask
         * @param {object} subtaskRule
         * @param {object} subtask
         */
        assignTaskAsSubtask(parentTask, subtaskRule, subtask) {
            const self = this;
            self.$store.dispatch("task/assignSubtask", {parentTask, subtaskRule, subtask}).then(async () => {
              self.$notify({
                icon: "fa fa-check",
                group: "TaskView",
                title: "Подзадача назначена",
                type: "success"
              });

              if (undefined === self.subtasksByRules[subtaskRule.id]) {
                self.subtasksByRules[subtaskRule.id] = []
              }

              const collection = self.subtasksByRules[subtaskRule.id]
              const newIndex = collection.length
              collection.push(subtask)
              self.$store.dispatch('task/getTask', subtask.id)
                .then(updatedSubtask => {
                  collection.splice(newIndex)
                  collection.push(updatedSubtask)
                })
                .catch(() => {})
            }).catch((error) => {
                if ('subtask_already_assigned' === error) {
                    error = 'Эта подзадача уже назначена';
                } else if ('parent_task_state_is_incompatible' === error) {
                    error = 'Статус выбранной задачи не разрешен правилом подзадач';
                } else if ('subtask_state_is_incompatible' === error) {
                    error = 'Статус текущей задачи не разрешен правилом подзадач';
                } else {
                    // Revert deleted item
                    self.tasksToPick.push(subtask);
                }
                self.$notify({
                    group: "TaskView",
                    icon: "fa fa-times",
                    title: error ? error : 'Не удалось добавить подзадачу',
                    type: "danger"
                });
            });
        },

        deleteTask() {
            if (!this.hasPermissionToDelete) {
                this.$notify({
                    group: "TaskView",
                    icon: "fa fa-times",
                    title: "Недостаточно прав для удаления задач",
                    type: "danger"
                });
            }

            if (!confirm(`Вы хотите удалить задачу "${this.task.subject}"?`)) {
                return;
            }

            const self = this;
            const categoryId = this.task.category_id;
            self.$store.dispatch("task/delete", this.task.id).then(() => {
                self.$router.push(`/category/${categoryId}/active`);
                self.$notify({
                    icon: "fa fa-check",
                    group: "TaskView",
                    title: "Задача удалена",
                    type: "success"
                });
            }).catch((error) => {
                self.$notify({
                    group: "TaskView",
                    icon: "fa fa-times",
                    title: error || "Задача не удалена",
                    type: "danger"
                });
            });
        },

        fetchTaskCategory(task) {
          console.debug(`Fetching category #${task.category_id} of task #${task.id}`)
          this.$store.dispatch(`categories/fetch`, task.category_id)
            .then(category => {
              this.taskCategory = category
              this.fetchSubtasksForTask(task.id)
            })
            .catch(() => {})
        },

        fetchSubtasksForTask(taskId) {
          const self = this
          const subtaskRules = self.taskCategory.subtask_rules

          self.isLoadingSubtasks = true

          for (const subtaskRule of subtaskRules) {
            const params = {
              taskId: taskId,
              ruleId: subtaskRule.id
            }

            console.debug(`Fetching subtasks of task #${taskId} by rule #${subtaskRule.id} `)

            self.fetchSubtasks(params)
                .then(({data}) => {
                  console.log({data})
                  self.isLoadingSubtasks = false
                  const tempCollection = Object.assign({}, self.subtasksByRules)
                  tempCollection[subtaskRule.id] = Object.assign([], data)
                  self.subtasksByRules = Object.assign({}, tempCollection)
                })
                .catch(response => {
                  console.error(response)
                  self.isLoadingSubtasks = null
                })
          }
        },
    },
    mounted() {
        this.readTaskNotifications();

        for (const subtaskRule of this.categorySeparateSubtaskRules) {
            if (!subtaskRule.subtask_category_presentation_id) {
              continue
            }
            this.$store.dispatch('categories/fetchPresentationOnly', {presentationId: subtaskRule.subtask_category_presentation_id})
        }

        this.$store.dispatch("notifications/readTaskNotifications", this.taskId);

        this.$store.dispatch("task/subscribe", this.taskId);

        this.task = this.preloadedTask
        this.$store.dispatch("task/fetch", this.taskId)
                .then((task) => {
                  document.title = task.subject;
                  // this.task = task
                  this.fetchTaskCategory(task)
                })
                .catch();

        /**
         * Subscribe for task updates channel
         */
        this.$store.dispatch("task/subscribe", this.taskId).catch();
    }
};
</script>

<style>
.not-found {
  text-align: center;
  height: 60vh;
  color: #666666;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>