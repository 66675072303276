<template>
  <router-view></router-view>
</template>

<script>
  export default {
    async created() {
      this.$store.dispatch("connection/check").catch();
      await this.$store.dispatch("settings/fetch").then().catch();
      this.$store.dispatch("categories/fetchSets").then().catch();
    }
  }
</script>
