<template>
  <div class="statistics-block">
    <h5 class="subtitle px-4 mb-2">{{ title }}</h5>
    <div class="px-4 py-2 font-italic small" v-if="data.length === 0">Нет данных</div>
    <div class="row" v-else>
      <div class="col col-sm-12 col-md-6">
        <el-table
          ref="table"
          :data="data"
          empty-text="Нет данных">
          <el-table-column min-width="70" :sortable="false" prop="label" :label="itemLabel"/>
          <el-table-column min-width="30" :sortable="false" prop="count" label="Количество"/>
        </el-table>
      </div>
      <div class="col col-sm-12 col-md-6" v-show="data.length > 0">
        <apexchart
          width="300"
          type="pie"
          :options="getChartOptions(data)"
          :series="getChartData(data)"/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'StatisticsBlock',
    props: {
      title: () => '',
      itemLabel: () => '',
      data: {
        type: Array
      }
    },
    data: () => ({
      chartOptions: {
        chart: {
          type: 'pie',
          width: 300
        },
        legend: {
          position: 'bottom'
        },
        labels: [],
        dataLabels: {
          enabled: false
        }
      }
    }),
    methods: {
      getChartData(collection) {
        return collection.map(item => item.count)
      },
      getChartOptions(collection) {
        const options = Object.assign({}, this.chartOptions);
        options.labels = collection.map(item => item.label);
        return options
      },
    }
  }
</script>
