<template>
  <div class="statistics-view page" ref="cat">
    <access-denied
      :show="hasPermissionToViewStatistics === false"
      message="У вас недостаточно прав для доступа к этой странице"/>

    <div v-if="hasPermissionToViewStatistics === true" class="card pb-3">

      <loading-bar :loading="loading"/>

      <div class="card-header category-header">

        <div class="category-title-col">
          <h4 class="card-title" v-html="categoryTitle"/>
          <div class="card-category">
            Статистика категории
          </div>
        </div>

        <div class="presentation-btns-col text-right">
          <ul class="nav  nav-stacked mt-0 justify-content-end">
            <li>
              <button class="btn btn-sm"
                      :class="{'btn-link': viewName !== 'active', 'btn-default': viewName === 'active'}"
                      @click="switchView('active')">Активные
              </button>
              <button class="btn btn-sm"
                      :class="{'btn-link': viewName !== 'closed', 'btn-default': viewName === 'closed'}"
                      @click="switchView('closed')">Закрытые
              </button>
            </li>
          </ul>
        </div>

      </div>

      <!-- Filter -->
      <div class="card-body statistics-filter" v-show="viewName === 'closed'">
        <div class="row">
          <div class="col">
            <select v-model="filter.show" class="custom-select">
              <option value="">Все задачи</option>
              <option value="created">Созданные в период</option>
              <option value="closed">Закрытые в период</option>
              <option value="created-and-closed">Созданные и закрытые в период</option>
            </select>
          </div>
          <div class="col" v-if="filter.show !== ''">
            <select v-model="filter.period" class="custom-select">
              <option value="this-week">Эта неделя</option>
              <option value="last-week">Прошлая неделя</option>
              <option value="this-month">Этот месяц</option>
              <option value="last-month">Прошлый месяц</option>
              <option value="exact">Произвольный период</option>
            </select>
          </div>
          <div class="col col-5 d-flex" v-if="filter.period === 'exact'">
            <el-date-picker
              style="margin-right: 10px"
              v-model="filter.from"
              type="date"
              :format="'dd.MM.yyyy'"
              placeholder="Начало периода"/>
            <el-date-picker
              v-model="filter.to"
              type="date"
              :format="'dd.MM.yyyy'"
              placeholder="Окончание периода"/>
          </div>
          <div class="col">
            <button class="btn btn-outline" @click="filterList">Показать</button>
          </div>
        </div>
      </div>

      <div class="card-body pl-0 my-0" v-show="viewName === 'closed' && statesDataset.length > 0">
        <StatesStatisticsBlock :data="statesDataset"/>
      </div>

      <div class="card-body with-table-with-gap p-0 my-4 tab-content">
        <StatisticsBlock title="По статусу" :item-label="'Статус'" :data="byState"/>
      </div>

      <div class="card-body with-table-with-gap p-0 my-4 tab-content">
        <StatisticsBlock title="По заказчику" :item-label="'Заказчик'" :data="byCreator"/>
      </div>

      <div class="card-body with-table-with-gap p-0 my-4 tab-content">
        <StatisticsBlock title="По исполнителю" :item-label="'Исполнитель'" :data="byPerformer"/>
      </div>

      <div class="card-body with-table-with-gap p-0 my-4 tab-content"
           v-for="(sets, fieldTitle) in currentDataset.by_field" :key="fieldTitle">
        <StatisticsBlock :title="`По полю ${fieldTitle}`" :item-label="'Значение поля'" :data="sets"/>
      </div>

    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import {mapGetters} from 'vuex';
  import LoadingBar from '../components/LoadingBar/LoadingBar';
  import AccessDenied from "../components/Messages/AccessDenied";
  import StatisticsBlock from "../components/Statistics/StatisticsBlock";
  import StatesStatisticsBlock from "../components/Statistics/StatesStatisticsBlock";

  export default {
    name: "StatisticsView",
    components: {StatisticsBlock, StatesStatisticsBlock, AccessDenied, LoadingBar},
    data: () => ({
      dataset: {},
      statesDataset: [],
      filter: {
        show: '',
        period: null,
        from: null,
        to: null,
      }
    }),
    watch: {
      'filter.show': {
        handler: function (value, oldValue) {
          if (oldValue === '' && value !== '') {
            this.filter.period = 'this-month';
          }
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters("categories", ["category", "loading", "hasPermissionToViewStatistics",
        "categoryTitle", "getStatistics", "getStatesStatistics"]),
      categoryId: state => state.$route.params.categoryId ? parseInt(state.$route.params.categoryId) : null,
      viewName: state => state.$route.params.viewName ? state.$route.params.viewName : 'active',
      currentDataset: state => state.dataset[state.viewName],
      byState: state => state.currentDataset !== undefined ? state.currentDataset.by_state : [],
      byCreator: state => state.currentDataset !== undefined ? state.currentDataset.by_creator : [],
      byPerformer: state => state.currentDataset !== undefined ? state.currentDataset.by_performer : [],
      query: state => {
        const {show, period, from, to} = state.filter;
        const lastWeek = {
          from: moment()
            .subtract(1, 'week')
            .startOf('week')
            .format('YYYY-MM-DD HH:mm:ss'),
          to: moment()
            .subtract(1, 'week')
            .endOf('week')
            .format('YYYY-MM-DD HH:mm:ss'),
        };
        const lastMonth = {
          from: moment()
            .subtract(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD HH:mm:ss'),
          to: moment()
            .subtract(1, 'month')
            .endOf('month')
            .format('YYYY-MM-DD HH:mm:ss'),
        };
        const thisWeek = {
          from: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          to: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss'),
        };
        const thisMonth = {
          from: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          to: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        };
        const customPeriod = {
          from: moment(from)
            .hours(0).minutes(0).seconds(0)
            .format('YYYY-MM-DD HH:mm:ss'),
          to: moment(to)
            .hours(23).minutes(59).seconds(59)
            .format('YYYY-MM-DD HH:mm:ss')
        };

        if (show === '') {
          return {}
        }

        if (show === 'created') {
          switch (period) {
            case 'last-week':
              return {
                created_from: lastWeek.from,
                created_to: lastWeek.to
              };
            case 'this-week':
              return {
                created_from: thisWeek.from,
                created_to: thisWeek.to
              };
            case 'last-month':
              return {
                created_from: lastMonth.from,
                created_to: lastMonth.to
              };
            case 'this-month':
              return {
                created_from: thisMonth.from,
                created_to: thisMonth.to
              };
            default:
              return {
                created_from: customPeriod.from,
                created_to: customPeriod.to
              }
          }
        }

        if (show === 'closed') {
          switch (period) {
            case 'last-week':
              return {
                closed_from: lastWeek.from,
                closed_to: lastWeek.to
              };
            case 'this-week':
              return {
                closed_from: thisWeek.from,
                closed_to: thisWeek.to
              };
            case 'last-month':
              return {
                closed_from: lastMonth.from,
                closed_to: lastMonth.to
              };
            case 'this-month':
              return {
                closed_from: thisMonth.from,
                closed_to: thisMonth.to
              };
            default:
              return {
                closed_from: customPeriod.from,
                closed_to: customPeriod.to
              }
          }
        }

        if (show === 'created-and-closed') {
          switch (period) {
            case 'last-week':
              return {
                created_from: lastWeek.from,
                closed_to: lastWeek.to
              };
            case 'this-week':
              return {
                created_from: thisWeek.from,
                closed_to: thisWeek.to
              };
            case 'last-month':
              return {
                created_from: lastMonth.from,
                closed_to: lastMonth.to
              };
            case 'this-month':
              return {
                created_from: thisMonth.from,
                closed_to: thisMonth.to
              };
            default:
              return {
                created_from: customPeriod.from,
                closed_to: customPeriod.to
              }
          }
        }
      }
    },
    methods: {
      switchView(view) {
        if (view && this.categoryId) {
          this.$router.push(`/statistics/${this.categoryId}/${view}`)
        }
      },

      fetch(filters) {
        const self = this;
        self.$store.dispatch(
            `categories/fetchCategoryStatistics`,
            {categoryId: self.categoryId, filters})
            .then(() => {
              self.dataset = self.getStatistics(self.categoryId);
            })
            .catch();

        self.$store.dispatch(
            `categories/fetchCategoryStatesStatistics`,
            {categoryId: self.categoryId, filters})
            .then(() => {
              self.statesDataset = self.getStatesStatistics(self.categoryId);
            })
            .catch();
      },

      filterList() {
        this.fetch(this.query);
      }
    },
    async mounted() {
      const self = this;
      self.fetch({});

      await self.$store.dispatch(`categories/fetch`, self.categoryId)
          .then()
          .catch();
    }
  }
</script>
<style lang="sass">
  .statistics-filter.card-body
    padding-left: 24px !important
    border-top: 1px solid #eee
    border-bottom: 1px solid #eee
</style>
