<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar, 'disconnected':!connected, 'offline':offline}">
    <internet-connection-check/>
    <notifications/>

    <SidebarMenu />

    <div class="main-panel">
      <top-navbar/>
      <dropzone/>
      <dashboard-content @click.native="toggleSidebar" ref="dashboardContent"/>
    </div>
  </div>
</template>
<script>
  import TopNavbar from "./TopNavbar.vue";
  import SidebarMenu from "./SidebarMenu.vue";
  import DashboardContent from "./Content.vue";
  import {mapGetters} from "vuex";
  import InternetConnectionCheck from "./Extra/InternetConnectionCheck";
  import Dropzone from "../Dropzone/Dropzone";

  export default {
    components: {
      Dropzone,
      InternetConnectionCheck,
      TopNavbar,
      SidebarMenu,
      DashboardContent
    },
    computed: {
      ...mapGetters("auth", ["userData", "loggedIn", "userId"]),
      ...mapGetters("categories", ["sets", "category"]),
      ...mapGetters("connection", ["connected", "offline"]),
    },
    watch: {
      userId: {
        handler: function (userId) {
          if (userId) {
            this.subscribe(userId);
          }
        },
        initial: true
      }
    },
    methods: {
      subscribe(userId) {
        this.$store.dispatch("notifications/subscribe", userId).catch();
      },

      /**
       * Toggle sidebar
       */
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
    }
  };
</script>

<style lang="scss">
  .vue-notifyjs.notifications {
    .list-move {
      transition: transform 0.3s, opacity 0.4s;
    }

    .list-item {
      display: inline-block;
      margin-right: 10px;

    }

    .list-enter-active {
      transition: transform 0.2s ease-in, opacity 0.4s ease-in;
    }

    .list-leave-active {
      transition: transform 1s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
      opacity: 0;
      transform: scale(1.1);

    }

    .list-leave-to {
      opacity: 0;
      transform: scale(1.2, 0.7);
    }
  }
</style>
