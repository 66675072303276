import {CoreApi, handleError} from "../../lib/HttpApi";

const MENU_FETCH_START = "MENU_FETCH_START";
const MENU_FETCH_SUCCESS = "MENU_FETCH_SUCCESS";
const MENU_FETCH_FAILED = "MENU_FETCH_FAILED";

const state = {
  menu: localStorage.getItem("menu") ? JSON.parse(localStorage.getItem("menu")) : [],
  loading: false,
};
const getters = {
  menu: state => state.menu,
  loading: state => state.loading,
};

const actions = {
  fetch: ({commit}) => {
    commit(MENU_FETCH_START);
    return new Promise((resolve, reject) => {
      CoreApi.get('/menu')
        .then(({data}) => {
          const menu = data.data;
          localStorage.setItem("menu", JSON.stringify(menu));
          commit(MENU_FETCH_SUCCESS, menu);
          resolve(menu);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(MENU_FETCH_FAILED, error);
          reject(error);
        });
    });
  },
};

const mutations = {
  [MENU_FETCH_START](state) {
    state.loading = true;
  },

  [MENU_FETCH_FAILED](state) {
    state.loading = false;
  },

  [MENU_FETCH_SUCCESS](state, menu) {
    state.menu = [...menu];
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
