import {CoreApi, handleError} from "../../lib/HttpApi";
import _ from "lodash";
import axios from "axios";

const CATEGORY_FETCH = "CATEGORY_FETCH";
const CATEGORY_FETCH_SUCCESS = "CATEGORY_FETCH_SUCCESS";
const CATEGORY_FETCH_FAILED = "CATEGORY_FETCH_FAILED";
const CATEGORY_FETCH_SUBTASK_RULE = "CATEGORY_FETCH_SUBTASK_RULE";
const CATEGORY_FETCH_SUBTASK_RULE_SUCCESS = "CATEGORY_FETCH_SUBTASK_RULE_SUCCESS";
const CATEGORY_FETCH_SUBTASK_RULE_FAILED = "CATEGORY_FETCH_SUBTASK_RULE_FAILED";
const CATEGORY_FETCH_VIEW = "CATEGORY_FETCH_VIEW";
const CATEGORY_SILENT_FETCH_VIEW = "CATEGORY_SILENT_FETCH_VIEW";
const CATEGORY_FETCH_VIEW_SUCCESS = "CATEGORY_FETCH_VIEW_SUCCESS";
const CATEGORY_FETCH_VIEW_FAILED = "CATEGORY_FETCH_VIEW_FAILED";
const CATEGORY_FETCH_DEPARTMENTS = "CATEGORY_FETCH_DEPARTMENTS";
const CATEGORY_FETCH_DEPARTMENTS_SUCCESS = "CATEGORY_FETCH_DEPARTMENTS_SUCCESS";
const CATEGORY_FETCH_DEPARTMENTS_FAILED = "CATEGORY_FETCH_DEPARTMENTS_FAILED";
const CATEGORY_SILENT_FETCH_PRESENTATION = "CATEGORY_SILENT_FETCH_PRESENTATION";
const CATEGORY_SILENT_FETCH_PRESENTATION_SUCCESS = "CATEGORY_SILENT_FETCH_PRESENTATION_SUCCESS";
const CATEGORY_SILENT_FETCH_PRESENTATION_FAILED = "CATEGORY_SILENT_FETCH_PRESENTATION_FAILED";
const CATEGORIES_FETCH = "CATEGORIES_FETCH";
const CATEGORIES_FETCH_SUCCESS = "CATEGORIES_FETCH_SUCCESS";
const CATEGORIES_FETCH_FAILED = "CATEGORIES_FETCH_FAILED";
const CATEGORIES_FETCH_SETS = "CATEGORIES_FETCH_SETS";
const CATEGORIES_FETCH_SETS_SUCCESS = "CATEGORIES_FETCH_SETS_SUCCESS";
const CATEGORIES_FETCH_SETS_FAILED = "CATEGORIES_FETCH_SETS_FAILED";
const CATEGORIES_UPDATE_TASK_IN_COLLECTION = "CATEGORIES_UPDATE_TASK_IN_COLLECTION";
const CATEGORY_FETCH_COLUMN_VALUES_SUCCESS = "CATEGORY_FETCH_COLUMN_VALUES_SUCCESS";
const CATEGORY_RESET_CREATORS = "CATEGORY_RESET_CREATORS";
const CATEGORY_FETCH_CREATORS = "CATEGORY_FETCH_CREATORS";
const CATEGORY_FETCH_CREATORS_SUCCESS = "CATEGORY_FETCH_CREATORS_SUCCESS";
const CATEGORY_FETCH_CREATORS_FAILED = "CATEGORY_FETCH_CREATORS_FAILED";
const CATEGORY_RESET_PERFORMERS = "CATEGORY_RESET_PERFORMERS";
const CATEGORY_FETCH_PERFORMERS = "CATEGORY_FETCH_PERFORMERS";
const CATEGORY_FETCH_PERFORMERS_SUCCESS = "CATEGORY_FETCH_PERFORMERS_SUCCESS";
const CATEGORY_FETCH_PERFORMERS_FAILED = "CATEGORY_FETCH_PERFORMERS_FAILED";
const CATEGORY_FETCH_STATISTICS = "CATEGORY_FETCH_STATISTICS";
const CATEGORY_FETCH_STATISTICS_SUCCESS = "CATEGORY_FETCH_STATISTICS_SUCCESS";
const CATEGORY_FETCH_STATISTICS_FAILED = "CATEGORY_FETCH_STATISTICS_FAILED";
const CATEGORY_FETCH_STATES_STATISTICS = "CATEGORY_FETCH_STATES_STATISTICS";
const CATEGORY_FETCH_STATES_STATISTICS_SUCCESS = "CATEGORY_FETCH_STATES_STATISTICS_SUCCESS";
const CATEGORY_FETCH_STATES_STATISTICS_FAILED = "CATEGORY_FETCH_STATES_STATISTICS_FAILED";
const CATEGORY_RESET = "CATEGORY_RESET";

const state = {
  cancelToken: null,
  viewCancelToken: null,

  // Collections
  errors: [],
  sets: [],
  categories: [],
  categoriesDepartments: {},

  // Current loaded category
  loaded: {},
  views: {},
  viewsByCategories: {},
  view: {},
  tasks: {},
  statistics: {},
  statesStatistics: {},
  performers: [],
  creators: [],
  pagination: {},
  columnsFiltersValues: {},

  isLoaded: false,

  creatorsIsLoaded: false,
  allCreatorsFetched: false,
  hasMoreCreators: true,

  performersIsLoaded: false,
  allPerformersFetched: false,
  hasMorePerformers: true,

  loading: false,
  loadingView: false
};

const getters = {
  sets: state => state.sets,
  categories: state => state.categories,

  departments: state => state.categoriesDepartments,
  categoryDepartments: state => categoryId => undefined !== state.categoriesDepartments[categoryId]
    ? state.categoriesDepartments[categoryId] : [],

  statistics: state => state.statistics,
  getStatistics: () => categoryId => _.has(state.statistics, categoryId) ? state.statistics[categoryId] : null,
  getStatesStatistics: () => categoryId => _.has(state.statesStatistics, categoryId) ? state.statesStatistics[categoryId] : [],

  loaded: state => state.loaded,
  category: state => state.loaded,
  views: state => state.views,
  view: state => state.view,
  tasks: state => state.tasks,
  performers: state => state.performers,
  creators: state => state.creators,

  creatorsIsLoaded: state => state.creatorsIsLoaded,
  allCreatorsFetched: state => state.allCreatorsFetched,
  hasMoreCreators: state => state.hasMoreCreators,

  performersIsLoaded: state => state.performersIsLoaded,
  allPerformersFetched: state => state.allPerformersFetched,
  hasMorePerformers: state => state.hasMorePerformers,

  pagination: state => state.pagination,
  loading: state => state.loading || state.loadingView,
  findSet: state => id => state.sets.find(user => user.id === id),
  findCategory: state => id => state.categories.find(category => category.id === id),
  fields: (state, getters) => getters.loaded ? getters.loaded.fields : [],
  categoryViews: (state, getters) => getters.loaded ? getters.loaded.views : [],
  categoryTitle: (state, getters) => getters.loaded ? getters.loaded.title : '',
  columnsFiltersValues: state => state.columnsFiltersValues,
  columnFilters: (state, getters) => (categoryId, column) => {
    return (_.has(getters.columnsFiltersValues, categoryId) && _.has(getters.columnsFiltersValues[categoryId], column))
        ? getters.columnsFiltersValues[categoryId][column] : []
  },
  permissions: (state, getters) => getters.loaded ? getters.loaded.user_permissions : [],
  hasPermissionTo: state => action => {
    if (true !== state.isLoaded) {
      return null;
    }

    const category = state.loaded;

    if (!category) {
      return null;
    }

    if (undefined === category.user_permissions) {
      // console.error('Property `user_permission` is not set in Category object. Please check response from server.', category);
      return null;
    }

    const permissions = category.user_permissions;

    if (!permissions || !_.has(permissions, action)) {
      // console.error(`Permissions list doesn't contain action '${action}'. Please check response from server.`, category);
      return null;
    }

    return permissions[action];
  },
  hasPermissionToSee: (state, getters) => getters.hasPermissionTo('see'),
  hasPermissionToView: (state, getters) => getters.hasPermissionTo('view'),
  hasPermissionToDelete: (state, getters) => getters.hasPermissionTo('delete'),
  hasPermissionToCreate: (state, getters) => getters.hasPermissionTo('create'),
  hasPermissionToPerform: (state, getters) => getters.hasPermissionTo('perform'),
  hasPermissionToChangeCreator: (state, getters) => getters.hasPermissionTo('change_creator'),
  hasPermissionToChangePerformer: (state, getters) => getters.hasPermissionTo('change_performer'),
  hasPermissionToChangeSpectators: (state, getters) => getters.hasPermissionTo('create')
      || getters.hasPermissionTo('perform'),
  hasPermissionToViewStatistics: (state, getters) => getters.hasPermissionTo('view_statistics'),
  hasAnyPermission: (state, getters) => {
    return getters.hasPermissionToSee ||
      getters.hasPermissionToView ||
      getters.hasPermissionToCreate ||
      getters.hasPermissionToPerform ||
      getters.hasPermissionToChangeCreator ||
      getters.hasPermissionToChangePerformer;
  }
};

const actions = {
  reset: ({commit}) => {
    commit(CATEGORY_RESET);
  },

  /**
   * @param commit
   * @param params
   */
  fetchSets: ({commit}) => {
    commit(CATEGORIES_FETCH_SETS);
    return new Promise((resolve, reject) => {
      CoreApi.get(`/set`)
        .then(response => {
          const setsCollection = response.data.data;
          commit(CATEGORIES_FETCH_SETS_SUCCESS, {collection: setsCollection});
          resolve(setsCollection);
          // dispatch("fetchEveryCategory", {sets: setsCollection});
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(CATEGORIES_FETCH_SETS_FAILED, error);
          reject(error);
        });
    });
  },

  fetchEveryCategory: ({dispatch}, {sets}) => {
    for (const set of sets) {
      for (const category of set.categories) {
        // console.log(`Fetch category ${category.title} of collection...`);

        dispatch("fetch", category.id);
      }
    }
  },

  /**
   * @todo Instead of passing user data pass user token via headers
   * @param commit
   * @param params
   */
  fetchCategories: ({commit}) => {
    // console.log('Fetch categories')
    commit(CATEGORIES_FETCH);
    return new Promise((resolve, reject) => {
      CoreApi.get(`/categories`)
        .then(response => {
          commit(CATEGORIES_FETCH_SUCCESS, {collection: response.data.data});
          resolve(response.data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(CATEGORIES_FETCH_FAILED, error);
          reject(error);
        });
    });
  },

  /**
   * Fetch one category
   * @param commit
   * @param getters
   * @param id
   * @returns {Promise<any>}
   */
  fetch: ({commit, getters}, id) => {
    // console.log('Find category')
    const cancelToken = axios.CancelToken.source();
    commit(CATEGORY_FETCH, cancelToken);

    return new Promise((resolve) => {
      const foundCategory = getters.findCategory(id);
      console.log({foundCategory})
      if (foundCategory && foundCategory.is_fully_loaded) {
        // Return loaded category
        console.log("Instead of fetching category from server we’ve just loaded it from cache");
        commit(CATEGORY_FETCH_SUCCESS, {category: foundCategory});
        resolve(foundCategory);
      } else {
        // Or fetch it from server
        console.log("Fetching category from server");
        CoreApi
          .get(`/categories/${id}`, {cancelToken: cancelToken.token})
          .then(({data}) => {
            commit(CATEGORY_FETCH_SUCCESS, {category: data.data});
            resolve(data.data);
          });
      }
    });
  },

  fetchCategoryStatistics: ({commit}, {categoryId, filters}) => {
    commit(CATEGORY_FETCH_STATISTICS);
    let url = `/categories/${categoryId}/statistics?`;
    if (_.has(filters, 'created_from')) {
      url += `created_from=${filters.created_from}&`
    }
    if (_.has(filters, 'created_to')) {
      url += `created_to=${filters.created_to}&`
    }
    if (_.has(filters, 'closed_from')) {
      url += `closed_from=${filters.closed_from}&`
    }
    if (_.has(filters, 'closed_to')) {
      url += `closed_to=${filters.closed_to}`
    }
    return new Promise((resolve, reject) => {
      CoreApi.get(url)
        .then(({data}) => {
          const statistics = data.data;
          commit(CATEGORY_FETCH_STATISTICS_SUCCESS, {categoryId, statistics});
          resolve(data)
        })
        .catch(({response}) => {
          console.error(response);
          const error = handleError(response);
          commit(CATEGORY_FETCH_STATISTICS_FAILED, error);
          reject(error);
        });
    });
  },

  fetchCategoryStatesStatistics: ({commit}, {categoryId, filters}) => {
    commit(CATEGORY_FETCH_STATES_STATISTICS);
    let url = `/categories/${categoryId}/statistics/states?`;
    if ({} !== filters && _.has(filters, 'created_from')) {
      url += `created_from=${filters.created_from}&`
    }
    if ({} !== filters && _.has(filters, 'created_to')) {
      url += `created_to=${filters.created_to}&`
    }
    if ({} !== filters && _.has(filters, 'closed_from')) {
      url += `closed_from=${filters.closed_from}&`
    }
    if ({} !== filters && _.has(filters, 'closed_to')) {
      url += `closed_to=${filters.closed_to}`
    }
    return new Promise((resolve, reject) => {
      CoreApi.get(url)
        .then(({data}) => {
          const statistics = data.data;
          commit(CATEGORY_FETCH_STATES_STATISTICS_SUCCESS, {categoryId, statistics});
          resolve(data)
        })
        .catch(({response}) => {
          console.error(response);
          const error = handleError(response);
          commit(CATEGORY_FETCH_STATES_STATISTICS_FAILED, error);
          reject(error);
        });
    });
  },

  fetchCategoryCreators: ({commit}, {categoryId, query}) => {
    commit(CATEGORY_FETCH_CREATORS);
    const url = `/categories/${categoryId}/creators?query=${query}`;
    return new Promise((resolve, reject) => {
      CoreApi.get(url)
        .then(({data}) => {
          data.query = query;
          commit(CATEGORY_FETCH_CREATORS_SUCCESS, data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(CATEGORY_FETCH_CREATORS_FAILED, error);
          reject(error);
        });
    });
  },

  fetchCategoryPerformers: ({commit}, {categoryId, query}) => {
    commit(CATEGORY_FETCH_PERFORMERS);
    const url = `/categories/${categoryId}/performers?query=${query}`;
    return new Promise((resolve, reject) => {
      CoreApi.get(url)
        .then(({data}) => {
          data.query = query;
          commit(CATEGORY_FETCH_PERFORMERS_SUCCESS, data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(CATEGORY_FETCH_PERFORMERS_FAILED, error);
          reject(error);
        });
    });
  },

  /**
   * Fetch category view
   * @param commit
   * @param id
   * @param view
   * @param page
   * @param sorting
   * @param perPage
   * @param filter
   * @param acceptMax
   * @returns {Promise<any>}
   */
  fetchView: ({commit}, {id, view, page, perPage, sorting, filter, acceptMax}) => {
    commit(CATEGORY_FETCH_VIEW);
    return actions.silentFetchView({commit}, {id, view, page, perPage, sorting, filter, acceptMax});
  },

  /**
   * Fetch category view
   * @param commit
   * @param id
   * @param view
   * @param page
   * @param sorting
   * @param perPage
   * @param filter
   * @param acceptMax
   * @returns {Promise<any>}
   */
  silentFetchView: ({commit}, {id, view, page, perPage, sorting, filter, acceptMax}) => {
    if (undefined === view) {
      console.error('Check view name');
    }
    const cancelToken = axios.CancelToken.source();
    commit(CATEGORY_SILENT_FETCH_VIEW, cancelToken);
    return new Promise((resolve, reject) => {
      // console.log("Fetching view from server", {id, view, page, perPage, sorting, filter, acceptMax});

      let accept = '';
      if (acceptMax) {
        accept = `&accept_max=` + acceptMax
      }

      let per_page = '';
      if (perPage) {
        per_page = `&per_page=` + perPage
      }

      let sort = '';
      if (sorting !== null && typeof sorting === 'object' && _.has(sorting, 'column')) {
        let order = (_.has(sorting, 'order') && sorting.order === 'desc') ? 'desc' : 'asc';
        if (sorting.column !== null) {
          sort = `&sorting[${sorting.column}]=${order}`;
        }
      }

      let filters = '';
      if (filter !== undefined && filter !== null) {
        for (const column in filter) {
          if (_.has(filter, column)) {
            const values = filter[column];
            for (const value of values) {
              filters += `&filter[${column}][]=${encodeURIComponent(value)}`
            }
          }
        }
      }
      const url = `/categories/${id}/${view}?page=` + page + per_page + sort + filters + accept;

      CoreApi.get(url, {cancelToken: cancelToken.token})
          .then(({data}) => {
            commit(CATEGORY_FETCH_VIEW_SUCCESS, data);
            if (_.has(data, 'meta') && _.has(data.view, 'filter_values')) {
              for (const column in data.view.filter_values) {
                const values = data.view.filter_values[column];
                commit(CATEGORY_FETCH_COLUMN_VALUES_SUCCESS, {values, column, categoryId: id});
              }
            }
            resolve(data);
          })
          .catch((thrown) => {
            if (axios.isCancel(thrown)) {
              // console.log('Fetch view request canceled')
            }else {
              const {response} = thrown;
              const error = handleError(response);
              commit(CATEGORY_FETCH_VIEW_FAILED, error);
              reject(error);
            }
          });
    });
  },

  fetchPresentationOnly: ({commit}, {presentationId}) => {
    commit(CATEGORY_SILENT_FETCH_PRESENTATION, presentationId);
    return new Promise((resolve, reject) => {
      console.log("Fetching presentation from server", {presentationId});

      const url = `/presentations/${presentationId}`;

      CoreApi.get(url)
          .then(({data}) => {
            commit(CATEGORY_SILENT_FETCH_PRESENTATION_SUCCESS, {view: data.data});
            resolve(data);
          })
          .catch((thrown) => {
            if (axios.isCancel(thrown)) {
              console.log('Fetch view request canceled')
            } else {
              const {response} = thrown;
              const error = handleError(response);
              commit(CATEGORY_SILENT_FETCH_PRESENTATION_FAILED, error);
              reject(error);
            }
          });
    });
  },

  /**
   * Get column values for filter
   *
   * @param commit
   * @param view
   * @param column
   * @param categoryId
   * @return {Promise<any>}
   * @deprecated
   */
  fetchPresentationColumnValues({commit}, {view, column, categoryId}) {
    return new Promise((resolve) => {
      const url = `/categories/${categoryId}/${view}/column/${column}`;
      CoreApi.get(url)
        .then(({data}) => {
          commit(CATEGORY_FETCH_COLUMN_VALUES_SUCCESS, {
            values: data.data,
            column,
            categoryId
          });
          resolve(data.data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          console.error(error);
          commit(CATEGORY_FETCH_COLUMN_VALUES_SUCCESS, {
            values: [],
            column,
            categoryId
          });
          resolve([]);
        });
    });
  },

  /**
   * Fetch subtask rule
   * @param commit
   * @param subtaskRuleId
   * @return {Promise<any>}
   */
  fetchSubtaskRule: ({commit}, subtaskRuleId) => {
    commit(CATEGORY_FETCH_SUBTASK_RULE);
    return new Promise((resolve, reject) => {
      // console.log("Fetching subtask rule from server");
      CoreApi.get(`/subtask_rule/${subtaskRuleId}`)
        .then(({data}) => {
          commit(CATEGORY_FETCH_SUBTASK_RULE_SUCCESS, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(CATEGORY_FETCH_SUBTASK_RULE_FAILED, error);
          reject(error);
        });
    });
  },

  fetchCategoryDepartments: ({commit, state}, categoryId) => {
    commit(CATEGORY_FETCH_DEPARTMENTS);
    return new Promise((resolve, reject) => {

      if (undefined !== state.categoriesDepartments[categoryId]) {
        resolve(state.categoriesDepartments[categoryId])
        return
      }

      CoreApi.get(`/categories/${categoryId}/departments`)
          .then(({data}) => {
            console.log({data})
            commit(CATEGORY_FETCH_DEPARTMENTS_SUCCESS, {categoryId, departments: data.data})
            resolve(data.data)
          })
          .catch(({response}) => {
            const error = handleError(response);
            commit(CATEGORY_FETCH_DEPARTMENTS_FAILED);
            reject(error);
          });
    })
  }
};

const mutations = {

  [CATEGORY_FETCH](state, cancelToken) {
    state.errors = [];
    state.loading = true;
    if (state.cancelToken !== null) {
      console.log('Canceling previous fetch category request')
      state.cancelToken.cancel();
    }
    state.cancelToken = cancelToken;
    // state.loaded = null;
    // console.debug(CATEGORY_FETCH, state)
  },

  [CATEGORY_FETCH_SUCCESS](state, {category}) {
    state.errors = [];
    category.is_fully_loaded = true
    state.categories = [category, ...state.categories];
    state.loaded = category;
    state.loading = false;
    state.cancelToken = null;
    // console.debug(CATEGORY_FETCH_SUCCESS, state, {category})
  },

  [CATEGORY_FETCH_FAILED](state, {errors}) {
    state.errors = [...errors];
    state.loading = false;
    state.loaded = null;
    state.cancelToken = null;
    // console.debug(CATEGORY_FETCH_FAILED)
  },

  [CATEGORY_FETCH_VIEW](state) {
    state.errors = [];
    state.tasks = [];
    state.view = null;
    state.loadingView = true;
    state.pagination = null;
  },

  [CATEGORY_SILENT_FETCH_PRESENTATION](state, {presentationId}) {
    console.log("Fetching presentation from server", {presentationId});
  },

  [CATEGORY_SILENT_FETCH_PRESENTATION_SUCCESS](state, {view}) {
    console.log('Got presentation data:', view)
  },

  [CATEGORY_SILENT_FETCH_PRESENTATION_FAILED](state, {error}) {
    console.error('Failed fetching presentation:', error)
  },

  [CATEGORY_SILENT_FETCH_VIEW](state, cancelToken) {
    if (state.viewCancelToken !== null) {
      // console.log('Canceling previous fetch view request')
      state.viewCancelToken.cancel();
    }
    // console.log('Saving new cancel token', cancelToken)
    state.viewCancelToken = cancelToken;
    state.loadingView = true
  },

  [CATEGORY_FETCH_VIEW_SUCCESS](state, {data, view, meta}) {
    state.errors = [];
    state.tasks = data;// [...data, ...state.tasks];
    view.filters = meta.filter;
    state.views[view.name] = view;

    if (!_.has(state.viewsByCategories, view.category_id)) {
      state.viewsByCategories[view.category_id] = {}
    }
    state.viewsByCategories[view.category_id][view.name] = view;

    state.view = view;
    state.pagination = {
      currentPage: meta.current_page,
      pageSize: meta.per_page,
      lastPage: meta.last_page,
      acceptMax: meta.accept_max,
      total: meta.total,
      count: meta.count,
    };
    state.loadingView = false;
    state.viewCancelToken = null;
    // console.debug(CATEGORY_FETCH_VIEW_SUCCESS, state, {data, view, meta, links})
  },

  [CATEGORY_FETCH_VIEW_FAILED](state, error) {
    state.errors.push(error);
    state.loadingView = false;
    state.viewCancelToken = null;
    // console.debug(CATEGORY_FETCH_VIEW_FAILED)
  },

  [CATEGORY_FETCH_SUBTASK_RULE]() {
    // console.debug(CATEGORY_FETCH_SUBTASK_RULE);
  },

  [CATEGORY_FETCH_SUBTASK_RULE_SUCCESS]() {
    // console.debug(CATEGORY_FETCH_SUBTASK_RULE_SUCCESS, {subtaskRule});
  },

  [CATEGORY_FETCH_SUBTASK_RULE_FAILED]() {
    // console.debug(CATEGORY_FETCH_SUBTASK_RULE_FAILED, error)
  },

  [CATEGORY_FETCH_DEPARTMENTS](state) {
    state.loading = true;
    // console.debug(CATEGORY_FETCH_DEPARTMENTS, state)
  },

  [CATEGORY_FETCH_DEPARTMENTS_SUCCESS](state, {categoryId, departments}) {
    console.log({categoryId, departments})
    state.loading = false;
    const newCollection = Object.assign({}, state.categoriesDepartments);
    newCollection[categoryId] = [...departments];
    state.categoriesDepartments = Object.assign({}, newCollection);
    // console.debug(CATEGORY_FETCH_DEPARTMENTS_SUCCESS, state, {category})
  },

  [CATEGORY_FETCH_DEPARTMENTS_FAILED](state) {
    state.loading = false;
    // console.debug(CATEGORY_FETCH_DEPARTMENTS_FAILED)
  },

  [CATEGORIES_FETCH_SETS](state) {
    state.errors = [];
    state.sets = [];
    state.isLoaded = false;
    state.loading = true;
    // console.debug(CATEGORIES_FETCH_SETS, state)
  },

  [CATEGORIES_FETCH_SETS_SUCCESS](state, {collection}) {
    state.errors = [];
    state.sets = [...collection];
    for (const set of collection) {
      state.categories = [...set.categories, ...state.categories];
    }
    state.isLoaded = true;
    state.loading = false;
    // console.debug(CATEGORIES_FETCH_SETS_SUCCESS, state, {collection})
  },

  [CATEGORIES_FETCH_SETS_FAILED](state, error) {
    state.errors = [error];
    state.categories = [];
    state.isLoaded = false;
    state.loading = false;
    // console.debug(CATEGORIES_FETCH_SETS_FAILED)
  },

  [CATEGORIES_FETCH](state) {
    state.errors = [];
    state.categories = [];
    state.isLoaded = false;
    state.loading = true;
    // console.debug(CATEGORIES_FETCH, state)
  },

  [CATEGORIES_FETCH_SUCCESS](state, {collection}) {
    state.errors = [];
    state.categories = [...collection];
    state.isLoaded = true;
    state.loading = false;
    // console.debug(CATEGORIES_FETCH_SUCCESS, state, {collection})
  },

  [CATEGORIES_FETCH_FAILED](state, error) {
    state.errors = [error];
    state.categories = [];
    state.isLoaded = false;
    state.loading = false;
    // console.debug(CATEGORIES_FETCH_FAILED)
  },

  [CATEGORIES_UPDATE_TASK_IN_COLLECTION](state, task) {
    const upsert = (arr, key, newval) => {
      const match = _.find(arr, key);
      if (match) {
        let index = _.indexOf(arr, match);
        arr.splice(index, 1, newval);
      } else {
        arr.push(newval);
      }
      return arr;
    };
    state.tasks = upsert(state.tasks, {id: task.id}, task);
  },

  [CATEGORY_FETCH_COLUMN_VALUES_SUCCESS](state, {values, column, categoryId}) {
    if (!_.has(state.columnsFiltersValues, categoryId)) {
      state.columnsFiltersValues[categoryId] = {}
    }
    state.columnsFiltersValues[categoryId][column] = values;
  },

  [CATEGORY_FETCH_STATISTICS](state) {
    state.loading = true;
  },

  [CATEGORY_FETCH_STATISTICS_SUCCESS](state, {categoryId, statistics}) {
    state.loading = false;
    state.statistics[categoryId] = Object.assign({}, statistics);
  },

  [CATEGORY_FETCH_STATISTICS_FAILED](state) {
    state.loading = false;
  },

  [CATEGORY_FETCH_STATES_STATISTICS](state) {
    state.loading = true;
  },

  [CATEGORY_FETCH_STATES_STATISTICS_SUCCESS](state, {categoryId, statistics}) {
    state.loading = false;
    state.statesStatistics[categoryId] = [...statistics];
  },

  [CATEGORY_FETCH_STATES_STATISTICS_FAILED](state) {
    state.loading = false;
  },

  [CATEGORY_RESET_CREATORS](state) {
    state.creators = [];
    state.creatorsIsLoaded = false;
    state.loading = false;
    state.allCreatorsFetched = false;
    state.hasMoreCreators = true;
  },

  [CATEGORY_FETCH_CREATORS](state) {
    state.creators = [];
    state.creatorsIsLoaded = false;
    state.loading = true;
    state.allCreatorsFetched = null;
    state.hasMoreCreators = true;
  },

  [CATEGORY_FETCH_CREATORS_SUCCESS](state, {query, data, meta}) {
    state.loading = false;
    state.creatorsIsLoaded = true;
    let collection = [...data];
    // If records count matches with total count on empty query
    if (_.has(meta, 'total_count') && _.has(meta, 'count') && meta.total_count <= meta.count) {
      if (query === '' || query === null) {
        state.allCreatorsFetched = true;
      }
      state.hasMoreCreators = false;
    }

    if ((query === '' || query === null) && !state.allCreatorsFetched) {
      collection = [];
    }

    state.creators = [...collection];
  },

  [CATEGORY_RESET_PERFORMERS](state) {
    state.performers = [];
    state.performersIsLoaded = false;
    state.loading = false;
    state.allPerformersFetched = false;
    state.hasMorePerformers = true;
  },

  [CATEGORY_FETCH_PERFORMERS](state) {
    state.performers = [];
    state.performersIsLoaded = false;
    state.loading = true;
    state.allPerformersFetched = null;
    state.hasMorePerformers = true;
  },

  [CATEGORY_FETCH_PERFORMERS_SUCCESS](state, {query, data, meta}) {
    state.loading = false;
    state.performersIsLoaded = true;
    let collection = [...data];
    // If records count matches with total count on empty query
    if (_.has(meta, 'total_count') && _.has(meta, 'count') && meta.total_count <= meta.count) {
      if (query === '' || query === null) {
        state.allPerformersFetched = true;
      }
      state.hasMorePerformers = false;
    }

    if ((query === '' || query === null) && !state.allPerformersFetched) {
      collection = [];
    }

    state.performers = [...collection];
  },

  [CATEGORY_FETCH_PERFORMERS_SUCCESS](state, {data}) {
    state.performers = data;
  },

  [CATEGORY_FETCH_PERFORMERS_FAILED]() {
  },

  [CATEGORY_RESET]() {
    state.loaded = null
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
