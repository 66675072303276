import {CoreApi, handleError} from '../../lib/HttpApi';
import _ from 'lodash';

const SEARCH_RESET = 'SEARCH_RESET';
const SEARCH_SET_QUERY = 'SEARCH_SET_QUERY';
const SEARCH_FETCH = 'SEARCH_FETCH';
const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
const SEARCH_FAILED = 'SEARCH_FAILED';
const SEARCH_PUSH_RESULTS = 'SEARCH_PUSH_RESULTS';
const SEARCH_SET_PAGINATION = 'SEARCH_SET_PAGINATION';
const SEARCH_SET_ERROR = 'SEARCH_SET_ERROR';
const SEARCH_CHECK_TASK_FETCH = 'SEARCH_CHECK_TASK_FETCH';
const SEARCH_CHECK_TASK_SUCCESS = 'SEARCH_CHECK_TASK_SUCCESS';
const SEARCH_CHECK_TASK_FAILED = 'SEARCH_CHECK_TASK_FAILED';
const SEARCH_SET_REDIRECT = 'SEARCH_SET_REDIRECT';
const SEARCH_SET_CURRENT_PAGE = 'SEARCH_SET_CURRENT_PAGE';

const state = {
  loading: null,
  results: [],
  resultsScoped: false,
  error: null,
  checkingTask: null,
  query: null,
  pagination: {
    loaded: false,
    perPage: 10,
    currentPage: 1,
    lastPage: null,
    count: null,
    total: null,
  },
};

const getters = {
  loading: state => state.loading,
  count: state => state.results && typeof state.results === 'object' ?
      state.results.length :
      0,
  query: state => state.query,
  results: state => state.results,
  isResultsScoped: state => state.resultsScoped,
  pagination: state => state.pagination,
  currentPage: state => state.pagination ? state.pagination.current_page : 1,
  fullyLoaded: state => state.pagination ? state.pagination.loaded : true,
  redirectPath: state => state.redirectPath,
  error: state => state.error,
};

const actions = {
  reset: ({commit}) => {
    commit(SEARCH_RESET);
  },

  executeSearch({commit}, params) {
    const query = _.has(params, 'query') ? params.query : '';
    const page = _.has(params, 'page') ? params.page : 1;
    const categoryId = _.has(params, 'categoryId') ? params.categoryId : null;
    commit(SEARCH_FETCH);
    let url = `/search?query=${query}&page=${page}`;
    let resultsScoped = undefined !== categoryId && null !== categoryId
    if (resultsScoped) {
      url += `&category_id=${categoryId}`
    }
    CoreApi.get(url).then(response => {
      const results = response.data.data;
      const pagination = response.data.meta;
      commit(SEARCH_SUCCESS);
      commit(SEARCH_PUSH_RESULTS, {results, resultsScoped});
      commit(SEARCH_SET_PAGINATION, pagination);
    }).catch(response => {
      const error = handleError(response);
      commit(SEARCH_FAILED, error);
    });
  },

  fetchMore({dispatch, state}) {
    const pagination = state.pagination;
    let page;
    if (pagination && pagination.currentPage < pagination.lastPage) {
      page = pagination.currentPage;
    } else {
      page = pagination.lastPage;
    }
    dispatch('executeSearch', {
      query: state.query,
      page: ++page,
    });
  },

  checkTask({commit}, taskId) {
    commit(SEARCH_CHECK_TASK_FETCH, taskId);

    return new Promise((resolve, reject) => {
      const url = `/search/check/${taskId}`;
      CoreApi.get(url).then(response => {
        const data = response.data.data;
        const taskId = data.task_id;
        const categoryId = data.category_id;
        commit(SEARCH_CHECK_TASK_SUCCESS);
        commit(SEARCH_SET_REDIRECT, {taskId, categoryId});
        resolve(data);
      }).catch(response => {
        const error = handleError(response);
        console.error(error);
        commit(SEARCH_CHECK_TASK_FAILED);
        commit(SEARCH_SET_ERROR, error);
        reject(error);
      });
    });

  },
};

const mutations = {
  [SEARCH_FETCH](state) {
    state.loading = true;
    console.log(`Searching for '${state.query}' on server...`);
  },

  [SEARCH_SUCCESS](state) {
    state.loading = false;
    console.log(`Searching on server went well!`);
  },

  [SEARCH_FAILED](state, error) {
    state.loading = false;
    console.error(`Searching failed with message: ${error}`);
  },

  [SEARCH_CHECK_TASK_FETCH](state, taskId) {
    state.loading = true;
    console.log(`Checking task #'${taskId}' on server...`);
  },

  [SEARCH_CHECK_TASK_SUCCESS](state) {
    state.loading = false;
    console.log(`Checking for task on server went well!`);
  },

  [SEARCH_CHECK_TASK_FAILED](state, error) {
    state.loading = false;
    console.error(`Checking for task failed with message: ${error}`);
  },

  [SEARCH_PUSH_RESULTS](state, {results, resultsScoped}) {
    state.results = [...state.results, ...results];
    state.resultsScoped = resultsScoped
    console.log(`Appending search results:`, results);
  },

  [SEARCH_SET_PAGINATION](state, pagination) {
    state.pagination = {
      count: pagination.count,
      currentPage: pagination.current_page,
      perPage: pagination.per_page,
      total: pagination.total,
      lastPage: pagination.total_pages,
      loaded: pagination.total_pages <= pagination.current_page,
    };
    console.log(`Setting search pagination:`, pagination);
  },

  [SEARCH_SET_CURRENT_PAGE](state, page) {
    state.pagination.currentPage = page;
  },

  [SEARCH_SET_QUERY](state, query) {
    state.query = query;
    state.results = [];
  },

  [SEARCH_SET_ERROR](state, error) {
    state.error = error;
    setTimeout(() => {
      state.error = null;
    }, 6000);
  },

  [SEARCH_SET_REDIRECT](state, {taskId, categoryId}) {
    state.redirectPath = `/category/${categoryId}/task/${taskId}`;
  },

  [SEARCH_RESET](state) {
    state.loading = null;
    state.results = [];
    state.query = null;
    state.error = null;
    state.checkingTask = null;
    state.count = 0;
    state.pagination = {
      loaded: false,
      perPage: 10,
      currentPage: 1,
      lastPage: null,
      count: null,
      total: null,
    };
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
