<template>
  <div class="UserSelect">
    <select v-if="true === allRecordsFetched"
            v-model="selectedId"
            :multiple="multiple"
            @change="select($event.target.value)"
            :class="{'custom-select': true}"
            size="1"
            :placeholder="placeholder"
            :name="name"
            :required="required"
            :readonly="readonly"
            :tabindex="tabindex"
            v-bind="$attrs"
    >
      <option v-for="user of sortedResults" :value="user.id" :label="user.alias" :key="user.id"
              :selected="predefinedValue && predefinedValue.id === user.id">
        {{ user.alias }}
      </option>
    </select>

    <div class="autocomplete" v-else>
      <div class="input">
        <input v-model="query" :placeholder="placeholder"
               autocomplete="off"
               @keyup="search"
               :class="{'custom-select': true}"
               :tabindex="tabindex"
               :name="name"
               :disabled="readonly"
               :required="required"
               v-bind="$attrs"
        />
      </div>

      <div
          v-if="showDropdown && results && results.length"
          v-click-outside="reset"
          class="dropdown-menu show"
          style="margin-top: -1px;"
      >
        <a
            class="dropdown-item"
            v-for="user in results"
            :key="user.id"
            @click="pick(user)">
          {{ user.alias }}
        </a>
        <a class="dropdown-item disabled" v-if="hasMore">...</a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from "axios";

export default {
  name: "UserSelect",
  props: {
    value: null,
    placeholder: String,
    required: Boolean,
    readonly: Boolean,
    type: String,
    name: String,
    categoryId: Number,
    property: String,
    tabindex: [String, Number],
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    lockPredefinedValue: false,
    query: '',
    selectedId: null,
    selected: null,
    showDropdown: false,
    results: [],
    isLoading: false,
    hasMore: null,
    allRecordsFetched: null,
    cancelToken: null,
  }),
  computed: {
    sortedResults: state => state.results.sort((a, b) => {
      if (a.alias === b.alias) {
        return 0;
      }
      return a.alias > b.alias ? 1 : -1;
    }),
    predefinedValue: state => state.$props.value,
  },
  mounted() {
    this.requestAll();
    if (this.predefinedValue !== undefined && this.predefinedValue !== null && this.predefinedValue !== {}) {
      this.selected = this.predefinedValue;
      this.selectedId = this.predefinedValue.id;
      this.query = this.predefinedValue.alias;
    } else {
      this.selected = null;
      this.selectedId = null;
      this.value = null;
      this.query = '';
    }
  },
  watch: {
    predefinedValue(newValue) {
      if (this.lockPredefinedValue) {
        return;
      }
      if (newValue !== undefined && newValue !== null && newValue !== {}) {
        this.selected = newValue;
        this.selectedId = newValue.id;
        this.query = newValue.alias;
      } else {
        this.selected = null;
        this.selectedId = null;
        this.value = null;
        this.query = '';
      }
      this.lockPredefinedValue = true;
    },
  },
  methods: {
    requestAll() {
      const url = this.getMethodUrl('');
      this.$api.get(url)
          .then((response) => {
            const {data, meta} = response.data;
            if (_.has(meta, 'total_count') && _.has(meta, 'count')
                && meta.total_count <= meta.count) {
              this.allRecordsFetched = true;
              for (const item of data) {
                if (!item.is_deleted) {
                  this.results.push(item);
                }
              }
            }
          })
          .catch(({response}) => {
            console.error(response);
          });
    },

    reset() {
      this.query = '';
      this.selectedId = null;
      this.selected = null;
      this.showDropdown = false;
      this.results = [];
      this.isLoading = false;
      this.hasMore = null;
      this.$emit('input', null);
    },

    emitResult() {
      const returnValue = this.property ? this.selected[this.property] : this.selected;
      this.$emit('input', returnValue);
    },

    select(userId) {
      userId = parseInt(userId);
      this.selected = this.results.find(user => user.id === userId);
      this.emitResult();
    },

    pick(user) {
      this.selected = user;
      this.selectedId = user.id;
      this.emitResult();
      this.query = this.selected.name;
      this.showDropdown = false;
      this.results = [];
      this.isLoading = false;
      this.hasMore = null;
    },

    getMethodUrl(query) {
      const categoryId = this.categoryId;
      query = query ? query : '';
      switch (this.type) {
        case 'creator':
          return `/categories/${categoryId}/creators?query=${query}`;
        case 'performer':
          return `/categories/${categoryId}/performers?query=${query}`;
        case 'spectators':
          return `/categories/${categoryId}/spectators?query=${query}`;
        default:
          return `/users?query=${query}`;
      }
    },

    search() {
      if (this.allRecordsFetched) {
        return;
      }

      if (this.query && this.query.length > 2) {
        this.showDropdown = true;
        this.hasMore = true;
        this.isLoading = true;
        const url = this.getMethodUrl(this.query ? this.query : '');

        if (this.cancelToken) {
          this.cancelToken.cancel()
        }
        this.cancelToken = axios.CancelToken.source();
        this.$api.get(url, {cancelToken: this.cancelToken.token})
            .then((response) => {
              const {data, meta} = response.data;

              if (_.has(meta, 'total_count') && _.has(meta, 'count')
                  && meta.total_count <= meta.count) {
                this.hasMore = false;
              }

              this.results = [...data];

              if (this.value) {
                this.selectedId = this.value;
                this.selected = this.results.find(user => user.id === this.value);
              }

              this.isLoading = false;
            })
            .catch(({response}) => {
              console.error(response);
            });
      }
    }
  }
};
</script>


<style>
.autocomplete {
  position: relative;
}

.input-group-icon {
  background: none;
  position: relative;
  float: right;
  margin: -31px 25px 0 0;
}

.dropdown-item {
  cursor: pointer;
}
</style>
