<template>
  <div class="sidebar"
       :style="sidebarStyle"
       :data-color="backgroundColor"
       :data-active-color="activeColor">

    <div class="sidebar-wrapper"
         ref="sidebarScrollArea">

<!--      <div class="connection">&nbsp;</div>-->

      <div class="logo">
        <a class="logo-link" :class="{'with-image-logo': logoPath}" href="/">
          <div class="logo-img" v-if="logoPath">
            <img :src="logoPath" alt=""/>
          </div>
          <span class="logo-text" v-else>
            <span class="mini">{{ title.charAt(0) }}</span>
            <span class="normal">{{ title }}</span>
          </span>
        </a>
      </div>

      <slot>
      </slot>

      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks"
                        :key="link.name + index"
                        :link="link">

            <sidebar-item v-for="(subLink, index) in link.children"
                          :key="subLink.name + index"
                          :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>

      </ul>
      <div class="sidebar-footer copyright">
        Версия {{version}}
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'sidebar',
    props: {
      title: {
        type: String,
        default: 'Taskflow BPM'
      },
      backgroundImage: {
        type: String,
        default: 'static/img/sidebar-5.jpg'
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      logo: {
        type: String,
        default: 'https://via.placeholder.com/150'
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      }
    },
    computed: {
      ...mapGetters('settings', {
        sidebarColor: 'sidebarColor',
        logoPath: 'logoPath'
      }),
      backgroundColor: state => state.sidebarColor || '#000000',
      version: state => state.$appVersion,
      sidebarStyle: state => ({
        backgroundColor: state.backgroundColor
      }),
    },
    methods: {
      async initScrollBarAsync() {
        await import('perfect-scrollbar/css/perfect-scrollbar.css')
        const PerfectScroll = await import('perfect-scrollbar')
        PerfectScroll.constructor(this.$refs.sidebarScrollArea)
      }
    },
    mounted() {
      this.initScrollBarAsync()
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    }
  }

</script>
<style>
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
      display: none;
    }
  }
</style>
