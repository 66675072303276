import { handleError, UserApi } from '../../lib/HttpApi';

const USER_UPDATE = 'USER_UPDATE';
const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';

const state = {
  errors: [],
  user: {},
  saving: false,
  loading: false,
};

const getters = {
  errors: state => state.errors,
  user: state => state.user,
  saving: state => state.saving,
  loading: state => state.loading,
};

const actions = {

  update({ commit }, data) {
    commit(USER_UPDATE);
    return new Promise((resolve, reject) => {
      UserApi.post('/user', data)
        .then(({ data }) => {
          console.log('User update POST succeed', data);
          commit(USER_UPDATE_SUCCESS, data.data);
          resolve(data.data);
        })
        .catch(response => {
          console.error('User update POST failed', response);
          const error = handleError(response);
          commit(USER_UPDATE_FAILED, error);
          reject(error);
        });
    });
  },

};

const mutations = {

  [USER_UPDATE](state) {
    state.loading = true;
    state.saving = true;
  },

  [USER_UPDATE_SUCCESS](state, userData) {
    state.errors = [];
    state.saving = false;
    state.loading = false;
    state.user = userData;
    console.debug('USER_UPDATE_SUCCESS');
  },

  [USER_UPDATE_FAILED](state, error) {
    state.errors.push(error);
    state.userData = {};
    state.saving = false;
    state.loading = false;
    console.debug('USER_UPDATE_FAILED');
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
