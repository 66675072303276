const ONLINE = "ONLINE";
const OFFLINE = "OFFLINE";
const CONNECTED = "CONNECTED";
const DISCONNECTED = "DISCONNECTED";
const CONNECTING = "CONNECTING";
const RECONNECTING = "RECONNECTING";
const CONNECTION_UNAVAILABLE = "CONNECTION_UNAVAILABLE";

const timeout = 1000;

const state = {
    state    : null,
    connected: null,
    network  : true
};

const getters = {
    state    : state => state.state,
    connected: state => state.connected,
    network  : state => state.network,
    online   : state => state.network === true,
    offline  : state => state.network === false
};

const actions = {

    watchNetwork: ( {commit} ) => {
        //console.debug("Watching network connection...");

        window.addEventListener("offline", () => {
            commit(OFFLINE);
        });
        window.addEventListener("online", () => {
            commit(ONLINE);
        });
    },

    connect: ( {commit} ) => {
        //console.debug("Connecting...");
        commit(CONNECTING);
        window.Echo.connector.connect();
    },

    check: ( {commit, dispatch, state} ) => {
        switch(window.Echo.connector.pusher.connection.state){
            case "connected":
                if( state.state !== CONNECTED ) {
                    //console.debug("Connection established");
                    commit(`${CONNECTED}`);
                }
                break;
            case "disconnected":
                if( state.state !== DISCONNECTED ) {
                    //console.debug("Connection terminated. Reconnecting...");
                    commit(`${DISCONNECTED}`);
                    // dispatch('connect')
                }
                break;
            case "unavailable":
                //console.debug('Connection unavailable: reconnecting...')
                // dispatch('connect')
                break;
            case "connecting":
                if( state.state !== CONNECTING ) {
                    commit(`${CONNECTING}`);
                    //console.debug("Connecting...");
                    // dispatch('connect')
                }
                break;
            case "reconnecting":
                if( state.state !== RECONNECTING ) {
                    commit(`${RECONNECTING}`);
                    //console.debug("Reonnecting...");
                    // dispatch('connect')
                }
                break;
            default:
                //console.debug(`Check connection: ${window.Echo.connector.pusher.connection.state}`);
        }
        setTimeout(() => {
            dispatch("check");
        }, timeout);
    }
};

const mutations = {

    [ONLINE]( state ){
        state.network = true;
    },

    [OFFLINE]( state ){
        state.network = false;
    },

    [CONNECTED]( state ){
        state.connected = true;
        state.state = CONNECTED;
    },

    [DISCONNECTED]( state ){
        state.connected = false;
        state.state = DISCONNECTED;
    },

    [CONNECTING]( state ){
        state.connected = false;
        state.state = CONNECTING;
    },

    [RECONNECTING]( state ){
        state.connected = false;
        state.state = RECONNECTING;
    },

    [CONNECTION_UNAVAILABLE]( state ){
        state.connected = false;
        state.state = CONNECTION_UNAVAILABLE;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
