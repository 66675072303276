<template>
  <div class="profile-page">

    <div class="card" v-if="loggedIn && userData">
      <div class="card-header">
        <h4 class="card-title">Мой профиль
          <router-link :to="{name:'EditProfileForm'}" class="btn btn-link btn-xs">Редактировать</router-link>
        </h4>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col">

            <div class="row my-2">
              <div class="col">
                <div class="form-group has-label">
                  <label>Полное имя</label>
                  <div class="value status-line">
                    {{ userData.last_name }}
                    {{ userData.first_name }}
                    {{ userData.middle_name }}
                  </div>
                </div>
              </div>

              <div class="col">
                <user-profile-prop prop="phone" label="Телефон" :user-data="userData"/>
              </div>
            </div>
            <div class="row my-2">
              <div class="col">
                <user-profile-prop prop="name" label="Отдел" :user-data="userData.department"/>
              </div>
              <div class="col">
                <user-profile-prop prop="title" label="Должность" :user-data="userData"/>
              </div>
            </div>

            <div class="row my-2">
              <div class="col">
                <user-profile-prop prop="alias" label="Отображать как" :user-data="userData"/>
              </div>
              <div class="col">
                <user-profile-prop prop="birth_date" date="LL" label="Дата рождения"
                                   :user-data="userData"/>
              </div>
            </div>

            <div class="row my-2">
              <div class="col">
                <user-profile-prop
                    label="Email"
                    prop="email"
                    :user-data="userData"
                    :format="emailLink"
                    class="mb-0"/>

                <em class="small mt-0 pt-0">
									<span v-if="userData.allow_email_notifications">
										(уведомления разрешены)
									</span>
                  <span v-else>
										(уведомления запрещены)
									</span>
                </em>
              </div>
              <div class="col" v-if="userData.telegram_user &&
                            userData.telegram_user.telegram_username">
                <user-profile-prop
                    label="Telegram"
                    prop="telegram_username"
                    :user-data="userData.telegram_user"
                    :format="telegramLink"
                    class="mb-0"/>

                <em class="small mt-0 pt-0" v-if="telegramBotEnabled">
                                    <span v-if="userData.allow_telegram_notifications === true">
                                        <span
                                            v-if="userData.telegram_user.telegram_username && userData.telegram_user.chat_id === null">
                                            Напишите что-нибудь боту
                                            <span v-html="telegramLink(telegramBotName)"/>,
                                            чтобы начать получать уведомления
                                        </span>
                                        <span v-else>
                                            (уведомления разрешены)
                                        </span>
									</span>
                  <span v-else>
										(уведомления запрещены)
									</span>
                </em>
              </div>
            </div>

          </div>
          <div class="col col-4" v-if="userData.picture">

            <div class="form-group has-label">
              <div class="value status-line">
                <img :src="userData.picture" alt="" class="img-thumbnail img-fluid">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import UserProfileProp from "./UserProfile/UserProfileProp";

export default {
  components: {
    UserProfileProp,
  },
  computed: {
    ...mapGetters("auth", ["userData", "loggedIn"]),
    telegramBotName: state => state.$telegramConfig.botName,
    telegramBotEnabled: state => '' !== state.telegramBotName && undefined !== state.telegramBotName,
  },
  methods: {
    emailLink: (email) => {
      return `<a href="mailto:${email}">${email}</a>`;
    },
    telegramLink: (username) => {
      const url = `https://t.me/${username}`;
      return `<a href="${url}" target="_blank">@${username}</a>`;
    }
  },
};
</script>
<style>

</style>
