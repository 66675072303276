<template>
  <li class="comment-item">
    <div class="comment-header">
      <span class="comment-created">{{ comment.created_at  | moment('D MMMM в HH:mm') }}</span>,
      <span class="comment-author comment-user"><User :user="comment.user"/>:</span>
    </div>
    <p class="comment-text" v-html="comment.text" v-linkified/>
  </li>
</template>

<script>
  import User from "../../../User";
  export default {
    name: 'CommentItem',
    components: {User},
    props: ['comment'],
  }
</script>

<style scoped>

</style>
