import {CoreApi} from "../../lib/HttpApi";
import _ from "lodash";

const APPROVAL_FETCH = "APPROVAL_FETCH";
const APPROVAL_FETCH_SUCCESS = "APPROVAL_FETCH_SUCCESS";
const APPROVAL_FETCH_FAILED = "APPROVAL_FETCH_FAILED";

const APPROVAL_REQUESTED = "APPROVAL_REQUESTED";
const APPROVAL_GRANTED = "APPROVAL_GRANTED";

const APPROVAL_REQUEST = "APPROVAL_REQUEST";
const APPROVAL_REQUEST_SUCCESS = "APPROVAL_REQUEST_SUCCESS";
const APPROVAL_REQUEST_FAILED = "APPROVAL_REQUEST_FAILED";

const APPROVAL_GRANT = "APPROVAL_GRANT";
const APPROVAL_GRANT_SUCCESS = "APPROVAL_GRANT_SUCCESS";
const APPROVAL_GRANT_FAILED = "APPROVAL_GRANT_FAILED";

const APPROVAL_COUNT_PENDING = "APPROVAL_COUNT_PENDING";
const APPROVAL_COUNT_PENDING_SUCCESS = "APPROVAL_COUNT_PENDING_SUCCESS";
const APPROVAL_COUNT_PENDING_FAILED = "APPROVAL_COUNT_PENDING_FAILED";

const state = {
  errors    : [],
  collection: [],
  loaded    : {},
  counters  : {
    waitingForMyApproval: 0
  },
  isLoaded  : false,
  saving    : false,
  loading   : false,
  progress  : null
};

const getters = {

  progress: state => state.progress,

  saving: state => state.saving,

  loading: state => state.loading,

  isLoaded: state => state.isLoaded,

  counters: state => state.counters,

  errors: state => state.errors,

  approvals: state => state.collection,

  collection: state => state.collection,

  approval: state => state.loaded,

  loaded: state => state.loaded,

  find: state => id => state.collection.find(task => task.id === id)

};

const mutations = {

  [APPROVAL_REQUESTED](){
    // state.approvals.push(approval)
    // state.approvals = _.uniqBy(state.approvals, 'id')
    // console.debug(APPROVAL_REQUEST_SUCCESS, state)
  },

  [APPROVAL_GRANTED](){
    // state.approvals.push(approval)
    // state.approvals = _.uniqBy(state.approvals, 'id')
    // console.debug(APPROVAL_REQUEST_SUCCESS, state)
  },

  [APPROVAL_FETCH]( state ){
    state.errors = [];
    state.loading = true;
    // console.debug(APPROVAL_FETCH, state)
  },

  [APPROVAL_FETCH_SUCCESS]( state, {collection} ){
    state.errors = [];
    state.collection = _.uniqBy([...state.collection, ...collection], "id");
    state.loading = false;
    state.progress = 100;
    // console.debug(APPROVAL_FETCH_SUCCESS, state, collection)
  },

  [APPROVAL_FETCH_FAILED]( state, {errors} ){
    state.loading = false;
    state.errors = errors;
    // console.debug(APPROVAL_FETCH_FAILED)
  },

  [APPROVAL_REQUEST]( state ){
    state.errors = [];
    state.loading = true;
    state.saving = true;
    // console.debug(APPROVAL_REQUEST, state)
  },
  [APPROVAL_REQUEST_SUCCESS]( state ){
    state.errors = [];
    state.loading = false;
    state.saving = false;
    state.progress = 100;
    // console.debug(APPROVAL_REQUEST_SUCCESS, state)
  },

  [APPROVAL_REQUEST_FAILED]( state, {errors} ){
    state.loading = false;
    state.saving = false;
    state.errors = errors;
    // console.debug(APPROVAL_REQUEST_FAILED)
  },

  [APPROVAL_GRANT]( state ){
    state.errors = [];
    state.loading = true;
    state.saving = true;
    // console.debug(APPROVAL_GRANT, state)
  },

  [APPROVAL_GRANT_SUCCESS]( state ){
    state.errors = [];
    state.loading = false;
    state.saving = false;
    state.progress = 100;
    // console.debug(APPROVAL_GRANT_SUCCESS, state)
  },

  [APPROVAL_GRANT_FAILED]( state, {errors} ){
    state.loading = false;
    state.saving = false;
    state.errors = errors;
    // console.debug(APPROVAL_GRANT_FAILED)
  },

  [APPROVAL_COUNT_PENDING]( state ){
    state.loading = true;
    // console.debug(APPROVAL_COUNT_PENDING, state)
  },

  [APPROVAL_COUNT_PENDING_SUCCESS]( state, count ){
    state.errors = [];
    state.loading = false;
    state.counters.waitingForMyApproval = count;
    // console.debug(APPROVAL_COUNT_PENDING_SUCCESS, state)
  },

  [APPROVAL_COUNT_PENDING_FAILED]( state, {errors} ){
    state.loading = false;
    state.errors = errors;
    // console.debug(APPROVAL_COUNT_PENDING_FAILED)
  }

};

const actions = {

  /**
   * Request approval
   * @param commit
   * @param data
   * @returns {Promise<any>}
   */
  request( {commit}, data ){
    commit(APPROVAL_REQUEST);
    return new Promise(( resolve, reject ) => {
      CoreApi.post(`/approval/request`, data)
             .then(( {data} ) => {
               commit(APPROVAL_REQUEST_SUCCESS);
               commit(`tasks/TASK_FETCH_SUCCESS`, {task: data}, {root: true});
               resolve(data);
             })
             .catch(( {response} ) => {
               const errors = [];
               for( const field in response.data.errors ){
                 const messages = response.data.errors[field];
                 errors.push(messages.join(""));
               }
               commit(APPROVAL_REQUEST_FAILED, {errors});
               // console.log(errors)
               reject(errors);
             });
    });
  },

  /**
   * Grant approval
   * @param commit
   * @param data
   * @returns {Promise<any>}
   */
  grant( {commit}, data ){
    // console.log(data)

    commit(APPROVAL_GRANT);
    return new Promise(( resolve, reject ) => {
      CoreApi.post(`/approval/grant`, data)
             .then(( {data} ) => {
               commit(APPROVAL_GRANT_SUCCESS);
               commit(`tasks/TASK_FETCH_SUCCESS`, {task: data}, {root: true});
               resolve(data);
             })
             .catch(( {response} ) => {
               const errors = [];
               for( const field in response.data.errors ){
                 const messages = response.data.errors[field];
                 errors.push(messages.join(""));
               }
               commit(APPROVAL_GRANT_FAILED, {errors});
               // console.log(errors)
               reject(errors);
             });
    });
  },

  /**
   * @param commit
   * @param params
   */
  fetchMyApprovals: ( {commit} ) => {
    // console.log('Fetch tasks')
    commit(APPROVAL_FETCH);
    return new Promise(( resolve, reject ) => {
      CoreApi.get(`/approval/my`)
             .then(response => {
               commit(APPROVAL_FETCH_SUCCESS, {collection: response.data.data});
               resolve(response.data.data);
             })
             .catch(( {response} ) => {
               commit(APPROVAL_FETCH_FAILED, {errors: response.data.errors});
               reject(response.data.errors);
             });
    });
  },

  /**
   * @param commit
   * @returns {Promise<any>}
   */
  countMyApprovals: ( {commit} ) => {
    // console.log('Fetch tasks')
    commit(APPROVAL_COUNT_PENDING);
    return new Promise(( resolve, reject ) => {
      CoreApi.get(`/approval/my/count?only=active`)
             .then(response => {
               commit(APPROVAL_COUNT_PENDING_SUCCESS, response.data.count);
               resolve(response.data.count);
             })
             .catch(( {response} ) => {
               commit(APPROVAL_COUNT_PENDING_FAILED, {errors: response.data.errors});
               reject(response.data.errors);
             });
    });
  }

};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
