<template>
  <el-alert v-show="show" :title="message" type="error" :closable="false"/>
</template>

<script>
  export default {
    name: "AccessDenied",
    props: {
      show: {
        type: Boolean,
        default: () => false,
      },
      message: {
        type: String,
        default: 'Доступ запрещён',
      },
    }
  }
</script>
