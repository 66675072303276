export default {
    stringify: function (bytes) {
        if (!bytes || bytes === undefined) {
            return "";
        }
        const si = 1;
        const thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + " байт";
        }
        const units = si
            ? ["кбайт", "Мбайт", "Гбайт", "Тбайт", "ПБайт", "ЭБайт", "ЗБайт", "Йбайт"]
            : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
        let u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + " " + units[u];
    }
}