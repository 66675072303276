<template>
  <div class="card-body" v-if="task">

    <ul class="task-rows">
      <li v-for="row in category.rows" :key="row.name"
          v-show="(row.type === 'field' && hasField(row.name)) || (row.type !== 'field' && ('closed_at' !== row.name || task.is_closed))"
          class="task-row" :class="'field-' + row.name">

        <div class="task-row-label" v-if="'brief' !== row.name">
          {{ row.label }}
        </div>

        <div class="task-row-column">
          <task-row-attribute
              :task="task"
              :attribute="row.name"
              :hasPermissionToPerform="hasPermissionToPerform"
              v-if="row.type === 'system_column'"
              :key="row.name"/>

          <task-row-field
              :task="task"
              :field="getFieldByName(row.name)"
              v-if="row.type === 'field'"
              :key="row.name"/>
        </div>
      </li>
      <li
          v-if="showParents"
          class="task-row">

        <div class="task-row-label">
          <span v-if="visibleParents.length === 1">Родительская задача</span>
          <span v-else>Родительские задачи</span>
        </div>

        <div class="task-row-column">
          <ul class="list-unstyled full-width">
            <li v-for="parent_relation of visibleParents" :key="parent_relation.id"
                class="task-row-attribute">
              <router-link
                  v-if="parent_relation && parent_relation.task"
                  class="related-task"
                  :to="{name: 'TaskView', params: {categoryId: parent_relation.task.category_id, taskId: parent_relation.task.id}}">
                {{ parent_relation.task.subject }}
              </router-link>

              <div class="edit-button-group">
                <button class="btn btn-sm btn-link edit-link"
                        @click="removeTaskAsSubtask(parent_relation.task, parent_relation.subtask_rule, task)">
                  Удалить связь
                </button>
              </div>
            </li>
          </ul>
        </div>

      </li>
    </ul>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TaskRowAttribute from "./TaskRowAttribute";
import TaskRowField from "./TaskRowField";

export default {
  name: "TaskBody",
  components: {
    TaskRowAttribute,
    TaskRowField
  },
  props: ["task", "hasPermissionToPerform"],
  data: () => ({
    creator_id: null,
    performer_id: null,
    changingCreator: false,
    changingPerformer: false,
    newCreatorId: null,
    newPerformerId: null,
    creator: null,
    performer: null,
  }),
  computed: {
    ...mapGetters("auth", ["userId"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("categories", [
      "category",
      "hasPermissionToChangeCreator",
      "hasPermissionToChangePerformer"
    ]),
    ...mapGetters("task", ["saving",]),

    categoryId: state => state.$route.params["categoryId"],
    permissions: state => state.category ? state.category.permissions : [],
    displayFields: state => state.category && state.category.display_fields ? state.category.display_fields : [],
    isDisplayed: state => field => state.displayFields.includes(field),
    openPerformer: state => state.category && state.category.open_performer === true
        && state.iAmPerformer && state.task.current_state.is_initial,
    iAmPerformer: state => state.task.performer_id === state.userId,
    showParents: state => undefined !== state.visibleParents && state.visibleParents.length > 0,
    visibleParents: state => {
      const parent_relations = state.task && state.task.parents_relations ? state.task.parents_relations : [];
      const visibleParents = [];
      for (const parent_relation of parent_relations) {
        if (!state.hasExactTaskRelationField(parent_relation)) {
          visibleParents.push(parent_relation);
        }
      }
      return visibleParents;
    },

    performerIsAssignable: state => state.task && state.task.performer_id !== state.userId && state.task.is_new && state.task.category.without_performer,
    systemFieldsLabels: state => state.category ? state.category.labels : {},
  },
  methods: {
    getFieldByName(name) {
      for (const field of this.task.fields) {
        if (field.name === name) {
          return field;
        }
      }

      return null;
    },

    hasField(name) {
      return null !== this.getFieldByName(name)
    },

    hasExactTaskRelationField(parentRelation) {
      const fields = this.task && this.task.fields ? this.task.fields : [];
      for (const field of fields) {
        if (field.type === 'FIELD_TYPE_TASK_RELATION' && field.subtask_rule_id === parentRelation.subtask_rule_id) {
          return true;
        }
      }
      return false;
    },
    startChangingCreator() {
      this.creator = this.task.creator;
      this.changingCreator = true;
    },

    changeCreator(creator) {
      if (this.hasPermissionToChangeCreator) {
        this.newCreatorId = creator.id;
      }
    },

    storeNewCreator() {
      if (this.hasPermissionToChangeCreator) {
        this.$store
            .dispatch("task/changeCreator", {
              creator_id: this.newCreatorId,
              task_id: this.task.id
            })
            .then(() => {
              this.changingCreator = false;
            })
            .finally(() => {
              this.newCreatorId = null;
            });
      }
    },

    startChangingPerformer() {
      this.performer = this.task.performer;
      this.changingPerformer = true;
    },

    changePerformer(performer) {
      if (this.openPerformer || this.hasPermissionToChangePerformer) {
        this.newPerformerId = performer.id;
      }
    },

    storeNewPerformer() {
      if (this.openPerformer || this.hasPermissionToChangePerformer) {
        this.$store
            .dispatch("task/changePerformer", {
              performer_id: this.newPerformerId,
              task_id: this.task.id
            })
            .then(() => {
              this.changingPerformer = false;
            })
            .finally(() => {
              this.newPerformerId = null;
            });
      }
    },

    assignToMe() {
      const self = this;
      if (self.performerIsAssignable) {
        self.$store
            .dispatch("task/assignToMe", {
              task_id: this.task.id
            })
            .then(() => {
            })
            .catch(({error, validationErrors}) => {
              if (validationErrors) {
                for (const field in validationErrors) {
                  const fieldErrorData = validationErrors[field];
                  const error = fieldErrorData.messages.join(' ');
                  const element = document.querySelector(`[name="${field}"]`);
                  element.classList.add('with-error');
                  self.$notify({
                    icon: "fa fa-times",
                    group: "TaskCreate",
                    title: error ? error : 'Ошибка',
                    type: "danger"
                  });
                }
              } else {
                self.$notify({
                  icon: "fa fa-times",
                  group: "TaskCreate",
                  title: error ? error : 'Ошибка',
                  type: "danger"
                });
              }
            });
      }
    },

    /**
     * @param {object} parentTask
     * @param {object} subtaskRule
     * @param {object} subtask
     */
    removeTaskAsSubtask(parentTask, subtaskRule, subtask) {
      if (confirm('Удалить связть с родительской задачей? (Сама задача удалена не будет)')) {
        const self = this;
        self.$store.dispatch("task/removeSubtask", {parentTask, subtaskRule, subtask})
            .then(() => {
              self.$notify({
                icon: "fa fa-check",
                group: "TaskView",
                title: "Задача исключена из списка подзадач",
                type: "success"
              });
            })
            .catch((error) => {
              self.$notify({
                group: "TaskView",
                icon: "fa fa-times",
                title: error,
                type: "danger"
              });
            });
      }
    }
  },

  watch: {
    creator_id: "changeCreator",
    performer_id: "changePerformer"
  },

  mounted() {
  }
};
</script>

<style lang="sass">
.row
  position: relative

  .key
    font-size: 80%
    overflow: hidden

    @media (min-width: 770px)
      font-size: 100%
      min-width: 150px

.floating-action-button
  display: flex
  justify-content: flex-end
  align-items: center

.assign-to-me-btn
  margin-left: 10px
  margin-top: -5px

.pull-left
  float: left

.inner-edit
  margin: -11px 0 0 -13px
  padding-right: 12px

.full-width
  width: 100%
</style>
