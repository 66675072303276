<template>
  <div v-if="hasPermission && isVisible">
    <label :class="classNames" v-if="field.type !== 'FIELD_TYPE_CHECKBOX'">
      {{ field.title }}
    </label>
    <field-item-edit :field="field"
                     :tabindex="tabindex"
                     @change="setValue"
                     :predefined-value="predefinedValue"
                     :required="isRequired"
                     :disabled="readOnly || !hasPermission"/>
  </div>
</template>

<script>
  import _ from "lodash";
  import {mapGetters} from "vuex";
  import FieldItemEdit from "../../TaskView/FieldList/FieldItem/FieldItemEdit";

  export default {
    name: "FieldItemNew",
    components: {
      FieldItemEdit
    },
    props: ['field', 'readOnly', 'predefinedValue', 'tabindex'],
    data: () => ({
      editMode: false,
      value: ""
    }),
    methods: {
      setValue({value}) {
        this.value = value;
        this.emit(value);
      },
      emit(value) {
        const args = {
          id: this.field.id,
          value: value
        };
        console.log('Emitting change', args);
        this.$emit("change", args);
      },
    },
    computed: {
      ...mapGetters("categories", {
        category: "loaded"
      }),

      isRequired: state => state.field.required && !state.field.is_autoincrement,

      allowedInInitialState: state => _.findIndex(state.field.states, {is_initial: true}) >= 0,
      initialState: state => state.category.initial_state,
      isVisible: state => _.findIndex(state.field.visible_states, {id: state.initialState.id}) >= 0,
      classNames: state => `${state.field.type} ${state.isRequired ? "required" : ""}`,

      hasPermission() {
        const access = this.field.access;
        return (access === "FIELD_ACCESS_CREATOR" || access === "FIELD_ACCESS_VIEWER") && (this.allowedInInitialState);
      }
    },
    watch: {
      value(newValue) {
        this.emit(newValue);
      }
    },
    mounted() {
      this.value = this.predefinedValue;
    }
  };
</script>
