import _ from 'lodash'
import actions from './actions.js'
import mutations from './mutations.js'

const state = {
    subscriptions: [],
    errors: [],
    transitionErrors: [],
    tasks: {},
    task: {},
    isLoaded: false,
    loading: false,
    saving: false,
    progress: null,
    preloadedTask: null,
    notFound: false,
};

const getters = {
    progress: state => state.progress,
    saving: state => state.saving,
    loading: state => state.loading,
    isLoaded: state => state.isLoaded,
    errors: state => state.errors,
    transitionErrors: state => state.transitionErrors,
    tasks: state => Object.values(state.tasks),
    task: state => state.task,
    comments: state => state.task ? _.orderBy(
        _.uniqBy(state.task.comments, 'id'),
        ['id'], ['asc']) : [],
    attachments: state => state.task ? _.orderBy(state.task.attachments,  ['id'], ['asc']) : [],
    preloadedTask: state => state.preloadedTask,
    notFound: state => state.notFound,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
