import {CoreApi, getValidationErrors, handleError} from "../../../lib/HttpApi";
import types from "./types.js";
import _ from 'lodash';

export default {

  /**
   * Subscribe for task updates
   *
   * Здесь мы подписываемся на все события по каждой из
   * загруженных задач.
   *
   * При закрытии/изменении задачи, смене статуса,
   * а также при смене заказчика/исполнителя
   * вызываются методы получения текущих задач пользователя
   * и соответствующего счетчика.
   *
   * При обновлении полей в загруженной задаче обновляется
   * измененное поле.
   *
   * При добавлении комментария/файла в коллекцию комментариев/файлов
   * загруженной задачи добавляется новый элемент.
   *
   * @param commit
   * @param dispatch
   * @param state
   * @param taskId
   */
  subscribe: ({commit, dispatch, state}, taskId) => {
    // Skip if already subscribed
    if (taskId && state.subscriptions.indexOf(taskId) < 0) {
      // console.log("Subscribing", {
      //     taskId,
      //     state: state.subscriptions.indexOf(taskId)
      // });

      const channel = `task-${taskId}-updates`;
      console.debug(`Event: [${channel}] subscribing...`);
      const subscription = window.Echo.channel(channel);
      commit(types.TASK_SUBSCRIBED_FOR_UPDATES, taskId);

      const refetch = (task) => {
        dispatch("tasks/fetchTasksInProgress", null, {root: true});
        dispatch("tasks/countTasksInProgress", null, {root: true});
        dispatch("silentFetch", task.id);
      };

      // Task close
      subscription.listen(".TaskClosedEvent", ({task}) => {
        console.debug(`Event: [${channel}] TaskClosedEvent`, {task});
        commit(types.TASK_CLOSED, {task});
        commit("tasks/TASKS_REMOVE_FROM_COLLECTION", task.id, {root: true});
        refetch(task);
      });

      // Task create
      subscription.listen(".TaskCreatedEvent", ({task}) => {
        console.debug(`Event: [${channel}] TaskCreatedEvent`, {task});
        // commit(types.TASK_CREATED, {task});
        dispatch("tasks/fetchTasksInProgress", null, {root: true});
        dispatch("tasks/countTasksInProgress", null, {root: true});
      });

      // Task update
      subscription.listen(".TaskUpdatedEvent", ({task}) => {
        console.debug(`Event: [${channel}] TaskUpdatedEvent`, {task});
        refetch(task);
      });

      // Creator change
      subscription.listen(".CreatorChangedEvent", ({task}) => {
        console.debug(`Event: [${channel}] CreatorChangedEvent`, {task});
        commit(types.TASK_CREATOR_CHANGED, {task});
        refetch(task);
      });

      // Performer change
      subscription.listen(".PerformerChangedEvent", ({task}) => {
        console.debug(`Event: [${channel}] PerformerChangedEvent`, {task});
        commit(types.TASK_PERFORMER_CHANGED, {task});
        refetch(task);
      });

      // State change
      subscription.listen(".StateChangedEvent", ({task, state, actions}) => {
        console.debug(`Event: [${channel}] StateChangedEvent`, {task, state, actions});
        commit(types.TASK_STATE_CHANGED, {
          task,
          state,
          actions
        });
        commit("tasks/TASKS_TASK_STATE_CHANGED", {
          task,
          state,
          actions
        }, {root: true});
        refetch(task);
      });

      // Field update
      subscription.listen(".FieldUpdatedEvent", ({task, field, value}) => {
        console.debug(`Event: [${channel}] FieldUpdatedEvent`, {task, field, value});
        commit(types.TASK_FIELD_UPDATED, {
          task,
          value
        });
        refetch(task);
      });

      // Comment added
      subscription.listen(".CommentAddedEvent", ({task, comment}) => {
        console.debug(`Event: [${channel}] CommentAddedEvent`, {task, comment});
        commit(types.TASK_COMMENT_ADDED, {
          task,
          comment
        });
      });

      // Attachment uploaded
      subscription.listen(".AttachmentUploadedEvent", ({task, attachment}) => {
        console.debug(`Event: [${channel}] AttachmentUploadedEvent`, {task, attachment});
        commit(types.TASK_ATTACHMENT_UPLOADED, {
          task,
          attachment
        });
      });
    } else {
      // console.log(`Skipping task #${taskId} as it's already subscribed`);
    }
  },

  /**
   * Subscribe for a bunch of tasks
   */
  batchSubscribe: ({dispatch}, tasks) => {
    // console.log(`Batch subscribe for ${tasks.length} tasks`);
    for (const task of tasks) {
      if (!task.is_closed) {
        dispatch("subscribe", task.id);
      }
    }
  },

  getTask: (context, taskId) => {
    return new Promise((resolve, reject) => {
      CoreApi.get(`/tasks/${taskId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch((response) => {
            reject(response)
          });
    });
  },

  /**
   * Fetch one task
   * @param commit
   * @param taskId
   * @returns {Promise<any>}
   */
  fetch: ({commit}, taskId) => {
    // console.log('Find task')
    commit(types.TASK_FETCH);
    return new Promise((resolve, reject) => {
      CoreApi.get(`/tasks/${taskId}`)
        .then(response => {
          commit(types.TASK_FETCH_SUCCESS, {task: response.data});
          commit('tasks/TASKS_PUSH', response.data, {root: true});
          resolve(response.data);
        })
        .catch((response) => {
          if (response.response.status === 404) {
            commit(types.TASK_FETCH_NOT_FOUND)
          } else {
            let validationErrors = getValidationErrors(response)
            commit(types.TASK_FETCH_FAILED, validationErrors)
          }
          reject(response)
        });
    });
  },

  /**
   * Fetch one task
   * @param commit
   * @param taskId
   * @returns {Promise<any>}
   */
  silentFetch: ({commit}, taskId) => {
    return new Promise((resolve, reject) => {
      CoreApi.get(`/tasks/${taskId}`)
        .then(response => {
          commit(types.TASK_FETCH_SUCCESS, {task: response.data});
          commit('tasks/TASKS_PUSH', response.data, {root: true});
          resolve(response.data);
        })
        .catch((response) => {
          reject(response)
        });
    });
  },

  fetchSubtasks: ({commit}, {ruleId, taskId}) => {
    let url = `/tasks/${taskId}/subtask`
    if (ruleId) {
      url += `/${ruleId}`
    }
    commit(types.TASK_FETCH_SUBTASKS);
    return new Promise((resolve, reject) => {
      CoreApi.get(url)
          .then(({data}) => {
            commit(types.TASK_FETCH_SUBTASKS_SUCCESS, data)
            resolve(data)
          })
          .catch(response => {
            commit(types.TASK_FETCH_SUBTASKS_FAILED, response)
            reject(response)
          })
    })
  },

  preloadTask({commit}, task) {
    commit(types.TASK_PRELOAD, task)
  },

  /**
   * Save task
   */
  save({commit}, data) {
    commit(types.TASK_CREATE);
    return new Promise((resolve, reject) => {
      CoreApi.post("/tasks", data)
        .then(({data}) => {
          commit(types.TASK_CREATE_SUCCESS, {task: data});
          resolve(data);
        })
        .catch(({response}) => {
          let error;
          let validationErrors = getValidationErrors(response);
          if (!validationErrors) {
            error = handleError(response);
          }

          commit(types.TASK_CREATE_FAILED, error);
          // console.log(error, validationErrors);
          reject({error, validationErrors});
        });
    });
  },

  /**
   * Delete task
   * @param commit
   * @param taskId
   */
  delete({commit}, taskId) {
    commit(types.TASK_DELETE);
    return new Promise((resolve, reject) => {
      CoreApi.delete(`/tasks/${taskId}`)
        .then(() => {
          commit(types.TASK_DELETE_SUCCESS);
          resolve();
        })
        .catch(({response}) => {
          let error;
          let validationErrors = getValidationErrors(response);
          if (validationErrors) {
            error = 'Ошибка';
          } else {
            error = handleError(response);
          }

          commit(types.TASK_DELETE_FAILED, error);
          // console.log(error, validationErrors);
          reject({error, validationErrors});
        });
    });
  },

  /**
   * Save task comment
   */
  comment({commit}, data) {
    commit(types.TASK_CREATE_COMMENT);
    return new Promise((resolve, reject) => {
      CoreApi
        .post("/comment", data)
        .then(({data}) => {
          const comment = data.data;
          const task = comment.task;
          commit(types.TASK_CREATE_COMMENT_SUCCESS, {comment, task});
          resolve(data);
        })
        .catch(({response}) => {
          const errors = [];
          for (const field in response.data.messages) {
            if (_.has(response.data.messages, field)) {
              const messages = response.data.messages[field];
              errors.push(messages.join(""));
            }
          }
          commit(types.TASK_CREATE_COMMENT_FAILED, {errors});
          // console.log(errors)
          reject(errors);
        });
    });
  },

  /**
   * Attach a file
   */
  attach({commit}, data) {
    commit(types.TASK_CREATE_ATTACHMENT);
    return new Promise((resolve, reject) => {
      const config = {
        onUploadProgress(progressEvent) {
          commit(types.TASK_CREATE_ATTACHMENT_PROGRESS,
            {progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)});
        }
      };

      CoreApi.post("/attachment", data, config)
        .then(({data}) => {
          const attachment = data.data;
          commit(types.TASK_CREATE_ATTACHMENT_SUCCESS, attachment);
          resolve(attachment);
        })
        .catch(({response}) => {
          const messages = response.data.data.messages || [];
          const errors = [];
          for (const field in messages) {
            if (_.has(messages, field)) {
              const message = messages[field];
              errors.push(message.join(""));
            }
          }
          commit(types.TASK_CREATE_ATTACHMENT_FAILED, {errors});
          reject(errors);
        });
    });
  },

  /**
   * Delete attach
   */
  deleteAttachment({commit}, id) {
    commit(types.TASK_DELETE_ATTACHMENT);
    return new Promise((resolve, reject) => {
      CoreApi.delete(`/attachment/${id}`)
        .then(({data}) => {
          commit(types.TASK_DELETE_ATTACHMENT_SUCCESS, {removedId: id});
          resolve(data);
        })
        .catch(({response}) => {
          const errors = [];
          for (const field in response.data.messages) {
            if (_.has(response.data.messages, field)) {
              const messages = response.data.messages[field];
              errors.push(messages.join(""));
            }
          }
          commit(types.TASK_DELETE_ATTACHMENT_FAILED, {errors});
          // console.log(errors)
          reject(errors);
        });
    });
  },

  /**
   * Update additional field data
   * @param commit
   * @param dispatch
   * @param data
   */
  updateField({commit}, data) {
    commit(types.TASK_FIELD_UPDATE);
    return new Promise((resolve, reject) => {
      CoreApi.post("/field", data)
        .then(({data}) => {
          commit(types.TASK_FIELD_UPDATE_SUCCESS, {fieldValue: data});
          resolve(data);
          // dispatch('fetch', data.task_id)
        })
        .catch((response) => {
          let error;
          let validationErrors = getValidationErrors(response);
          if (validationErrors) {
            error = 'Форма заполнена с ошибкой';
          } else {
            error = handleError(response);
          }
          commit(types.TASK_FIELD_UPDATE_FAILED, error);
          // console.log(errors)
          reject({error, validationErrors});
        });
    });
  },

  /**
   * Change task state
   */
  transit({commit}, data) {
    commit(types.TASK_TRANSIT);
    return new Promise((resolve, reject) => {
      CoreApi.post("/tasks/transit", data)
        .then(({data}) => {
          commit(types.TASK_TRANSIT_SUCCESS, {task: data});
          resolve(data);
        })
        .catch(({response}) => {
          let error;
          let validationErrors = getValidationErrors(response);
          if (validationErrors) {
            error = 'Форма заполнена с ошибкой';
          } else {
            error = handleError(response);
          }
          commit(types.TASK_TRANSIT_FAILED, error);
          reject({error, validationErrors});
          console.error({error, validationErrors});
        });
    });
  },

  /**
   * Change task creator
   */
  changeCreator({commit, dispatch}, {task_id, creator_id}) {
    commit(types.TASK_CHANGE_CREATOR);
    return new Promise((resolve) => {
      CoreApi.post(`/tasks/${task_id}/creator`, {creator_id})
        .then(({data}) => {
          commit(types.TASK_CHANGE_CREATOR_SUCCESS, {task: data});
          resolve(data);
          dispatch('silentFetch', task_id);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(types.TASK_CHANGE_CREATOR_FAILED, error);
          console.error(error);
        });
    });
  },

  /**
   * Change task performer
   */
  changePerformer({commit, dispatch}, {task_id, performer_id}) {
    commit(types.TASK_CHANGE_PERFORMER);
    return new Promise((resolve) => {
      CoreApi.post(`/tasks/${task_id}/performer`, {performer_id})
        .then(({data}) => {
          commit(types.TASK_CHANGE_PERFORMER_SUCCESS, {task: data});
          resolve(data);
          dispatch('silentFetch', task_id);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(types.TASK_CHANGE_PERFORMER_FAILED, error);
          console.error(error);
        });
    });
  },

  changeSpectators({commit, dispatch}, {task_id, spectators_ids}) {
    commit(types.TASK_CHANGE_SPECTATORS);
    return new Promise((resolve) => {
      CoreApi.post(`/tasks/${task_id}/spectators`, {spectators_ids})
          .then(({data}) => {
            commit(types.TASK_CHANGE_SPECTATORS_SUCCESS, {task: data});
            resolve(data);
            dispatch('silentFetch', task_id);
          })
          .catch(({response}) => {
            const error = handleError(response);
            commit(types.TASK_CHANGE_SPECTATORS_FAILED, error);
            console.error(error);
          });
    });
  },

  /**
   * Assign task to me (authorized user)
   */
  assignToMe({commit}, {task_id}) {
    commit(types.TASK_ASSIGN_TO_ME);
    return new Promise((resolve, reject) => {
      CoreApi.post(`/tasks/${task_id}/assign`)
        .then(({data}) => {
          commit(types.TASK_ASSIGN_TO_ME_SUCCESS, {task: data});
          commit('tasks/TASKS_PUSH', data, {root: true});
          resolve(data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(types.TASK_ASSIGN_TO_ME_FAILED, error);
          console.error(error);
          reject(error);
        });
    });
  },

  assignSubtask({commit}, {parentTask, subtaskRule, subtask}) {
    commit(types.TASK_ASSIGN_SUBTASK);
    return new Promise((resolve, reject) => {
      CoreApi
        .post(`/tasks/${parentTask.id}/subtask`, {
          subtask_rule_id: subtaskRule.id,
          task_id: subtask.id,
        })
        .then(({data}) => {
          commit(types.TASK_ASSIGN_SUBTASK_SUCCESS, {task: data});
          commit('tasks/TASKS_PUSH', data, {root: true});
          resolve(data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(types.TASK_ASSIGN_SUBTASK_FAILED, error);
          console.error(error);
          reject(error);
        });
    });
  },

  removeSubtask({commit}, {parentTask, subtaskRule, subtask}) {
    commit(types.TASK_REMOVE_SUBTASK);
    return new Promise((resolve, reject) => {
      CoreApi
        .delete(`/tasks/${parentTask.id}/subtask`, {
          data: {
            subtask_rule_id: subtaskRule.id,
            task_id: subtask.id,
          }
        })
        .then(({data}) => {
          commit(types.TASK_REMOVE_SUBTASK_SUCCESS, {task: data});
          commit('tasks/TASKS_PUSH', data, {root: true});
          resolve(data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(types.TASK_REMOVE_SUBTASK_FAILED, error);
          console.error(error);
          reject(error);
        });
    });
  },

  /**
   * Update task brief
   */
  updateBrief({commit}, {task_id, text}) {
    commit(types.TASK_UPDATE_BRIEF);
    return new Promise((resolve) => {
      CoreApi
        .patch(`/tasks/${task_id}`, {
          brief: text
        })
        .then(({data}) => {
          commit(types.TASK_UPDATE_BRIEF_SUCCESS, {task: data});
          resolve(data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(types.TASK_UPDATE_BRIEF_FAILED, error);
          console.error(error);
        });
    });
  },

  /**
   * Update task subject
   */
  updateSubject({commit}, {task_id, text}) {
    commit(types.TASK_UPDATE_SUBJECT);
    return new Promise((resolve) => {
      CoreApi
        .patch(`/tasks/${task_id}`, {
          subject: text
        })
        .then(({data}) => {
          commit(types.TASK_UPDATE_SUBJECT_SUCCESS, {task: data});
          resolve(data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(types.TASK_UPDATE_SUBJECT_FAILED, error);
          console.error(error);
        });
    });
  },

  /**
   * Update task brief
   */
  updateExpiredAt({commit}, {task_id, expired_at}) {
    commit(types.TASK_UPDATE_EXPIRED_AT);
    return new Promise((resolve) => {
      CoreApi
        .patch(`/tasks/${task_id}`, {expired_at})
        .then(({data}) => {
          commit(types.TASK_UPDATE_EXPIRED_AT_SUCCESS, {task: data});
          resolve(data);
        })
        .catch(({response}) => {
          const error = handleError(response);
          commit(types.TASK_UPDATE_EXPIRED_AT_FAILED, error);
          console.error(error);
        });
    });
  }

};
