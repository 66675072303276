<template>
    <div class="dropzone" style="visibility: hidden;" v-if="taskId">
        <div class="textnode">
            Перетащите файлы для загрузки...
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        name: "Dropzone",
        created() {
            this.makeDropzone();
        },
        computed  : {
            ...mapGetters("auth", ["userId"]),
            ...mapGetters("categories", ["category"]),
            ...mapGetters("task", ["task"]),
            taskId: state => state.$route.params["taskId"],
        },
        methods: {
            dropAllowed() {
              return this.$route.name === 'TaskView' && !this.task.is_closed && !this.category.disable_attachments;
            },

            /**
             * Bind listeners - make dropzone active
             */
            makeDropzone() {
                const self = this;
                const dragenterListener = function () {

                  if (null === document.querySelector(".dropzone")) {
                    return;
                  }

                  document.querySelector(".dropzone").style.visibility = "";
                    document.querySelector(".dropzone").style.opacity = 1;
                    document.querySelector(".dropzone .textnode").style.fontSize = "48px";
                };
                window.removeEventListener('dragenter', dragenterListener);
                window.addEventListener("dragenter", dragenterListener);

                const dragleaveListener = function (e) {
                    e.preventDefault();

                    if (null === document.querySelector(".dropzone")) {
                      return;
                    }

                    document.querySelector(".dropzone").style.visibility = "hidden";
                    document.querySelector(".dropzone").style.opacity = 0;
                    document.querySelector(".dropzone .textnode").style.fontSize = "42px";

                };
                window.removeEventListener('dragleave', dragleaveListener);
                window.addEventListener("dragleave", dragleaveListener);

                const dragoverListener = function (e) {
                    e.preventDefault();
                    if (!self.dropAllowed()) {
                      return;
                    }
                    document.querySelector(".dropzone").style.visibility = "";
                    document.querySelector(".dropzone").style.opacity = 1;
                    document.querySelector(".dropzone .textnode").style.fontSize = "48px";
                };
                window.removeEventListener('dragover', dragoverListener);
                window.addEventListener("dragover", dragoverListener);

                let dropListener = function (e) {
                    e.preventDefault();
                    if (!self.dropAllowed()) {
                      return;
                    }
                    document.querySelector(".dropzone").style.visibility = "hidden";
                    document.querySelector(".dropzone").style.opacity = 0;
                    document.querySelector(".dropzone .textnode").style.fontSize = "42px";

                    const files = e.dataTransfer.files;
                    console.log("Drop files:", files);
                    self.uploadFiles(files);
                };
                window.removeEventListener('drop', dropListener);
                window.addEventListener("drop", dropListener);
            },

            /**
             * Upload method: execute POST request
             * @param files
             */
            uploadFiles(files) {
                if (!this.dropAllowed()) {
                  return;
                }
                if (this.taskId) {
                    for (const file of files) {
                        console.log("Uploading...", file);
                        let data = new FormData();
                        data.append("task_id", this.taskId);
                        data.append("user_id", this.userId);
                        data.append("attachment", file);
                        this.$store.dispatch("task/attach", data).then(() => {
                          this.$root.$emit('openAttachmentsTab');
                        }).catch();
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>