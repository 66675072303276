<template>
  <div :class="className" @dblclick="enableEditMode" @keyup.esc="disableEditMode" @keyup.ctrl.enter="hotSave">
    <edit-button-group
        v-show="showEditButtons"
        @is-editing="updateEditMode"
        :editing="editMode"
        :saving="saving"
        :on-save="edit"
        :on-edit="enableEditMode"
        :on-cancel="disableEditMode"
    />

    <field-item-value
        v-show="!editMode"
        :highlight-query="highlightQuery"
        :data="getData"
        :field="field"
        :has-permission="hasPermission"/>

    <field-item-edit
        v-show="(editMode || field.type === 'FIELD_TYPE_CHECKLIST') && !isReadOnly"
        v-if="field && field.type === 'FIELD_TYPE_CHECKLIST' || (hasPermission && isFillable)"
        :data="getData" :field="field" :value="getData" :custom-class="'inline-input'"
        :saving="saving" @change="setValue" :disabled="!(hasPermission && isFillable)"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import FieldItemValue from "./FieldList/FieldItem/FieldItemValue";
import FieldItemEdit from "./FieldList/FieldItem/FieldItemEdit";
import EditButtonGroup from "@/components/Controls/EditButtonGroup";
import _ from "lodash";

export default {
  name: "TaskRowField",
  components: {
    FieldItemEdit,
    FieldItemValue,
    EditButtonGroup
  },
  props: {
    "task": {
      type: Object,
      default: () => ({})
    },
    "field": {
      type: Object,
      default: () => ({})
    },
    "readonly": {
      type: Boolean,
      default: false
    },
    "highlightQuery": {
      type: String,
      default: ''
    },
    "className": {
      type: String,
      default: 'task-row-attribute'
    },
  },
  data: () => ({
    saving: false,
    editMode: false,
    value: null
  }),
  computed: {
    ...mapGetters("auth", ["userData"]),

    departments: state => state.users.map(user => user.department),

    showEditButtons() {
      return this.hasPermission && !this.isReadOnly && this.isFillable && this.field && this.field.type !== 'FIELD_TYPE_CHECKLIST';
    },

    hasPermission() {
      if (!this.field) {
        return false;
      }
      const {access} = this.field;
      return (access === "FIELD_ACCESS_PERFORMER" && this.task.performer_id === this.userData.id)
          || (access === "FIELD_ACCESS_CREATOR" && this.task.creator_id === this.userData.id)
          || access === "FIELD_ACCESS_VIEWER";
    },

    isFillable() {
      if (!this.field) {
        return false;
      }
      return this.field.is_fillable;
    },

    getData() {
      const field = this.field
      const task = this.task
      const fieldName = _.has(field, 'name') ? field.name : null
      const taskData = _.has(task,'data') ? task.data : null
      const taskField = _.has(task, fieldName) ? task[fieldName] : null
      const taskDataField = _.has(taskData, fieldName) ? taskData[fieldName] : taskField

      return taskDataField ? taskDataField : taskField
    },

    isReadOnly() {
      if (this.readonly) {
        return true;
      }

      if (!this.field) {
        return false;
      }

      const field = this.field;
      if (field.type !== 'FIELD_TYPE_TASK_RELATION') {
        return false
      }

      // The rest applies only to FIELD_TYPE_TASK_RELATION fields
      const subtaskRule = field.subtask_rule;

      // Seems to be incorrect
      // if (subtaskRule.multiple === false) {
      //   return true;
      // }

      if (undefined === subtaskRule.subtask_category_states || null === subtaskRule.subtask_category_states) {
        return false;
      }

      for (const state of subtaskRule.subtask_category_states) {
        if (state.id === this.task.current_state_id) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    setValue({value}) {
      if (!this.field) {
        return false;
      }
      this.value = value;

      if (this.field.type === "FIELD_TYPE_CHECKLIST") {
        this.edit();
      }
    },

    updateEditMode(editModeValue) {
      this.editMode = editModeValue;
    },

    enableEditMode() {
      if (!this.showEditButtons) {
        return ;
      }

      if (!this.readonly && this.hasPermission) {
        this.editMode = true;
        this.value = (this.getData && typeof this.getData === "object" ? this.getData.value : this.getData);
      }
    },

    initEditMode() {
      if (!this.field) {
        return false;
      }
      this.editMode = this.field.type === "FIELD_TYPE_CHECKLIST";
    },

    disableEditMode() {
      this.initEditMode();
      this.saving = false;
      // this.value = null;
    },

    /**
     * Save function
     */
    edit() {
      if (this.readonly || !this.field){
        return;
      }

      let value;
      const self = this;

      if (self.field.type === "FIELD_TYPE_CHECKBOX") {
        value = !!self.value;
      } else if (self.field.type === "FIELD_TYPE_CHECKLIST") {
        value = JSON.stringify(self.value);
      } else if (self.field.type === "FIELD_TYPE_DATE") {
        value = self.$moment(self.value).format('YYYY-MM-DD');
        console.log(value);
      } else {
        value = self.value;
      }
      const data = new FormData();
      data.append("task_id", self.task.id);
      data.append("field_id", self.field.id);
      data.append("value", value || '');
      self.saving = true;
      self.$store.dispatch("task/updateField", data)
          .then(() => {
                self.$store.dispatch("task/silentFetch", self.task.id);
                self.disableEditMode();
              },
              () => {
                self.disableEditMode();
                self.$notify({
                  group: "TaskView",
                  icon: "fa fa-times",
                  title: 'Не удалось сохранить значение поля',
                  type: "danger"
                });
              })
    },

    /**
     * Save on Ctrl+Enter key press
     */
    hotSave() {
      console.log("Hot save", this.editMode);

      if (this.editMode) {
        this.edit();
      }
    }
  },
  mounted() {
    this.initEditMode();
  }
};
</script>

