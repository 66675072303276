<template>
    <div class="tasks-table-wrapper">
        <el-table
                ref="table"
                :data="dataset"
                :default-sort="defaultSorting"
                :empty-text="emptyText"
                :header-cell-class-name="headerCellClassName"
                :row-class-name="rowClassName"
                :cell-class-name="cellClassName"
                @current-change="rowClick">

            <!-- ID -->
            <el-table-column :sortable="false" prop="id" label="#" width="60"/>

            <!-- Subject -->
            <el-table-column :resizable="true" prop="subject" label="Тема">
                <template slot-scope="{row}">
                    <router-link :to="{ name: 'TaskView',  params :  {categoryId:row.category_id, taskId: row.id} }">
                        {{ row.subject }}
                    </router-link>
                </template>
            </el-table-column>

            <!-- Brief -->
            <el-table-column prop="brief" type="expand" v-if="showBriefColumn">
                <template slot-scope="props">
                    <div class="cell-contents-wrapper">

                        <p class="in-place-brief"
                           v-html="$options.filters.highlight(props.row.brief, highlightQuery)"/>

                        <field-list :task="props.row"
                                    :items="props.row.category.fields"
                                    class="in-place-fields"
                                    :highlight-query="highlightQuery"
                                    :allow-edit="false"/>

                        <el-divider
                                v-if="props.row.hasOwnProperty('subtasks') && props.row.subtasks.length"
                                content-position="left">
                            Подзадачи
                        </el-divider>
                        <ul v-if="props.row.hasOwnProperty('subtasks') && props.row.subtasks.length">
                            <li v-for="subtask of props.row.subtasks" :key="subtask.id">
                                <router-link :to="subtaskRoute(subtask)">
                                    {{ subtask.subject }}
                                </router-link>
                                (#{{ subtask.id }})
                            </li>
                        </ul>

                    </div>
                </template>
            </el-table-column>

            <!-- State -->
            <el-table-column :sortable="false" label="Статус" prop="current_state"
                             :filter-method="filter" :filter-multiple="true"
                             :filters="uniqueColumnValues('current_state', state => state ? state.title : '--', state => state ? state.id : null)">
                <template slot-scope="{row}">
                    <el-tag size="small" type="default" v-if="row.current_state">
                        {{ row.current_state.title }}
                    </el-tag>
                </template>
            </el-table-column>

            <!-- Category -->
            <el-table-column :resizable="true" label="Категория" v-if="showCategoryColumn" prop="category"
                             :filter-method="filter" :filter-multiple="true"
                             :filters="uniqueColumnValues('category', cat => cat ? cat.title : '', cat => cat ? cat.id : null)">
                <template slot-scope="{row}">
                    {{ row && row.category ? row.category.title : '' }}
                </template>
            </el-table-column>

            <!-- Creator -->
            <el-table-column :sortable="false" :resizable="true" label="Заказчик" prop="creator"
                             v-if="showCreatorColumn !== false"
                             :filter-multiple="true" :filter-method="filter"
                             :filters="uniqueColumnValues('creator',
                                user => user.hasOwnProperty('name') ? user.name : '',
                                user => user.id)">
                <template slot-scope="{row}">
                    <div v-if="row.creator !== undefined && row.creator !== null">
                        <span>{{ row.creator.hasOwnProperty('name') ? row.creator.name : '' }}</span>
                    </div>
                </template>
            </el-table-column>

            <!-- Performer -->
            <el-table-column :sortable="false" :resizable="true" label="Исполнитель" prop="performer"
                             v-if="showPerformerColumn !== false"
                             :filter-multiple="true" :filter-method="filter"
                             :filters="uniquePerformerValues()">
                <template slot-scope="{row}">
                    <div v-if="!performerIsAssignable(row, userId) && row.performer_id && row.performer !== undefined
                     && row.performer !== null">
                        <span>{{ row.performer.hasOwnProperty('name') ? row.performer.name : '' }}</span>
                    </div>
                    <button v-if="performerIsAssignable(row, userId)"
                            :disabled="assigning"
                            class="btn btn-xs btn-primary" @click="assignToMe(row)">Взять
                    </button>
                </template>
            </el-table-column>

            <!-- Expiring At -->
            <el-table-column :sortable="true" :resizable="true" label="Срок" prop="expired_at"
                             v-if="showExpiredColumn === true"
                             :filters="uniqueColumnValues('expired_at', dateFormat, date => date )"
                             :filter-method="filter">
                <template slot-scope="{row}">
                    <span :class="{expiring : row.is_active && expiring(row.expiring_at)}">
                      <span v-if="isToday(row.expired_at)" :class="{expiring: !isExpired(row.expired_at)}">
                        <span v-if="null === row.category.default_period_days">{{
                                row.expired_at | moment( 'HH:mm')
                            }}</span>
                        <span v-else>Сегодня</span>
                      </span>
                      <span v-else>
                      {{ row.expired_at | moment( 'D.MM.YYYY') }}
                      </span>
                    </span>
                </template>
            </el-table-column>

            <!-- Closed At -->
            <el-table-column :sortable="true" :resizable="true" label="Закрыта" prop="closed_at"
                             v-if="showClosedColumn === true"
                             :filters="uniqueColumnValues('closed_at', dateFormat, date => date )"
                             :filter-method="filter">
                <template slot-scope="{row}">
                  <span v-if="isToday(row.closed_at)">
                  {{ row.closed_at | moment( 'HH:mm') }}
                  </span>
                    <span v-else>
                  {{ row.closed_at | moment( 'D.MM.YYYY') }}
                  </span>
                </template>
            </el-table-column>

            <!-- Extra Fields -->
            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <el-table-column v-for="field of extraFields" :key="field.id" :label="field.name" v-if="field.display"
                             :filter-multiple="true" :filter-method="filterExtraColumn" :prop="field.name"
                             :filters="uniqueExtraColumnValues(field)"
            >
                <template slot-scope="{row}">
                    <field-item-value :data="dataValueOf(row, field)" :field="field"/>
                </template>
            </el-table-column>

            <!-- Approve Action -->
            <el-table-column v-if="showApprovalColumn">
                <template slot-scope="{row}">
                    <div v-for="approval in [requestedApprovalOfTask(row.id)]"
                         :key="approval.id">
                        <el-button v-if="approval && approval.is_requested && !approval.is_granted" @click="grantApproval($event, approval)">Утвердить</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import moment from "moment";
import FieldItemValue from "../TaskView/FieldList/FieldItem/FieldItemValue";
import FieldList from "../TaskView/FieldList/FieldList.vue";

export default {
    name: "TasksTable",
    components: {
        FieldItemValue,
        FieldList
    },
    props: {
        highlightQuery: {
            type: String,
            default: ''
        },
        tasks: {
            type: Array,
            default: () => []
        },
        extraColumns: {
            type: Array,
            default: () => []
        },
        defaultSort: {
            type: Object,
            default: () => ({
                prop: "id",
                order: "desc"
            })
        },
        showExpiredColumn: {
            type: Boolean,
            default: () => true
        },
        showClosedColumn: {
            type: Boolean,
            default: () => false
        },
        showApprovalColumn: {
            type: Boolean,
            default: () => false
        },
        showCreatorColumn: {
            type: Boolean,
            default: () => null
        },
        showPerformerColumn: {
            type: Boolean,
            default: () => null
        },
        showCategoryColumn: {
            type: Boolean,
            default: () => false
        },
        showBriefColumn: {
            type: Boolean,
            default: () => false
        },
        showSilentTasks: {
            type: Boolean,
            default: () => true
        },
        emptyText: {
            type: String,
            default: () => "Нет задач"
        },
        clickCallback: Function,
    },
    data: () => ({
        currentPage: 1,
        perPage: 1,
        assigning: false,
        highlightStyles: {
            backgroundColor: '#fff300',
            color: 'inherit'
        }
    }),
    computed: {
        ...mapGetters("approvals", ["approvals", "loading"]),
      ...mapGetters("auth", ["userId"]),
      ...mapGetters("categories", ["findCategory"]),
        defaultSorting: state => ({
            prop: state.defaultSort.prop,
            order: state.defaultSort.order === "desc" || state.defaultSort.order === -1 || state.defaultSort.order ===
            "descending"
                    ? "descending" : "ascending"
        }),
        extraFields: state => state.extraColumns ? state.extraColumns : [],
        dataset: state => state.tasks && state.tasks.length
                ? state.tasks.filter(
                        task => {
                            return state.showSilentTasks || task.is_expiring || !task.is_silent;
                        })
                : []
    },
    watch: {
        tasks: {
            handler: "subscribe",
            initial: true
        }
    },
    methods: {
        /**
         * @param {object} task
         */
        rowClick(task) {
            if (this.clickCallback) {
                this.clickCallback(task);
            } else {
                this.goto(task);
            }
        },

        /**
         * @param {object} task
         */
        goto(task) {
            const path = this.getPath(task);
            if (path) {
                this.$router.push(path)
            }
        },

        /**
         * Get task URL path
         * @param {object} task
         * @return {string}
         */
        getPath(task) {
            return `/category/${task.category_id}/task/${task.id}`;
        },

        subscribe(tasks) {
            this.$store.dispatch("task/batchSubscribe", tasks).catch();
        },
        filterExtraColumn(filter, row, column) {
            return row.data[column.property] === filter;
        },
        filter(filter, row, column) {
            let value = row[column.property];
            if (typeof value === "object" && value.id) {
                value = value.id;
            }

            return value === filter;
        },
        dateFormat(date) {
            return moment(date).format("D.M.YYYY");
        },
        uniqueColumnValues(column, textCallback, valueCallback) {
            if (column !== undefined) {
                return _.uniqBy(
                        this.tasks.map(task => ({
                            text: textCallback ? textCallback(task[column]) : task[column],
                            value: valueCallback ? valueCallback(task[column])
                                    : (textCallback ? textCallback(task[column]) : task[column])
                        })),
                        "text");
            }
        },
        uniquePerformerValues() {
            let tasks = _.filter(this.tasks, task => task.performer_id !== null);
            tasks = _.uniqBy(tasks.map(task => ({
                text: task.performer && _.has(task.performer, 'name') ? task.performer.name : '--',
                value: task.performer_id
            })), "text");
            return tasks;
        },
        uniqueExtraColumnValues(field) {
            if (field !== undefined) {
                const values = [];
                for (const task of this.tasks) {
                    values.push(
                            this.dataValueOf(task, field)
                    );
                }
                return _.uniqBy(
                        values.map(value => ({
                            text: value,
                            value: value
                        })),
                        "text");
            }
        },
        isExpired(expiringAt, closedAt) {
            return moment(expiringAt).isBefore(moment(closedAt ? closedAt : null));
        },
        isToday(expiringAt) {
            return moment(expiringAt).startOf("day").isSame(moment().startOf("day"));
        },
        performerIsAssignable: (row, userId) => row && row.is_new
            && row.category
            && row.category.without_performer
            && row.category.user_permissions
            && row.category.user_permissions.perform
            && row.performer_id !== userId,
        expiring(expiringAt) {
            return moment(new Date()).isAfter(expiringAt);
        },
        cellClassName({column}) {
            return `column-${column.property}`;
        },
        headerCellClassName({column}) {
            const classes = [column.id, column.order, column.headerAlign, column.className, column.labelClassName];

            if (!column.children) {
                classes.push("is-leaf");
            }

            if (column.sortable) {
                classes.push("is-sortable");
            }

            const columnName = `column-${column.property}`;
            classes.push(columnName);

            return classes.join(" ");
        },
        rowClassName({row}) {
            return (row.is_closed ? "closed" : "active")
                    + (row.is_expired ? " expired"
                                    : (row.is_expiring ? " expiring" : "")
                    );
        },
        dataValueOf(task, field) {
            if (!_.has(field, name)) {
                return null;
            }
            let value = task.data[field.name];
            if (typeof value === "object" && field.type === "FIELD_TYPE_TASK") {
                return value.subject;
            } else if (field.type === "FIELD_TYPE_CHECKBOX") {
                return !!((value === "true" || value === true));
            } else {
                return value;
            }
        },

        requestedApprovalOfTask(taskId) {
            return this.approvals ? _.find(this.approvals, {task_id: taskId}) : null;
        },

        /**
         * Grant approval
         * @param event
         * @param approval
         */
        grantApproval(event, approval) {
            const element = event.target.parentElement;
            this.$store.dispatch("approvals/grant", {
                approval_id: approval.id,
                task_id: approval.task_id
            }).then(() => {
                this.$store.dispatch("approvals/fetchMyApprovals").catch();
                element.remove();
                this.$notify({
                    icon: "fa fa-check",
                    group: "TasksApprovedByMe",
                    title: "Утверждено",
                    type: "success"
                });
            }).catch(errors => {
                this.$notify({
                    icon: "fa fa-times",
                    group: "TasksApprovedByMe",
                    title: _.isArray(errors) ? errors.join(",") : errors,
                    type: "danger"
                });
            });
        },

        /**
         * Set user as task performer
         */
        assignToMe(task) {
            if (this.performerIsAssignable(task, this.userId)) {
                this.assigning = true;
                this.$store.dispatch("task/assignToMe", {task_id: task.id}).then(() => {
                    this.assigning = false;
                }).catch((error) => {
                    this.assigning = false;
                    this.$notify({
                        icon: "fa fa-times",
                        group: "TaskCreate",
                        title: error,
                        type: "danger"
                    });
                });
            }
        },

        subtaskRoute(task) {
            return this.$taskRoute(task.category_id, task.id);
        }
    }
}
;
</script>
