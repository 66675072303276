<template>
  <div class="edit-button-group" :class="customClass">
    <button class="btn btn-sm btn-primary mb-0" v-show="editing" @click="save"
            :disabled="saving" v-b-popover.hover="'Ctrl + Enter'">
      {{ saving ? "Сохраняем" : "Сохранить" }}
    </button>
    <button class="btn btn-sm btn-link mb-0" v-show="editing" @click="cancel">
      Отмена
    </button>
    <button class="btn btn-link btn-sm mb-0 edit-link" v-show="!editing" @click.prevent="edit">
      Изменить
    </button>
  </div>
</template>

<script>
export default {
  name: "EditButtonGroup",
  props: {
    saving: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    onSave: {
      type: Function,
      required: true,
      default: () => {}
    },
    onEdit: {
      type: Function,
      required: true,
      default: () => {}
    },
    onCancel: {
      type: Function,
      required: true,
      default: () => {}
    },
  },
  methods: {
    edit() {
      this.onEdit();
      this.$emit('isEditing', true);
    },
    save() {
      this.onSave();
      this.$emit('isEditing', false);
    },
    cancel() {
      this.onCancel();
      this.$emit('isEditing', false);
    },
  }
}
</script>

<style scoped>

</style>