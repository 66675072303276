export default {
  TASK_SUBSCRIBED_FOR_UPDATES: 'TASK_SUBSCRIBED_FOR_UPDATES',
  TASK_FIELD_UPDATED: 'TASK_FIELD_UPDATED',
  TASK_COMMENT_ADDED: 'TASK_COMMENT_ADDED',
  TASK_ATTACHMENT_UPLOADED: 'TASK_ATTACHMENT_UPLOADED',
  TASK_STATE_CHANGED: 'TASK_STATE_CHANGED',
  TASK_CLOSED: 'TASK_CLOSED',
  TASK_CREATOR_CHANGED: 'TASK_CREATOR_CHANGED',
  TASK_PERFORMER_CHANGED: 'TASK_PERFORMER_CHANGED',

  TASK_TRANSIT: 'TASK_TRANSIT',
  TASK_TRANSIT_SUCCESS: 'TASK_TRANSIT_SUCCESS',
  TASK_TRANSIT_FAILED: 'TASK_TRANSIT_FAILED',

  TASK_CHANGE_CREATOR: 'TASK_CHANGE_CREATOR',
  TASK_CHANGE_CREATOR_SUCCESS: 'TASK_CHANGE_CREATOR_SUCCESS',
  TASK_CHANGE_CREATOR_FAILED: 'TASK_CHANGE_CREATOR_FAILED',

  TASK_CHANGE_PERFORMER: 'TASK_CHANGE_PERFORMER',
  TASK_CHANGE_PERFORMER_SUCCESS: 'TASK_CHANGE_PERFORMER_SUCCESS',
  TASK_CHANGE_PERFORMER_FAILED: 'TASK_CHANGE_PERFORMER_FAILED',

  TASK_CHANGE_SPECTATORS: 'TASK_CHANGE_SPECTATORS',
  TASK_CHANGE_SPECTATORS_SUCCESS: 'TASK_CHANGE_SPECTATORS_SUCCESS',
  TASK_CHANGE_SPECTATORS_FAILED: 'TASK_CHANGE_SPECTATORS_FAILED',

  TASK_ASSIGN_SUBTASK: 'TASK_ASSIGN_SUBTASK',
  TASK_ASSIGN_SUBTASK_SUCCESS: 'TASK_ASSIGN_SUBTASK_SUCCESS',
  TASK_ASSIGN_SUBTASK_FAILED: 'TASK_ASSIGN_SUBTASK_FAILED',

  TASK_REMOVE_SUBTASK: 'TASK_REMOVE_SUBTASK',
  TASK_REMOVE_SUBTASK_SUCCESS: 'TASK_REMOVE_SUBTASK_SUCCESS',
  TASK_REMOVE_SUBTASK_FAILED: 'TASK_REMOVE_SUBTASK_FAILED',

  TASK_ASSIGN_TO_ME: 'TASK_ASSIGN_TO_ME',
  TASK_ASSIGN_TO_ME_SUCCESS: 'TASK_ASSIGN_TO_ME_SUCCESS',
  TASK_ASSIGN_TO_ME_FAILED: 'TASK_ASSIGN_TO_ME_FAILED',

  TASK_UPDATE_SUBJECT: 'TASK_UPDATE_SUBJECT',
  TASK_UPDATE_SUBJECT_SUCCESS: 'TASK_UPDATE_SUBJECT_SUCCESS',
  TASK_UPDATE_SUBJECT_FAILED: 'TASK_UPDATE_SUBJECT_FAILED',

  TASK_UPDATE_BRIEF: 'TASK_UPDATE_BRIEF',
  TASK_UPDATE_BRIEF_SUCCESS: 'TASK_UPDATE_BRIEF_SUCCESS',
  TASK_UPDATE_BRIEF_FAILED: 'TASK_UPDATE_BRIEF_FAILED',

  TASK_UPDATE_EXPIRED_AT: 'TASK_UPDATE_EXPIRED_AT',
  TASK_UPDATE_EXPIRED_AT_SUCCESS: 'TASK_UPDATE_EXPIRED_AT_SUCCESS',
  TASK_UPDATE_EXPIRED_AT_FAILED: 'TASK_UPDATE_EXPIRED_AT_FAILED',

  TASK_CREATE: 'TASK_CREATE',
  TASK_CREATE_SUCCESS: 'TASK_CREATE_SUCCESS',
  TASK_CREATE_FAILED: 'TASK_CREATE_FAILED',

  TASK_DELETE: 'TASK_DELETE',
  TASK_DELETE_SUCCESS: 'TASK_DELETE_SUCCESS',
  TASK_DELETE_FAILED: 'TASK_DELETE_FAILED',

  TASK_CREATE_COMMENT: 'TASK_CREATE_COMMENT',
  TASK_CREATE_COMMENT_SUCCESS: 'TASK_CREATE_COMMENT_SUCCESS',
  TASK_CREATE_COMMENT_FAILED: 'TASK_CREATE_COMMENT_FAILED',
  TASK_COMMENT_PUSHED: 'TASK_COMMENT_PUSHED',

  TASK_FIELD_UPDATE: 'TASK_FIELD_UPDATE',
  TASK_FIELD_UPDATE_SUCCESS: 'TASK_FIELD_UPDATE_SUCCESS',
  TASK_FIELD_UPDATE_FAILED: 'TASK_FIELD_UPDATE_FAILED',

  TASK_CREATE_ATTACHMENT: 'TASK_CREATE_ATTACHMENT',
  TASK_CREATE_ATTACHMENT_PROGRESS: 'TASK_CREATE_ATTACHMENT_PROGRESS',
  TASK_CREATE_ATTACHMENT_SUCCESS: 'TASK_CREATE_ATTACHMENT_SUCCESS',
  TASK_CREATE_ATTACHMENT_FAILED: 'TASK_CREATE_ATTACHMENT_FAILED',
  TASK_DELETE_ATTACHMENT: 'TASK_DELETE_ATTACHMENT',
  TASK_DELETE_ATTACHMENT_SUCCESS: 'TASK_DELETE_ATTACHMENT_SUCCESS',
  TASK_DELETE_ATTACHMENT_FAILED: 'TASK_DELETE_ATTACHMENT_FAILED',
  TASK_ATTACHMENT_PUSHED: 'TASK_ATTACHMENT_PUSHED',

  TASK_FETCH: 'TASK_FETCH',
  TASK_FETCH_SUCCESS: 'TASK_FETCH_SUCCESS',
  TASK_FETCH_FAILED: 'TASK_FETCH_FAILED',
  TASK_FETCH_NOT_FOUND: 'TASK_FETCH_NOT_FOUND',

  TASK_FETCH_SUBTASKS: "TASK_FETCH_SUBTASKS",
  TASK_FETCH_SUBTASKS_SUCCESS: "TASK_FETCH_SUBTASKS_SUCCESS",
  TASK_FETCH_SUBTASKS_FAILED: "TASK_FETCH_SUBTASKS_FAILED",

  TASK_PRELOAD: "TASK_PRELOAD",
}
