import axios from 'axios';
import _ from 'lodash';
import api from '../config/api';

const getAccessToken = () => localStorage.getItem('access_token');

export const AuthApi = axios.create({
    baseURL: `${api.backend.url}/api`,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

// @todo Remove after implementing substitution for UserApi.post('/user')
export const UserApi = axios.create({
    baseURL: `${api.users.url}/api`,
    headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

export const CoreApi = axios.create({
    baseURL: `${api.backend.url}/api`,
    headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

export const CoreApiBlob = axios.create({
    baseURL: `${api.backend.url}/api`,
    responseType: 'blob',
    headers: {
        Authorization: `Bearer ${getAccessToken()}`,
    },
});

export const CoreApiArrayBuffer = axios.create({
    baseURL: `${api.backend.url}/api`,
    responseType: 'arraybuffer',
    headers: {
        Authorization: `Bearer ${getAccessToken()}`,
    },
});

export const handleError = (response) => {
    let error;
    if (response === undefined) {
        error = 'Ошибка подключения!';
    } else if (_.has(response, 'data')) {
        if ('message' in response.data) {
            error = response.data.message;
        }
        if (_.has(response.data, 'error.message')) {
            error = response.data.error.message
        }
        if ('errors' in response.data) {
            error = _.map(response.data.errors,
                (errors) => (typeof errors === 'string' ? errors : errors.join(',')))
                .join('\n ');
        }
    } else {
        error = response.message || response;
    }
    // console.error(error, response);
    return error;
};

export const getValidationErrors = (response) => {
    if (undefined !== response.data && undefined !== response.data.message && 'validation_error' === response.data.message) {
      return response.data.data.messages;
    }

    if (!('data' in response)) {
        return null;
    }
    const payload = response.data;
    if (undefined === payload || !('message' in payload) || 'validation_error' !== payload.message) {
        return null;
    }
    if ('data' in payload) {
        const errorBag = {};
        const data = payload.data;
        const errors = data.errors;
        const messages = data.messages;
        for (const field in errors) {
            if (Object.prototype.hasOwnProperty.call(errorBag, field)) {
                errorBag[field] = {errorTypes: [], messages: []};
                const errorSet = errors[field];
                for (const error of errorSet) {
                    errorBag[field].errorTypes.push(error.name);
                }
            }
        }
        for (const field in messages) {
            if (Object.prototype.hasOwnProperty.call(messages, field)) {
                if (!Object.prototype.hasOwnProperty.call(errorBag, field)){
                    errorBag[field] = {errorTypes: [], messages: []};
                }
                const messageSet = messages[field];
                for (const error in messageSet) {
                    if (Object.prototype.hasOwnProperty.call(messageSet, error)) {
                        errorBag[field].messages.push(messageSet[error]);
                    }
                }
            }
        }
        return errorBag;
    }
    return null;
};
