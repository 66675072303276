import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMoment from 'vue-moment';
import {VueTabs} from 'vue-nav-tabs';
import VueApexCharts from 'vue-apexcharts'
import {
    Button,
    Collapse,
    DatePicker,
    Option,
    Pagination,
    Scrollbar,
    Select,
    Table,
    TableColumn,
    Tag,
    TimeSelect,
    Divider,
    Alert
} from 'element-ui';
import ElementUI from 'element-ui';
import ElTableWrapper from 'el-table-wrapper'
// eslint-disable-next-line
import Pusher from 'pusher-js';
import BootstrapVue from 'bootstrap-vue';
import wysiwyg from 'vue-wysiwyg';
import Notifications from "vue-notifyjs";
import {validate as VeeValidate} from "vee-validate";
import SideBar from "./components/SidebarPlugin";
import "es6-promise/auto";

import App from './App.vue';
import store from './store';
import router from './routes/router';

import Filters from './lib/Filters';
import Components from "./lib/Components";
import Directives from "./lib/Directives";
import Globals from './lib/Globals';
import './lib/Icons';

import "bootstrap/dist/css/bootstrap.css";
import 'vue-wysiwyg/dist/vueWysiwyg.css';
import 'vue-notifyjs/themes/default.css';
import 'vue-nav-tabs/themes/vue-tabs.scss';
import "./assets/sass/app.sass";

import elementUiLang from 'element-ui/lib/locale/lang/ru-RU';
import elementUiLocale from 'element-ui/lib/locale';

const moment = require('moment');
moment.locale('ru');
elementUiLocale.use(elementUiLang);
// elementUiLang.i18n('ru-RU');

Vue.use(VueApexCharts);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tag);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(Button);
Vue.use(Select);
Vue.use(Option);
Vue.use(Pagination);
Vue.use(Collapse);
Vue.use(Divider);
Vue.use(Scrollbar);
Vue.use(Alert);
Vue.use(ElementUI);
Vue.use(ElTableWrapper);
Vue.use(VueTabs);
Vue.use(VueMoment, {moment});
Vue.use(wysiwyg, {
    hideModules: {"image": true},
});
Vue.use(SideBar);
Vue.use(Notifications);
Vue.use(VeeValidate);

Vue.use(Components);
Vue.use(Directives);
Vue.use(Filters);
Vue.use(Globals);

/* eslint-disable no-new */
new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store,
});

const env = process.env.NODE_ENV;
if ('development' === env) {
    console.log({
        ENV: env,
        CORE_API_URL: process.env.VUE_APP_CORE_API_URL,
        USER_API_URL: process.env.VUE_APP_USER_API_URL,
        TELEGRAM_BOT_NAME: process.env.VUE_APP_TELEGRAM_BOT_NAME,
    });
}
