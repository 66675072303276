import {CoreApi} from "../../lib/HttpApi";

const SETTINGS_FETCH = "SETTINGS_FETCH";
const SETTINGS_FETCH_SUCCESS = "SETTINGS_FETCH_SUCCESS";
const SETTINGS_FETCH_FAILED = "SETTINGS_FETCH_FAILED";

const settingsCached = localStorage.getItem('settings')
  ? JSON.parse(localStorage.getItem('settings'))
  : null;

const state = {
  displayMyTasksBlock: settingsCached ? settingsCached.display_my_tasks_block : true,
  sidebarColor: settingsCached ? settingsCached.sidebar_color : null,
  logoPath: settingsCached ? settingsCached.logo_path : null,
};

const getters = {
  displayMyTasksBlock: state => state.displayMyTasksBlock,
  sidebarColor: state => state.sidebarColor,
  logoPath: state => state.logoPath,
};

const actions = {
  fetch: ({commit}) => {
    commit(SETTINGS_FETCH);

    return new Promise((resolve, reject) => {

      CoreApi
        .get(`/settings`)
        .then(({data}) => {
          localStorage.setItem('settings', JSON.stringify(data.data));
          commit(SETTINGS_FETCH_SUCCESS, data.data);
          resolve(data.data);
        })
        .catch((response) => {
          console.error(response);
          commit(SETTINGS_FETCH_FAILED);
          reject();
        });
    });

  },
};

const mutations = {
  [SETTINGS_FETCH](state) {
    state.loading = true;
  },

  [SETTINGS_FETCH_FAILED](state) {
    state.loading = false;
    state.logoPath = null;
    state.sidebarColor = null;
  },

  [SETTINGS_FETCH_SUCCESS](state, settings) {
    state.loading = false;
    state.logoPath = settings.logo_path;
    state.sidebarColor = settings.sidebar_color;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
