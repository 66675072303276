<template>
	<div class="alert alert-danger offline-message" v-if="offline">
		<span>Вы не подключены к интернету. Восстановите соединение, чтобы продолжить.</span>
	</div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters("connection", ["offline"])
        },
        mounted(){
            this.$store.dispatch("connection/watchNetwork").catch();
        }
    };
</script>
