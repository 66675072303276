<template>
  <div class="category-view page" ref="cat" :key="categoryId">
    <div v-if="hasAnyPermission === true">
      <div class="card pb-3">
        <loading-bar :loading="loading"></loading-bar>
        <div class="card-header category-header">

          <div class="category-title-col">
            <h4 class="card-title">
              {{title}}
            </h4>
            <div class="card-category" v-if="category && category.set">
              {{category.set ? category.set.title : ""}}
            </div>
          </div>

          <div class="presentation-btns-col text-right">
            <ul class="nav  nav-stacked mt-0 justify-content-end">
              <li v-for="(viewLabel, viewName) in views" :key="viewName">
                <button class="btn btn-sm" @click="switchView(viewName)"
                        :class="{'btn-default':viewName===viewParam, 'btn-link':viewName!==viewParam}">
                  {{ viewLabel }}
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div class="card-body p-0 tab-content" v-if="hasAnyPermission">
          <Presentation v-if="category && viewParam"
                        :viewName="viewParam"
                        :key="viewKey"
                        :viewKey="viewKey"
                        :show-create-button="hasPermissionToCreate"/>
        </div>
      </div>
    </div>

    <access-denied
      :show="hasPermissionToSee === false"
      message="У вас недостаточно прав для просмотра данной категории"/>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import LoadingBar from "../components/LoadingBar/LoadingBar.vue";
  import Presentation from "../components/Presentation/Presentation";
  import AccessDenied from "../components/Messages/AccessDenied";

  export default {
    name: "CategoryView",
    components: {
      Presentation,
      LoadingBar,
      AccessDenied
    },
    data: () => ({
      currentView: null
    }),
    computed: {
      ...mapGetters("auth", ["userData"]),
      ...mapGetters("categories", {
        categories: "categories",
        views: "categoryViews",
        fetchedCategory: "category",
        loadingCategories: "loading",
        findCategory: "findCategory",
        hasAnyPermission: "hasAnyPermission",
        hasPermissionToSee: "hasPermissionToSee",
        hasPermissionToCreate: "hasPermissionToCreate",
      }),
      ...mapGetters("tasks", {
        activeCollection: "activeCollection",
        closedCollection: "closedCollection",
        loadingTasks: "loading",
        isLoaded: "isLoaded",
        activePagination: "activePagination",
        closedPagination: "closedPagination"
      }),
      categoryId: state => state.$route.params.categoryId ? parseInt(state.$route.params.categoryId) : null,
      viewParam: state => state.$route.params.viewName ? state.$route.params.viewName : null,
      viewKey: state => state.viewParam + state.categoryId,
      category: state => state.findCategory(state.categoryId),
      defaultViewName: state => state.views ? Object.keys(state.views)[0] : null,
      extraFields: state => state.category ? state.category.fields : [],
      title: state => state.category ? state.category.title : '...',
      loading: state => state.loadingCategories || state.loadingTasks,
      fullyLoaded: state => state.isLoaded,
    },
    methods: {
      /**
       * @param {string} view
       */
      switchView(view) {
        if (view && this.categoryId) {
          this.$router.push(`/category/${this.categoryId}/${view}`)
        }
      },
    },
    mounted() {
      this.$store.dispatch(`categories/fetch`, this.categoryId).then(category => {
        document.title = category.title;
      });

      // Auto redirect to first view
      if (!this.viewParam && this.defaultViewName) {
        this.switchView(this.defaultViewName);
      }
    }
  };
</script>
