<template>
  <ul class="navbar-nav">
    <drop-down position="right">
      <template slot="title">
        <span class="icon">
          <font-awesome-icon icon="bell"/>
        </span>
        <span class="notification" v-show="count">{{count}}</span>
      </template>
      <li class="" v-show="!count">&nbsp;</li>
      <li class="header-item text-right" v-show="count">
        <a class="small" href="#" @click.prevent="markAllAsRead">Отметить все как прочитанные</a>
      </li>
      <NotificationDropdownItem :notification="notification"
                                v-for="notification of notifications.slice(0, 5)" :key="notification.id"/>
      <li class="dropdown-item text-center">
        <router-link :to="{name: 'NotificationsPage'}">Все уведомления</router-link>
      </li>
    </drop-down>
  </ul>
</template>
<script>
  import {mapGetters} from 'vuex'
import NotificationDropdownItem from './NotificationsDropdownItem'

export default {
    name: 'NotificationsDropdown',
    components: {NotificationDropdownItem},
    computed: {
      ...mapGetters('notifications', ['notifications', 'count']),
      ...mapGetters('auth', ['userId'])
    },
    methods: {
      markAllAsRead () {
        this.$store.dispatch('notifications/markAllAsRead').catch();
      }
    },
    mounted () {
      this.$store.dispatch('notifications/count').catch();
      this.$store.dispatch('notifications/fetch').catch();
      setTimeout(() => {
        if (this.userId) {
          this.$store.dispatch('notifications/subscribe', this.userId).catch();
        } else {
          setTimeout(() => {
            this.$store.dispatch('notifications/subscribe', this.userId).catch();
          }, 10000)
        }
      }, 2000)
  }
  }
</script>
