var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"attachments-table-wrapper"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.attachments.length),expression:"attachments.length"}],staticClass:"btn btn-link download-all-button",on:{"click":function($event){$event.preventDefault();return _vm.downloadAll($event)}}},[_c('font-awesome-icon',{attrs:{"icon":"download"}}),_c('span',{staticStyle:{"padding-left":"6px"}},[_vm._v("Скачать все")])],1),_c('el-table',{staticClass:"table attachment-table",attrs:{"data":_vm.attachmentsSorted,"empty-text":"Нет файлов"}},[_c('el-table-column',{attrs:{"label":"Превью","class-name":'col-2 text-center'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('PreviewLink',{attrs:{"file":row,"target":"_blank"}},[(row.is_image)?_c('preview',{staticClass:"mr-3 attachment-preview",attrs:{"src":row.url,"alt":row.name}}):_vm._e(),(!row.is_image)?_c('font-awesome-icon',{attrs:{"icon":_vm.icon(row),"size":"3x"}}):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"label":"Имя"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('DownloadLink',{staticClass:"download-link",attrs:{"file":row,"target":"_blank"}},[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Размер"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.filesize(row.filesize))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Дата"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatTime(row))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Загрузил"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user ? row.user.name : "--")+" ")]}}])}),_c('el-table-column',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.user.id === _vm.userId)?_c('a',{staticClass:"btn btn-sm btn-link float-right",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(row.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }