import {AuthApi, handleError, CoreApi} from "../../lib/HttpApi";
import router from "../../routes/router";

const AUTH_SET_REDIRECT_URL = "AUTH_SET_REDIRECT_URL";
const AUTH_LOGIN_START = "AUTH_LOGIN_START";
const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
const AUTH_LOGIN_FAILED = "AUTH_LOGIN_FAILED";
const AUTH_LOGOUT = "AUTH_LOGOUT";
const AUTH_SET_USERDATA = "AUTH_SET_USERDATA";
const AUTH_SET_ACCESS_TOKEN = "AUTH_SET_ACCESS_TOKEN";

const state = {
    errors  : [],
    userData: {},
    loggedIn: false,
    loading : false,
    redirectUrl: '/'
};
const getters = {
    redirectUrl : state => state.redirectUrl,
    loggedIn    : state => state.loggedIn,
    userData    : state => state.userData,
    lastName    : state => state.userData.last_name,
    firstName   : state => state.userData.first_name,
    userId      : state => state.userData.id,
    departmentId: state => state.userData.department_id,
    isSupervisor: state => state.userData.is_supervisor,
    roles       : state => state.userData.roles ? state.userData.roles.map(item => item.id)
                                                       .join(",") : null
};

const actions = {
    /**
     * Authentication Post request
     * with credentials
     * @param commit
     * @param credentials
     * @returns {Promise<any>}
     */
    authenticate: ( {commit}, credentials ) => {
        commit(AUTH_LOGIN_START);
        return new Promise(( resolve, reject ) => {
            AuthApi.post(`/login`, credentials)
                   .then(( {data} ) => {
                       const {access_token, user_data} = data;
                       commit(AUTH_SET_ACCESS_TOKEN, {access_token});
                       commit(AUTH_SET_USERDATA, {userData: user_data});
                       commit(AUTH_LOGIN_SUCCESS);
                       resolve({userData: user_data});
                   })
                   .catch(( {response} ) => {
                       console.log('response', response);
                       const error = handleError(response);
                       commit(AUTH_LOGIN_FAILED, error);
                       reject(error);
                   });
        });
    },

    /**
     * Loading user data from local storage
     * @param commit
     * @returns {Promise<any>}
     */
    recoverUser: ( {commit, dispatch} ) => {
        const token = localStorage.getItem("access_token");
        return new Promise(( resolve, reject ) => {
            if( token ) {
                CoreApi.get(`/user`)
                       .then(response => {
                           commit(AUTH_SET_USERDATA, {userData: response.data.data});
                           resolve(response.data.data);
                           dispatch("notifications/subscribe", response.data.data.id, {root: true});
                       })
                       .catch(( {response} ) => {
                           const error = handleError(response);
                           router.push("/login").catch();
                           reject(error);
                       });
            } else {
                this.$router.push("/login").catch();
                reject();
            }
        });
    },

    logout: ( {commit} ) => {
        commit(AUTH_LOGOUT);
    },

    setRedirectUrl:({commit}, url) => {
        commit(AUTH_SET_REDIRECT_URL, url);
    },

    resetRedirectUrl:({commit}) => {
        commit(AUTH_SET_REDIRECT_URL, '/');
    }
};

const mutations = {

    [AUTH_SET_REDIRECT_URL]( state, url ){
        if (url === '/login') {
            state.redirectUrl = '/';
        } else {
            state.redirectUrl = url;
        }
    },

    [AUTH_LOGIN_START]( state ){
        state.loading = true;
        state.loggedIn = false;
    },

    [AUTH_LOGIN_FAILED]( state, error ){
        state.errors.push(error);
        state.userData = {};
        state.loggedIn = false;
        state.loading = false;
        // console.debug('AUTH_LOGIN_FAILED')
    },

    [AUTH_LOGIN_SUCCESS]( state ){
        state.errors = [];
        state.loggedIn = true;
        state.loading = false;
        // console.debug('AUTH_LOGIN_SUCCESS')
    },

    [AUTH_LOGOUT]( state ){
        state.userData = {};
        state.loggedIn = false;
        localStorage.removeItem("menu");
        localStorage.removeItem("access_token");
        localStorage.removeItem("menu");
        window.location.reload();
        // console.debug('AUTH_LOGOUT')
    },

    [AUTH_SET_USERDATA]( state, {userData} ){
        state.userData = {...userData};
        state.loggedIn = true;
        console.debug('AUTH_SET_USERDATA', userData);
    },

    [AUTH_SET_ACCESS_TOKEN]( state, {access_token: accessToken} ){
        state.access_token = accessToken;
        localStorage.setItem("access_token", accessToken);
        // console.debug('AUTH_SET_ACCESS_TOKEN')
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
