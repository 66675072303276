<template>
  <div class="task-transition-actions card p-3 m-0" v-show="permittedActions.length">
    <ul class="task-actions list-inline list-unstyled mb-0">
      <li v-for="action in permittedActions" :key="action.id">
        <button class="btn btn-primary btn-wd" @click="transit(action.id)"
                :disabled="saving" v-b-popover.hover="description(action)">
          {{ action.label }}
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
  import {mapGetters} from "vuex";
  import makeTransition from "../../mixins/makeTransition";

  export default {
    name: "TaskTransitionActions",
    mixins: [makeTransition],
    props: {
      task: {},
      actions: {},
      hasPermissionToCreate: {
        type: Boolean,
        default: false
      },
      hasPermissionToPerform: {
        type: Boolean,
        default: false
      },
      isCreator: {
        type: Boolean,
        default: false
      },
      isPerformer: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters("task", ["saving"]),
      permittedActions: state => (state && state.actions) ? state.actions.filter(action => state.actionIsPermitted(action)) : [],
    },
    methods: {

      /**
       * Check if action is permitted for user
       **/
      actionIsPermitted(action) {
        return action.access === "TRANSITION_ACCESS_ANY" ||
          (action.access === "TRANSITION_ACCESS_PERFORMER" && this.isPerformer && this.hasPermissionToPerform) ||
          (action.access === "TRANSITION_ACCESS_CREATOR" && this.isCreator && this.hasPermissionToCreate);
      },

      /**
       * Perform transition from
       * one state to another
       * @param transitionId
       */
      transit(transitionId) {
        this.makeTransition(transitionId, this.task.id)
      },

      // Action description
      description: action => action && action.description ? action.description : ''
    }
  };
</script>
