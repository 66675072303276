<template>
  <div>
    <sidebar-item v-for="set of menu" :key="set.id" :link="{name: set.label}" :style="{paddingLeft: offset}">

      <sidebar-tree :menu="set.submenus" :level="level+1"/>

      <sidebar-item
        v-for="category of set.items"
        :key="category.category_id"
        :link="{name: category.label, path: getCategoryPresentationDefaultPath(category), categoryPath:
        getCategoryPath(category)}"/>

    </sidebar-item>
  </div>

</template>
<script>
  import SidebarItem from "./SidebarItem";

  export default {
    name: 'SidebarTree',
    components: {
      SidebarItem
    },
    props: {
      menu: {
        type: Array
      },
      level: {
        type: Number,
        default: 0
      }
    },
    computed: {
      offset: state => state.level * 10 + 'px'
    },
    methods: {
      getCategoryPath(category) {
        return `/category/${category.category_id}`;
      },

      getCategoryPresentationDefaultPath(category) {
        let url = this.getCategoryPath(category);
        const defaultView =  category.default_view_name;
        if (defaultView) {
          url += `/${defaultView}`;
        }
        return url;
      }
    }
  }
</script>
<style>
  .sidebar-menu-item {
    cursor: pointer;
  }
</style>
