<template>
  <div class="inline-input" :class="{required: required && !isAutoIncrement}">

    <select
        :tabindex="tabindex"
        v-if="field.type === 'FIELD_TYPE_OPTION'"
        :name="fieldName"
        v-model="newValue" class="custom-select"
        :disabled="saving || disabled" :required="required">
      <option value="">Выберите</option>
      <option v-for="option in field.options" :key="option.id">{{ option }}</option>
    </select>

    <div
        v-else-if="field.type === 'FIELD_TYPE_CHECKBOX'">
      <label>
        <b-form-checkbox
            :tabindex="tabindex"
            :name="fieldName" :value="true"
            class="d-block" v-model="newValue" :disabled="disabled" :required="required">
          {{ field.title }}
        </b-form-checkbox>
      </label>
    </div>

    <b-form-group
        v-else-if="field.type === 'FIELD_TYPE_CHECKLIST'"
        class="field-checkbox"
    >
      <b-form-checkbox-group stacked :id="field.name" :name="field.name" v-model="newValue" :disabled="disabled">
        <b-form-checkbox v-for="option of field.options" :value="option" :key="option" :name="fieldName">
          {{ option }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>

    <textarea
        v-else-if="field.type === 'FIELD_TYPE_LONGTEXT'"
        v-model="newValue" :name="fieldName"
        :tabindex="tabindex"
        class="field field-longtext form-control textarea" rows="4"
        :disabled="saving || disabled" :required="required"/>

    <input
        v-else-if="field.type === 'FIELD_TYPE_NUMBER'"
        type="text" v-model="newValue" :name="fieldName"
        :placeholder="isAutoIncrement ? autoIncrementHint : field.title"
        :tabindex="tabindex"
        @keyup="newValue = stripNonDigits(newValue)"
        :class="className" class="form-control"
        :disabled="saving || disabled || isAutoIncrement"
        :required="required && !isAutoIncrement"/>

    <div
        v-else-if="field.type === 'FIELD_TYPE_URL'"
        class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <font-awesome-icon icon="globe"/>
        </span>
      </div>
      <input type="text" class="form-control" v-model="newValue" :name="fieldName" :tabindex="tabindex"
             @keyup="newValue = appendHttp(newValue)" :required="required"/>
    </div>

    <user-select
        v-else-if="field.type === 'FIELD_TYPE_USER'"
        v-model="newValue"
        :name="fieldName"
        :tabindex="tabindex"
        :placeholder="field.title"
        :required="required"
        :readonly="saving || disabled"
        property="id"
    />

    <b-form-file
        v-else-if="field.type === 'FIELD_TYPE_FILE'"
        :name="fieldName"
        :tabindex="tabindex"
        placeholder="Выберите файл..."
        drop-placeholder="Перетащите файл сюда..."
        no-drop
        v-model="newValue" :disabled="saving || disabled"/>

    <el-date-picker
        v-else-if="field.type === 'FIELD_TYPE_DATE'"
        @change="formatDateInput" :format="'dd.MM.yyyy'"
        :picker-options="{firstDayOfWeek: 1}"
        :tabindex="tabindex"
        v-model="newValue" :name="fieldName" type="date" placeholder="Выберите дату" :disabled="saving || disabled"/>

    <task-select
        v-else-if="field.type === 'FIELD_TYPE_TASK'"
        :predefined-value="predefinedValue"
        :tabindex="tabindex"
        @selected="pick"
        :name="fieldName"
        :default="newValue"
        :category-id="field.task_category_id"
        placeholder="Выберите задачу"
        sort-by="subject"
        :disabled="saving || disabled"/>

    <task-select
        v-else-if="field.type === 'FIELD_TYPE_TASK_RELATION'"
        :predefined-value="predefinedValue"
        :tabindex="tabindex"
        @selected="pick"
        :name="fieldName"
        :default="newValue"
        :filter-by-states="field.subtask_rule.parent_category_states"
        :active-tasks-only="true"
        :category-id="field.subtask_rule.category_id"
        placeholder="Выберите"
        sort-by="subject"
        :disabled="parentTaskId === predefinedValue || saving || disabled"/>

    <input v-else :type="type" v-model="newValue"
           :tabindex="tabindex" :name="fieldName" :class="className" class="form-control"
           :disabled="saving || disabled" :required="required">
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import TaskSelect from "../../../TaskSelect/TaskSelect.vue";
import UserSelect from '../../../UserSelect/UserSelect.vue'

export default {
  name: "field-item-edit",
  components: {
    TaskSelect,
    UserSelect
  },
  data: () => ({
    newValue: null
  }),
  props: ["data", "field", "value", "saving", "disabled", "required", "predefinedValue", "tabindex", "customClass"],
  computed: {
    ...mapGetters("users", ["users"]),
    parentTaskId: state => parseInt(state.$route.params.parentTaskId),
    usersSorted: state => state.users.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    }),
    fieldName: state => `data[${state.field.id}]`,
    isAutoIncrement: state => state.field.is_autoincrement ?? false,
    autoIncrementHint: state => (undefined === state.newValue || null === state.newValue) ? 'Поле будет заполнено автоматически' : ''
  },
  methods: {

    formatDateInput(dateValue) {
      return this.$moment(dateValue)
          .format("YYYY-MM-DD");
    },
    className() {
      switch (this.field.type) {
        case "FIELD_TYPE_TASK":
          return "field field-task";
        case "FIELD_TYPE_TASK_RELATION":
          return "field field-task-relation";
        case "FIELD_TYPE_OPTION":
          return "field field-option";
        case "FIELD_TYPE_NUMBER":
          return "field field-nubmer";
        case "FIELD_TYPE_LONGTEXT":
          return "field field-longtext";
        case "FIELD_TYPE_FILE":
          return "field field-file";
        case "FIELD_TYPE_USER":
          return "field field-user";
        case "FIELD_TYPE_CHECKBOX":
          return "field field-checkbox";
        default:
          return "field field-text";
      }
    },

    type() {
      switch (this.field.type) {
        case "FIELD_TYPE_TASK":
          break;
        case "FIELD_TYPE_OPTION":
          break;
        case "FIELD_TYPE_CHECKBOX":
          return "checkbox";
        default:
          return "text";
      }
    },

    stripNonDigits: value => value.replace(/[^\d.]/g, ""),

    appendHttp: value => {
      if (value && value.length >= 5 && value.match(/^http/) === null) {
        value = value.replace(/^(.*)/, "http://$1");
      }
      return value;
    },

    emit(value) {
      this.$emit("change", {
        field: this.field,
        value: value
      });
    },

    pick(id){
      this.newValue = id;
      this.emit(id);
    }
  },
  watch: {
    value() {
      this.newValue = this.value;
    },
    newValue(value) {
      if (this.newValue !== this.data) {
        if (this.field.type === 'FIELD_TYPE_CHECKLIST' && typeof this.newValue !== 'object') {
          value = [value];
          this.newValue = [value];
        }
        this.emit(value);
      }
    }
  },
  mounted() {
    this.newValue = this.predefinedValue ? this.predefinedValue : this.data;

    if (this.field.type === "FIELD_TYPE_USER") {
      this.$store.dispatch("users/fetch");
    }

    if (this.field.type === "FIELD_TYPE_CHECKBOX" && this.newValue === null) {
      this.newValue = [];
    }
  }
};
</script>
