import VueRouter from 'vue-router';
import routes from './routes';
import appConfig from '../config/app';

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: 'active'
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || appConfig.name;
  next();
});

export default router;
