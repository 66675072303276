<template>
  <div class="statistics-block">
    <apexchart
        type="bar"
        height="150"
        :options="chartOptions"
        :series="getChartData()"/>
  </div>
</template>

<script>
export default {
  name: "StatesStatisticsBlock",
  data: () => ({
    chartOptions: {
      chart: {
        type: 'bar',
        height: 150,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false,
          tools: {
            download: false
          }
        },
        offsetX: -5,
        dropShadow: {
          enabled: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      yaxis: {
        show: false,
        floating: true,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      xaxis: {
        categories: ['Среднее время нахождения задач в статусе'],
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " ч."
          }
        }
      },
      fill: {
        opacity: 1,
        type: 'solid'
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'left',
        offsetX: -6,
        offsetY: 0
      }
    },
  }),
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {

  },
  methods: {
    getChartData() {
      return this.data.map(item => ({name: item.state.title, data: [item.average_duration]}))
    },
  }
}
</script>
<style scoped>

</style>