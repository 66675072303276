import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import users from './modules/users'
import categories from './modules/categories'
import task from './modules/task/'
import tasks from './modules/tasks'
import approvals from './modules/approvals'
import notifications from './modules/notifications'
import connection from './modules/connection'
import settings from './modules/settings'
// import permissions from './modules/permissions'
import user from './modules/user'
import search from './modules/search'
import menu from './modules/menu'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    menu,
    users,
    categories,
    task,
    tasks,
    approvals,
    notifications,
    connection,
    // permissions,
    user,
    search,
    settings
  }
})
