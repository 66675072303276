<template>
  <ul class="navbar-nav" v-if="loggedIn">
    <drop-down position="right">
      <div slot="title">
        {{userData ? userData.name : ""}}
      </div>
      <li class="dropdown-item">
        <router-link active-class="act" class="dropdown-item" tag="a" :to="{ name: 'UserProfile' }">Мой профиль</router-link>
      </li>
      <li class="dropdown-item">
        <router-link active-class="act" class="dropdown-item" tag="a" :to="{ name: 'NotificationsPage' }">Уведомления</router-link>
      </li>
      <li class="dropdown-item">
        <a class="dropdown-item" @click.prevent="logout" href="#">Выход</a>
      </li>

      <!--<li class="divider"></li>-->
    </drop-down>
  </ul>
</template>
<script>
  import {mapGetters} from 'vuex'

export default {
    name: 'UserBlockDropdown',
    computed: {
      ...mapGetters('auth', ['userData', 'loggedIn'])
    },
    methods: {

      logout () {
        this.$store.dispatch('auth/logout')
            .then(() => this.$router.push('/login'))
      }
    }
  }
</script>
