<template>
  <div class="form-group has-label">
    <label :for="prop">{{label}}</label>
    <div class="value status-line" v-html="value"/>
  </div>
</template>

<script>
  import moment from 'moment'

export default {
    name: 'UserProfileProp',
    props: ['prop', 'label', 'userData', 'date', 'format'],
    computed: {
      value: state => {
        const val = state.userData[state.prop];
        let value;
        if ('phone' !== state.prop && moment(val).isValid() === true) {
          value = moment(val).format(state.date || 'LL')
        } else {
          value = val
        }

        if ( typeof state.format === 'function') {
          value = state.format(value);
        }

        return value
      }
    }
  }
</script>

<style scoped>

</style>
