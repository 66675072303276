<template>
  <a @click="action(file)" v-bind="$attrs">
    <slot></slot>
  </a>
</template>

<script>
import {CoreApiArrayBuffer} from "@/lib/HttpApi";

export default {
  name: "PreviewLink",
  props: {
    file: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    stripHostname(url) {
      const a = document.createElement('a');
      a.href = url;
      console.log(a, a.path, a.search, a.hash);
      return a.pathname + a.search + a.hash;
    },
    action(file) {
      if (this.isPreviewable(file)) {
        window.open(this.previewLink(file), '_blank');
        return;
      }
      this.download(file);
    },
    previewLink(file) {
      return '/preview' + this.stripHostname(file.url);
    },
    isPreviewable(file) {
      return file.is_previewable;
    },
    async download(file) {
      if (this.isPreviewable(file)) {
        return;
      }
      console.log(`Downloading ${file.download_link}`);
      CoreApiArrayBuffer.get(file.download_link)
          .then(response => {
            console.log(response);
            let blob = new Blob([response.data], { type: response.headers['Content-Type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = file.name;
            link.click();
          });
    },
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>