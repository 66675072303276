<template>
  <span v-if="user" class="user" :class="{'user-deleted': user ? user.is_deleted : false}">
    {{ user ? user.name : '#' + user.id }}
  </span>
</template>

<script>
export default {
  name: "User",
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>