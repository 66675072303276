const highlight = function (words, query) {
    if (query && words) {
        const iQuery = new RegExp(query, "ig");
        return words.toString().replace(iQuery, function (matchedTxt) {
            return ('<mark class="highlight">' + matchedTxt + '</mark>');
        });
    } else {
        return words;
    }
}

const Filters = {
    install(Vue) {
        Vue.filter('highlight', highlight)
    }
};

export default Filters