<template>
  <form class="comment-create" @submit.prevent="saveComment" @keyup.ctrl.enter="saveComment">
<!--    <div class="alert alert-danger mb-1 mt-1" v-show="errors.length" v-for="(error, fieldId) of errors" :key="fieldId">-->
<!--      {{ error }}-->
<!--    </div>-->
    <div class="form-group">
      <textarea class="form-control" id="text" v-model="text" rows="3" :disabled="saving" placeholder="Комментарий"></textarea>
    </div>
    <div class="form-group">
      <button type="button" class="btn btn-primary" :disabled="saving" @click="saveComment">
        {{ saving ? "Отправляем..." : "Отправить (Ctrl+Enter)" }}
      </button>
    </div>
  </form>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'CommentCreate',
    data: () => ({
      text: ''
    }),
    computed: {
      ...mapGetters('auth', ['userData']),
      ...mapGetters('task', ['task', 'saving'])
    },
    methods: {
      ...mapActions('notifications', ['readTaskCommentsNotifications']),

      saveComment () {
        const data = {
          task_id: this.task.id,
          user_id: this.userData.id,
          text: this.text
        };

        this.readTaskCommentsNotifications(this.task.id);

        this.$store.dispatch('task/comment', data)
            .then(() => {
              this.text = null
            })
            .catch(() => {
            })
      }

    }
  }
</script>

<style scoped>

</style>
