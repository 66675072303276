<template>
  <div class="form-group attachment-create">
    <loading-bar :loading="saving"/>

    <b-form-file v-model="attachment" @change="upload($event)" multiple :disabled="saving"
                 drop-placeholder="Перетащите файлы сюда..."
                 ref="file-control" placeholder="Выберите или перетащите сюда файлы"/>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import LoadingBar from "../../LoadingBar/LoadingBar.vue";

  export default {
    name: "AttachmentCreate",
    components: {LoadingBar},
    props: ["task"],
    data: () => ({
      attachment: null
    }),
    computed: {
      ...mapGetters("auth", ["userData"]),
      ...mapGetters("task", ["saving"])
    },
    methods: {
      upload(event) {
        const target = event.target;
        target.files.forEach(file => {
          const data = new FormData();
          data.append("task_id", this.task.id);
          data.append("user_id", this.userData.id);
          data.append("attachment", file);

          const fileControl = this.$refs['file-control'];
          this.$store.dispatch("task/attach", data)
            .then(function () {
              fileControl.reset();
            })
            .catch((errors) => {
              errors.forEach(error => {
                this.$notify({
                  icon: "fa fa-exclamation",
                  group: "AttachmentCreate",
                  title: error,
                  type: "danger"
                });
              });
            });
        })
      }
    }

  };
</script>

<style scoped>

</style>
