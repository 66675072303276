<template>
  <ul class="comment-list">
    <comment-item v-for="comment of comments" :comment="comment" :key="comment.id"/>
    <li class="mt-4 comment-create-item">
      <comment-create/>
    </li>
  </ul>
</template>

<script>
  import {mapGetters} from 'vuex'
  import CommentItem from './CommentItem/CommentItem.vue'
  import CommentCreate from '../CommentCreate/CommentCreate.vue'

  export default {
    name: 'CommentList',
    components: {
      CommentCreate,
      CommentItem
    },
    computed: mapGetters('task', ['comments'])
  }
</script>

<style scoped>
</style>
