<template>
  <div class="page" v-if="canCreate">
    <form @submit.prevent="saveTask" @keyup.ctrl.enter="saveTask" ref="createTaskForm">
      <notifications></notifications>
      <div class="card">
        <loading-bar :loading="loading"></loading-bar>

        <div class="card-header">
          <h4 class="card-title" v-if="isCreatingSubtask">
            {{ createSubtaskLabel }} в «<router-link :to="parentTaskPath">{{ parentTask.subject }}</router-link>»
          </h4>
          <h4 class="card-title" v-else>
            {{ createTaskLabel }}
          </h4>

          <div class="card-category" v-if="category">
            <breadcrumb>
              <breadcrumb-item v-if="category.set">
                {{ category.set.title }}
              </breadcrumb-item>
              <breadcrumb-item v-if="category.parent && category.parent.parent">
                <router-link :to="`/category/${category.parent.parent.id}`" class="text-muted">
                  {{ category.parent.parent.title }}
                </router-link>
              </breadcrumb-item>
              <breadcrumb-item v-if="category.parent">
                <router-link :to="`/category/${category.parent.id}`" class="text-muted">
                  {{ category.parent.title }}
                </router-link>
              </breadcrumb-item>
              <breadcrumb-item v-if="category">
                <router-link :to="`/category/${category.id}`" class="text-muted">
                  {{ category.title }}
                </router-link>
              </breadcrumb-item>
            </breadcrumb>
          </div>
        </div>

        <div class="card-body" v-if="category">

          <div class="alert alert-danger mb-1 mt-1" v-show="errors.length" v-for="(error, fieldId) of errors"
               :key="fieldId">
            {{ error }}
          </div>

          <!-- Заголовок -->
          <div class="form-group">
            <label for="subject" class="required">
              {{ systemFieldsLabels['subject'] }}
            </label>
            <fg-input v-model="subject" name="subject"
                      tabindex="-1"
                      id="subject" :placeholder="systemFieldsLabels['subject']"
                      autofocus :required="true"/>
          </div>

          <div class="form-group" v-for="(row, index) of category.rows" :key="index">
            <div v-if="'system_column' === row.type">

              <!-- Отдел -->
              <div v-if="'department_id' === row.name">
                <label for="department_id" class="required">
                  {{ systemFieldsLabels['department_id'] }}
                </label>

                <select v-model="department_id"
                        :class="{'custom-select': true}"
                        size="1"
                        :placeholder="systemFieldsLabels['department_id']"
                        name="department_id"
                        :required="true"
                        :readonly="readonlyDepartment"
                        :disabled="readonlyDepartment"
                        :tabindex="index"
                >
                  <option v-for="dep of departments" :value="dep.id" :key="dep.id" :selected="department_id && department_id === dep.id">
                    <span v-html="'&nbsp;&nbsp;'.repeat(dep.level)"/>
                    {{ dep.name }}
                  </option>
                </select>
              </div>

              <!-- Описание -->
              <div v-if="'brief' === row.name && withBrief">
                <label for="brief">
                  {{ systemFieldsLabels['brief'] }}
                </label>
                <wysiwyg v-model="brief" name="brief" placeholder="Описание"
                         :tabindex="index"/>
              </div>

              <!-- Срок -->
              <div v-if="'expired_at' === row.name && withPeriod">
                <label for="expired_at" class="required">
                  {{ systemFieldsLabels['expired_at'] }}
                </label>
                <el-date-picker tabindex="2" v-model="expired_at" name="expired_at" type="datetime"
                                :picker-options="{firstDayOfWeek: 1}"
                                placeholder="Выберите дату и время"
                                :format="'dd.MM.yyyy HH:mm'" :required="true">
                </el-date-picker>
              </div>

              <!-- Исполнитель -->
              <div v-if="'performer' === row.name && withPerformer">
                <label for="performer_id" class="required">
                  {{ systemFieldsLabels['performer'] }}
                </label>
                <user-select v-model="performer"
                             :tabindex="index"
                             type="performer"
                             :category-id="category.id"
                             :placeholder="systemFieldsLabels.performer"
                             name="performer_id"
                             :required="true"
                             :readonly="false"/>
              </div>

              <!-- Наблюдатели -->
              <div v-if="'spectators' === row.name && withSpectators" class="spectators">
                <label>
                  {{ systemFieldsLabels['spectators'] }}:
                </label>
                <ul class="list-inline inline-input">
                  <li v-for="(spectator, index) of spectators" :key="spectator.id" class="d-inline-block">
                    <div class="d-flex">
                      <user-select
                          v-model="spectators[index]"
                          type="spectators"
                          :category-id="category.id"
                          :placeholder="systemFieldsLabels.spectators"
                          name="spectators[]"
                          :readonly="false"/>
                      <b-btn pill variant="link"  @click="removeSpectator(index)" style="padding-bottom: 5px">
                        <font-awesome-icon icon="trash"/>
                      </b-btn>
                    </div>
                  </li>
                  <li class="d-inline-block mr-2" v-if="canAddNextSpectator">
                    <div class="d-flex">
                      <b-btn pill size="sm" variant="link" @click="addSpectator" style="padding-bottom: 0">
                        <font-awesome-icon icon="plus"/>
                      </b-btn>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else-if="'field' === row.type">
              <field-item-new
                  :tabindex="index"
                  :predefined-value="(subtaskRuleId && parseInt(getFieldByName(row.name).subtask_rule_id) === parseInt(subtaskRuleId)) ? parseInt(parentTask.id) : null"
                  :key="getFieldByName(row.name).id" :field="getFieldByName(row.name)"
                  @change="pushData"/>
            </div>
          </div>

          <!-- Вложение -->
          <div class="form-group mt-3" v-if="!category.disable_attachments &&
          !category.disable_attachments_on_creation">
            <label for="attachments">
              {{ systemFieldsLabels['attachment'] }}
            </label>
            <div class="form-group attachment-create">
              <b-form-file
                :tabindex="category.fields.length + 5"
                multiple
                no-drop
                drop-placeholder="Перетащите файлы сюда..."
                id="attachments" name="attachments" placeholder="Выберите файл..." :disabled="saving"/>
            </div>
          </div>

        </div>

        <div class="card-footer" v-show="category && !loading">
          <button :tabindex="category.fields.length + 6" class="btn btn-primary" type="submit" :disabled="saving">
            {{ saving ? "Сохраняем..." : "Сохранить" }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <!--	<alert v-else-if="canCreate === false" class="alert alert-danger">-->
  <!--		<span>У вас недостаточно прав для создания задач в данной категории.</span>-->
  <!--	</alert>-->

</template>

<script>
  import {mapGetters} from "vuex";
  import moment from "moment";
  import _ from "lodash";
  import FieldItemNew from "./FieldItemNew/FieldItemNew.vue";
  import LoadingBar from "../LoadingBar/LoadingBar";
  import BreadcrumbItem from "../Breadcrumb/BreadcrumbItem";
  import Breadcrumb from "../Breadcrumb/Breadcrumb";
  import UserSelect from '../UserSelect/UserSelect.vue'

  export default {
    name: "TaskCreate",
    components: {
      Breadcrumb,
      BreadcrumbItem,
      LoadingBar,
      FieldItemNew,
      UserSelect
    },
    data: () => ({
        subject: null,
        brief: null,
        expired_at: null,
        department_id: null,
        fieldValues: {},
        parentTask: {},
        subtaskRule: {},
        performer: {},
        creator: {},
        spectators: [],
      }
    ),
    computed: {
      ...mapGetters("auth", ["userData"]),
      ...mapGetters("categories", [
        "category",
        "permissions",
        "hasAnyPermission",
        "hasPermissionToSee",
        "hasPermissionToView",
        "hasPermissionToCreate",
        "hasPermissionToPerform",
        "hasPermissionToChangeCreator",
        "hasPermissionToChangePerformer",
        "categoryDepartments"
      ]),
      ...mapGetters("users", {
        users: "collection",
        find: "find"
      }),
      ...mapGetters("task", ["errors", "saving", "loading"]),
      subtaskRuleId: state => undefined !== state.$route.params.subtaskRuleId ? parseInt(state.$route.params.subtaskRuleId) : null,
      parentTaskId: state => undefined !== state.$route.params.parentTaskId ? parseInt(state.$route.params.parentTaskId) : null,
      categoryId: state => parseInt(state.$route.params.categoryId),
      taskCategoryId: state =>
          (_.has(state.$route.params, 'parentTaskId') && state.$route.params.parentTaskId &&
            _.has(state.$route.params, 'subtaskRuleId') && state.$route.params.subtaskRuleId &&
            undefined !== state.subtaskRule.subtask_category_id)
          ? state.subtaskRule.subtask_category_id : state.categoryId,
      departments: state => state.categoryDepartments(state.taskCategoryId),
      readonlyDepartment: state => state.departments && state.departments.length === 1,
      parentTaskPath: state => state.parentTask ? `/category/${state.parentTask.category_id}/task/${state.parentTask.id}` : '',
      systemFieldsLabels: state => state.category ? state.category.labels : {},
      canCreate: state => state.category && 'TASK_CREATION_METHOD_AUTO' !== state.category.task_creation_method &&
        state.hasPermissionToCreate,
      withBrief: state => state.category.without_brief !== true,
      withPeriod: state => state.category.auto_period !== true
        || (state.category.default_period === null && state.category.default_period_days === null && state.category.default_period_hours === null && state.category.default_period_minutes === null),
      withPerformer: state => state.category.auto_performer !== true
        || state.category.default_performer_id === null,
      withSpectators: state => !!_.find(state.category.rows, {name: 'spectators'}),
      performerIsRequired: state => state.category && !state.category.without_performer,
      now: () => {
        const date = new Date();
        return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
      },
      createTaskLabel: state => {
        if (!state.category) {
          return null;
        }
        return state.category.create_task_label || 'Новая задача';
      },
      createSubtaskLabel: state => {
        if (!state.category) {
          return null;
        }
        return state.category.create_subtask_label || 'Новая подзадача';
      },
      isCreatingSubtask: state => state.parentTask && state.parentTask.subject,

      canAddNextSpectator: state => {
        if (state.spectators.length === 0) {
          return true
        }

        return state.spectators.length && state.spectators[state.spectators.length - 1].id !== null
      },
    },
    methods: {
      parseInt(number) {
        return parseInt(number)
      },

      addSpectator() {
        this.spectators.push({id: null})
      },

      removeSpectator(index) {
        this.spectators.splice(index, 1)
      },

      /**
       * Submit form and save a task
       */
      saveTask() {

        // Clear previous errors
        const elements = document.getElementsByClassName('required');
        for (const elem of elements) {
          if (elem.nodeName !== 'LABEL') {
            elem.classList.remove('with-error');
          }
        }

        const data = new FormData();
        if (this.category.id) {
          data.append("category_id", this.category.id);
        }
        if (this.userData.id) {
          data.append("creator_id", this.userData.id);
        }
        if (this.performer.id) {
          data.append("performer_id", this.performer.id);
        }
        if (this.departments.length === 1) {
          this.department_id = this.departments[0].id
        }
        if (this.department_id) {
          data.append("department_id", this.department_id);
        }
        if (this.spectators) {
          for (const spectator of this.spectators) {
            if (spectator.id) {
              data.append("spectators_ids[]", spectator.id);
            }
          }
        }
        if (this.subject) {
          data.append("subject", this.subject);
        }
        if (this.withBrief) {
          data.append("brief", this.brief);
        }
        if (this.expired_at) {
          data.append("expired_at", moment(this.expired_at)
            .format("YYYY-MM-DD HH:mm:ss"));
        }
        if (this.parentTaskId && this.subtaskRuleId) {
          data.append("parent_task_id", this.parentTaskId);
          data.append("subtask_rule_id", this.subtaskRuleId);
        }
        const files = document.getElementById("attachments") ? document.getElementById("attachments").files : null;
        if (!this.category.disable_attachments && !this.category.disable_attachments_on_creation && files.length) {
          const filesCount = files.length;

          if (10 < filesCount) {
            this.$notify({
              group: 'TaskCreate',
              message: 'Нельзя прикрепить больше 10 вложений сразу',
              type: 'danger',
              icon: "fa fa-times",
            });
            return;
          }

          let filesTotalSize = 0;
          for (const file of files) {
            filesTotalSize += file.size;
          }
          const filesTotalSizeInKiloBytes = Math.round(filesTotalSize / 1024 / 1024);

          if (20 < filesTotalSizeInKiloBytes) {
            this.$notify({
              group: 'TaskCreate',
              message: 'Суммарный вес вложений не должен превышать 20 мегабайт',
              type: 'danger',
              icon: "fa fa-times",
            });
            return;
          }

          for (let i = 0; i < filesCount; i++) {
            data.append("attachments[]", document.getElementById('attachments').files[i]);
          }
        }

        for (const field of this.category.fields) {

          let value = this.fieldValues[field.id];
          if (value) {
            if (field.type === "FIELD_TYPE_CHECKLIST" && typeof value === "object") {
              value = JSON.stringify(value);
            } else if (field.type === "FIELD_TYPE_DATE") {
              value = this.$moment(value).format('YYYY-MM-DD');
            }
            data.append(`data[${field.id}]`, value);
          }
        }

        const self = this;
        self.$store.dispatch("task/save", data)
          .then(task => {
            self.$notify({
              icon: "fa fa-check",
              group: "TaskCreate",
              title: "Задача создана",
              type: "success"
            });
            self.$router.push({
              name: "TaskView",
              params: {
                taskId: task.id,
                categoryId: task.category_id
              }
            });
          })
          .catch(({error, validationErrors}) => {
            console.log({error, validationErrors});
            if (validationErrors && 'string' !== typeof validationErrors) {
              for (let fieldName in validationErrors) {
                const errorMessages = validationErrors[fieldName];
                if (undefined !== errorMessages) {
                  error = errorMessages.join(' ');
                }
                const element = document.querySelector(`[name="${fieldName}"]`);
                if (element) {
                  element.classList.add('with-error');
                }

                self.$notify({
                  icon: "fa fa-times",
                  group: "TaskCreate",
                  title: error,
                  type: "danger"
                });
              }
            } else {
              self.$notify({
                icon: "fa fa-times",
                group: "TaskCreate",
                title: error,
                type: "danger"
              });
            }
          });
      },

      /**
       * Push data into field values
       */
      pushData({id, value}) {
        // console.log('Pushing data', id, value);
        this.fieldValues[id] = value;
      },

      getFieldByName(fieldName) {
        return _.find(this.category.fields, {name: fieldName});
      },
    },

    async mounted() {
      // Fetch category info
      // Fetch parent task info
      let category;
      this.$store.dispatch(`categories/reset`)
      if (_.has(this.$route.params, 'parentTaskId') && this.$route.params.parentTaskId &&
        _.has(this.$route.params, 'subtaskRuleId') && this.$route.params.subtaskRuleId
      ) {
        await this.$store.dispatch(`task/fetch`, this.parentTaskId)
          .then(task => this.parentTask = task);
        await this.$store.dispatch(`categories/fetchSubtaskRule`, this.subtaskRuleId)
          .then(subtaskRule => this.subtaskRule = subtaskRule);
      }

      category = await this.$store.dispatch(`categories/fetch`, this.taskCategoryId);
      this.$store.dispatch('categories/fetchCategoryDepartments', this.taskCategoryId)
        .then(() => {
          if (this.departments.length === 1) {
            this.department_id = this.departments[0].id
          }
        })
        .catch(error => {
          console.error(error)
        })

      if (this.isCreatingSubtask && category.create_subtask_label) {
        document.title = category.create_subtask_label;
      } else if (!this.isCreatingSubtask && category.create_task_label) {
        document.title = category.create_task_label;
      } else {
        document.title = !this.isCreatingSubtask ? 'Cоздание задачи' : 'Создание подзадачи';
      }
      // Set fieldValues empty
      for (const field in category.fields) {
        this.fieldValues[field.id] = null;
      }
    }
  };
</script>
