<template>
  <div class="attachments-table-wrapper">
    <button class="btn btn-link download-all-button"
            @click.prevent="downloadAll"
            v-show="attachments.length">
      <font-awesome-icon icon="download"/>
      <span style="padding-left: 6px">Скачать все</span>
    </button>

    <el-table :data="attachmentsSorted" empty-text="Нет файлов" class="table attachment-table">
      <el-table-column label="Превью" :class-name="'col-2 text-center'">
        <template slot-scope="{row}">
          <PreviewLink :file="row" target="_blank">
            <preview :src="row.url" class="mr-3 attachment-preview" :alt="row.name" v-if="row.is_image"/>
            <font-awesome-icon :icon="icon(row)" v-if="!row.is_image" size="3x"/>
          </PreviewLink>
        </template>
      </el-table-column>
      <el-table-column label="Имя">
        <template slot-scope="{row}">
          <DownloadLink :file="row" target="_blank" class="download-link">{{ row.name }}</DownloadLink>
        </template>
      </el-table-column>
      <el-table-column label="Размер">
        <template slot-scope="{row}">
          {{ filesize(row.filesize) }}
        </template>
      </el-table-column>
      <el-table-column label="Дата">
        <template slot-scope="{row}">
          {{ formatTime(row) }}
        </template>
      </el-table-column>
      <el-table-column label="Загрузил">
        <template slot-scope="{row}">
          {{ row.user ? row.user.name : "--" }}
        </template>
      </el-table-column>
      <el-table-column label="">
        <template slot-scope="{row}">
          <a href="#" class="btn btn-sm btn-link float-right" v-if="row.user.id === userId"
             @click.prevent="remove(row.id)">
            <font-awesome-icon icon="trash"/>
          </a>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import Preview from "@/pages/Preview";
import {CoreApiArrayBuffer} from "@/lib/HttpApi"
import DownloadLink from "@/components/Links/DownloadLink";
import PreviewLink from "@/components/Links/PreviewLink";

export default {
  name: "AttachmentTable",
  components: {PreviewLink, DownloadLink, Preview},
  props: ["task"],
  computed: {
    ...mapGetters("auth", ["userId"]),
    ...mapGetters("task", ["attachments"]),
    attachmentsSorted: state => _.uniqBy(state.attachments.sort((a, b) => a.created_at > b.created_at ? -1 : 1), 'id')
  },
  methods: {

    /**
     * Attachment file size
     */
    filesize(kbytes) {
      return this.$filesize(kbytes * 1024);
    },

    /**
     * Icon
     */
    icon(file) {
      if (file.type.indexOf("video") === 0) {
        return "file-video";
      } else if (file.type.indexOf("image") === 0) {
        return "file-image";
      } else {
        switch (file.type) {
          case "application/msword":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return "file-word";
          case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return "file-excel";
          case "application/vnd.ms-powerpoint":
          case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            return "file-powerpoint";
          case "application/pdf":
            return "file-pdf";
          default:
            return "file-alt";
        }
      }

    },

    /**
     * Remove attachment
     */
    remove(attachmentId) {
      console.log(`Removing attachment ${attachmentId}`);
      this.$store.dispatch("task/deleteAttachment", attachmentId)
          .then(() => {
            this.$notify({
              icon: "fa fa-check",
              group: "TaskCreate",
              title: "Файл удалён",
              type: "success"
            });
          })
          .catch(() => {
            this.$notify({
              icon: "fa fa-times",
              group: "TaskCreate",
              title: "Не удалось удалить файл	",
              type: "danger"
            });
          });
    },

    /**
     * Download every attachment
     */
    async downloadAll() {
      console.log(`Downloading all attachments...`);
      for (const attachment of this.attachmentsSorted) {
        console.log(`Downloading ${attachment.download_link}`);
        CoreApiArrayBuffer.get(attachment.download_link)
            .then(response => {
              console.log(response);
              let blob = new Blob([response.data], { type: response.headers['Content-Type'] });
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = attachment.name;
              link.click();
            });
        await this.sleep(500);
      }
    },

    /**
     * Pause before next download
     */
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    /**
     * Format time
     */
    formatTime(attachment) {
      const diff = this.$moment(attachment.created_at).diff(this.$moment());
      if (diff > 60 * 60 * 24) {
        return this.$moment(attachment.created_at).format('L HH:mm');
      }
      if (diff > 0) {
        return this.$moment(attachment.created_at).format('HH:mm');
      }
      return this.$moment(attachment.created_at).fromNow();
    },
  }
};
</script>
