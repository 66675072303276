import _ from 'lodash'
import {CoreApi} from '../../lib/HttpApi'

const USER_FETCH = 'USER_FETCH'
const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS'
const USER_FETCH_FAILED = 'USER_FETCH_FAILED'
const USERS_FETCH = 'USERS_FETCH'
const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS'
const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED'
const USERS_RESET = 'USERS_RESET'

const state = {
  errors: [],
  collection: [],
  loaded: {},
  isLoaded: false,
  hasMore: null,
  loading: false,
  allRecordsFetched: false
}

const getters = {
  users: state => state.collection,
  collection: state => state.collection,

  user: state => state.loaded,
  loaded: state => state.loaded,
  loading: state => state.loading,
  isLoaded: state => state.isLoaded,
  hasMore: state => state.hasMore,
  allRecordsFetched: state => state.allRecordsFetched,

  find: state => id => state.collection.find(user => user.id === id)
}

const actions = {

  /**
   * @param commit
   * @param query
   * @param params
   */
  fetch: ({commit}, query) => {
    console.log(`Fetch users by query: ${query}`);
    commit(USERS_FETCH)
    return new Promise((resolve, reject) => {
      CoreApi.get(`/users?query=${query}`)
        .then(response => {
          const responseData = response.data;
          if (responseData) {
            const {data, meta} = responseData;
            commit(USERS_FETCH_SUCCESS, {query, data, meta})
            resolve({data, meta})
          }

        })
        .catch(({response}) => {
          commit(USERS_FETCH_FAILED, {errors: response.data.errors})
          reject(response.data.errors)
        })
    });
  },

  /**
   * Fetch one user
   * @param commit
   * @param id
   * @returns {Promise<any>}
   */
  find: ({commit}, id) => {
    // console.log('Find user')
    commit(USER_FETCH)
    return new Promise((resolve) => {
      CoreApi.get(`/user/${id}`)
        .then(response => {
          commit(USER_FETCH_SUCCESS, {user: response.data})
          resolve(response.data)
        })
    })
  }

}

const mutations = {

  [USER_FETCH](state) {
    state.errors = []
    state.loading = true
    // console.debug(USER_FETCH, state)
  },

  [USER_FETCH_SUCCESS](state, {user}) {
    state.errors = []
    state.collection.push(user)
    state.loaded = user
    state.loading = false
    // console.debug(USER_FETCH_SUCCESS, state, {user})
  },

  [USER_FETCH_FAILED](state, {errors}) {
    state.errors = [...errors]
    state.loading = false
    // console.debug(USER_FETCH_FAILED)
  },

  [USERS_FETCH](state) {
    state.errors = [];
    state.collection = [];
    state.isLoaded = false;
    state.hasMore = null;
    state.loading = true;
    state.allRecordsFetched = false;
    // console.debug(USERS_FETCH, state)
  },

  [USERS_FETCH_SUCCESS](state, {query, data, meta}) {
    state.errors = [];
    state.isLoaded = false;
    state.loading = false;
    state.hasMore = true;
    let collection = [...data];
    // If records count matches with total count on empty query
    if (_.has(meta, 'total_count') && _.has(meta, 'count') && meta.total_count <= meta.count) {
      if (query === '' || query === null) {
        state.allRecordsFetched = true;
      }
      state.hasMore = false;
    }

    if ((query === '' || query === null) && !state.allRecordsFetched) {
      collection = [];
    }

    state.collection = [...collection];

    // console.debug(USERS_FETCH_SUCCESS, state, {collection})
  },

  [USERS_FETCH_FAILED](state, {errors}) {
    state.errors = [...errors]
    state.collection = []
    state.isLoaded = false
    state.loading = false
    // console.debug(USERS_FETCH_FAILED)
  },

  [USERS_RESET](state) {
    state.errors = [];
    state.collection = [];
    state.isLoaded = false;
    state.hasMore = null;
    state.loading = false;
    state.allRecordsFetched = false;
    console.debug(USERS_RESET)
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
