<template>
  <div class="task-row-attribute" @dblclick="onEdit" @keyup.esc="onCancel" @keyup.ctrl.enter="onSave">
    <edit-button-group
        v-show="showEditButtons()"
        :editing="editing"
        :saving="saving"
        :on-save="onSave"
        :on-edit="onEdit"
        :on-cancel="onCancel"/>

    <div v-if="attribute === 'creator' && task.creator" class="creator">
      <User
          :user="task.creator" v-show="!editing"/>

      <UserSelect
          v-if="!readonly"
          v-show="editing"
          class="inline-input"
          v-model="creator"
          @input="changeCreator"
          type="creator"
          :category-id="task.category.id"
          :placeholder="systemFieldsLabels.creator"
          name="creator_id"
          :required="true"
          :readonly="false"/>
    </div>

    <div v-else-if="'performer' === attribute" class="performer">
      <div class="" v-show="!editing">
        <User
            v-if="task.performer"
            :user="task.performer"/>
        <button
            v-if="performerIsAssignable && hasPermissionToPerform"
            :disabled="saving"
            class="btn btn-primary btn-sm assign-to-me-btn" @click="assignToMe">
          Взять на себя
        </button>
      </div>
      <user-select
          v-if="!readonly"
          v-show="editing"
          class="inline-input"
          v-model="performer"
          @input="changePerformer"
          type="performer"
          :category-id="task.category.id"
          :placeholder="systemFieldsLabels.performer"
          name="performer_id"
          :required="true"
          :readonly="false"/>
    </div>

    <div v-else-if="'spectators' === attribute" class="spectators">
      <div class="" v-show="!editing">
        {{ spectatorsList }}
      </div>
      <div class="" v-if="!readonly" v-show="editing">
        <ul class="py-2 list-inline inline-input">
          <li v-for="(spectator, index) of spectators" :key="spectator.id" >
            <div class="d-flex">
              <user-select
                  v-model="spectators[index]"
                  name="spectators[]"
                  class="spectator-select"
                  type="spectators"
                  :category-id="task.category.id"
                  :multiple="false"
                  :readonly="false"
                  @input="changeSpectators"
              />
              <b-btn pill variant="link"  @click="removeSpectator(index)" style="padding-bottom: 5px">
                <font-awesome-icon icon="trash"/>
              </b-btn>
            </div>
          </li>
          <li v-if="canAddNextSpectator">
            <div class="d-flex">
              <b-btn pill variant="link" @click="addSpectator">
                <font-awesome-icon icon="plus"/>
              </b-btn>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div v-else-if="attribute === 'department_id' && task.category.with_department_scope && task.department" class="department">
      <span>{{task.department.name}}</span>
    </div>

    <div v-else-if="'created_at' === attribute" class="task-created">
      {{ task.created_at | moment(getDateFormat(task.created)) }}
    </div>

    <div v-else-if="'closed_at' === attribute" class="task-closed">
      {{ task.closed_at | moment(getDateFormat(task.closed_at)) }}
    </div>

    <div v-else-if="'expired_at' === attribute" class="task-expired">
      <div v-show="!editing">
        {{ task.expired_at | moment(getDateFormat(task.expired_at)) }}
      </div>

      <el-date-picker
          v-show="editing"
          class="inline-input"
          v-model="expiredAt" :clearable="false"
          :picker-options="{firstDayOfWeek: 1}"
          type="datetime" placeholder="Выберите дату и время"
          :format="dateFormat" :required="true"/>
    </div>

    <div v-else-if="'brief' === attribute" class="brief">
      <wysiwyg
          v-if="canEditBrief" v-show="editingBrief" ref="brief"
          v-model="task.brief" name="brief" placeholder="Описание"/>

      <div v-show="!editingBrief && task.brief"
           class="task-brief" v-html="task.brief" v-linkified>
      </div>
    </div>

    <div v-else-if="'current_state' === attribute" class="current_state">
      <span>{{ task.current_state.title }}</span>
    </div>

    <div v-else :class="'field-' + attribute">
      <p>{{ task[attribute] }}</p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UserSelect from '../UserSelect/UserSelect.vue'
import User from "../User";
import EditButtonGroup from "../Controls/EditButtonGroup";
import moment from "moment";
import _ from "lodash";

export default {
  name: "TaskRowAttribute",
  components: {
    EditButtonGroup,
    User,
    UserSelect,
  },
  props: {
    task: Object,
    attribute: String,
    hasPermissionToPerform: Boolean,
    readonly: Boolean,
    compact: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: 'D MMMM YYYY в H:mm'
    },
  },
  data: () => ({
    creator_id: null,
    performer_id: null,
    changingCreator: false,
    changingPerformer: false,
    newCreatorId: null,
    newPerformerId: null,
    creator: null,
    performer: null,
    editingBrief: false,
    savingBrief: false,
    editingExpiredAt: false,
    expiredAt: null,
    spectators: [],
    changingSpectators: false,
    savingSpectators: false,
    departmentId: null,
  }),
  computed: {
    ...mapGetters("auth", ["userId"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("categories", [
      "category",
      "hasPermissionToChangeCreator",
      "hasPermissionToChangePerformer",
      "hasPermissionToChangeSpectators"
    ]),
    ...mapGetters("task", ["saving",]),

    ...mapGetters("auth", ["userId"]),
    iAmCreator: state => state.task.creator_id === state.userId,
    currentState: state => state.task.current_state,
    canEditBrief: state => state.iAmCreator && state.currentState && state.currentState.is_initial,
    canEditExpiredAt: state => {
      if (!state.currentState || state.currentState.is_terminal) {
        return false;
      }

      if ('PERIOD_EDITABLE_FOR_ANYBODY' === state.category.period_editable_for) {
        return true;
      }

      return ('PERIOD_EDITABLE_FOR_CREATOR' === state.category.period_editable_for && state.iAmCreator)
        ||  ('PERIOD_EDITABLE_FOR_PERFORMER' === state.category.period_editable_for && state.iAmPerformer);
    },
    categoryId: state => state.$route.params["categoryId"],
    permissions: state => state.category ? state.category.permissions : [],
    displayFields: state => state.category && state.category.display_fields ? state.category.display_fields : [],
    isDisplayed: state => field => state.displayFields.includes(field),
    openPerformer: state => state.category && state.category.open_performer === true
        && state.iAmPerformer && state.task.current_state.is_initial,
    iAmPerformer: state => state.task.performer_id === state.userId,
    showParents: state => undefined !== state.visibleParents && state.visibleParents.length,
    spectatorsList: state => state.task.spectators.map(spectator => spectator.name).join(', '),
    visibleParents: state => {
      const parents = state.task && state.task.parents ? state.task.parents : [];
      const visibleParents = [];
      for (const parent of parents) {
        if (!state.hasExactTaskRelationField(parent.id)) {
          visibleParents.push(parent);
        }
      }
      return visibleParents;
    },
    systemFieldsLabels: state => state.category ? state.category.labels : {},
    performerIsAssignable: state => state.task && state.task.performer_id !== state.userId && state.task.is_new && state.task.category.without_performer,

    editing() {
      return {
        spectators: this.changingSpectators,
        creator: this.changingCreator,
        performer: this.changingPerformer,
        brief: this.editingBrief,
        expired_at: this.editingExpiredAt,
      }[this.attribute];
    },

    saving() {
      return {
        creator: this.savingCreator,
        performer: this.savingPerformer,
        brief: this.savingBrief,
        expired_at: this.savingExpiredAt,
      }[this.attribute];
    },

    canAddNextSpectator() {
      if (this.spectators.length === 0) {
        return true
      }

      const lastSpectator = this.spectators[this.spectators.length - 1];

      return lastSpectator && lastSpectator.id != null
    },
  },
  methods: {
    getDateFormat(date) {
      if (this.compact && this.isToday(date)) {
        return 'HH:mm'
      }

      return this.dateFormat
    },

    isToday(date) {
      return moment(date).startOf("day").isSame(moment().startOf("day"));
    },

    call(callbacks) {
      const callback = callbacks[this.attribute];
      if (typeof callback !== 'function') {
        console.error('Callback for ' + this.attribute + ' is undefined');
      }

      return callback();
    },

    onSave() {
      return this.call({
        spectators: this.saveNewSpectators,
        creator: this.saveNewCreator,
        performer: this.saveNewPerformer,
        brief: this.saveBrief,
        expired_at: this.saveExpiredAt,
      });
    },

    onEdit() {
      if (!this.showEditButtons()) {
        return ;
      }

      return this.call({
        spectators: this.startChangingSpectators,
        creator: this.startChangingCreator,
        performer: this.startChangingPerformer,
        brief: this.editBrief,
        expired_at: this.editExpiredAt,
      });
    },

    onCancel() {
      return this.call({
        spectators: this.cancelChangingSpectators,
        creator: this.cancelChangingCreator,
        performer: this.cancelChangingPerformer,
        brief: this.cancelBrief,
        expired_at: this.disableEditMode,
      });
    },

    showEditButtons() {
      if (this.readonly) {
        return false
      }

      return {
        spectators: !this.task.is_closed && this.hasPermissionToChangeSpectators,
        creator: !this.task.is_closed && this.hasPermissionToChangeCreator,
        performer: this.openPerformer ||
            (this.task.closed_at === null && this.hasPermissionToChangePerformer),
        brief: this.canEditBrief,
        expired_at: this.canEditExpiredAt,
        created_at: false,
        closed_at: false,
      }[this.attribute];
    },

    hasExactTaskRelationField(parentTaskId) {
      const fieldValues = this.task && this.task.field_values ? this.task.field_values : [];
      let i = 0;
      for (const fieldValue of fieldValues) {
        const field = this.task.fields[i];
        if (field.type === 'FIELD_TYPE_TASK_RELATION' && fieldValue.value && parentTaskId === fieldValue.value.task_id) {
          return true;
        }
        i++;
      }
      return false;
    },

    startChangingCreator() {
      this.creator = this.task.creator;
      this.changingCreator = true;
    },

    changeCreator(creator) {
      if (this.hasPermissionToChangeCreator) {
        this.newCreatorId = creator.id;
      }
    },

    saveNewCreator() {
      if (this.hasPermissionToChangeCreator) {
        this.savingCreator = true;
        this.$store
            .dispatch("task/changeCreator", {
              creator_id: this.newCreatorId,
              task_id: this.task.id
            })
            .then(() => {
            })
            .finally(() => {
              this.newCreatorId = null;
              this.changingCreator = false;
              this.savingCreator = false;
            });
      }
    },

    startChangingPerformer() {
      this.performer = this.task.performer;
      this.changingPerformer = true;
    },

    cancelChangingCreator() {
      this.changingCreator = false
    },

    changePerformer(performer) {
      if (this.openPerformer || this.hasPermissionToChangePerformer) {
        this.newPerformerId = performer.id;
      }
    },

    cancelChangingPerformer() {
      this.changingPerformer = false
    },

    saveNewPerformer() {
      if (this.openPerformer || this.hasPermissionToChangePerformer) {
        this.savingPerformer = true;
        this.$store
            .dispatch("task/changePerformer", {
              performer_id: this.newPerformerId,
              task_id: this.task.id
            })
            .then(() => {
            })
            .finally(() => {
              this.newPerformerId = null;
              this.changingPerformer = false;
              this.savingPerformer = false;
            });
      }
    },

    startChangingSpectators() {
      this.spectators = Object.assign([], this.task.spectators);
      this.changingSpectators = true
    },

    changeSpectators(spectator) {
      this.spectators.push(spectator)
      this.spectators = _.uniq(this.spectators)
    },

    addSpectator() {
      this.spectators.push({id: null})
    },

    removeSpectator(index) {
      this.spectators.splice(index, 1)
    },

    cancelChangingSpectators() {
      this.changingSpectators = false
    },

    saveNewSpectators() {
      if (!this.hasPermissionToChangeSpectators) {
        return
      }
      this.savingSpectators = true;
      this.$store
          .dispatch("task/changeSpectators", {
            spectators_ids: this.spectators.map(spectator => spectator.id).filter(spectator => !!spectator),
            task_id: this.task.id
          })
          .then(() => {
          })
          .finally(() => {
            this.spectators = this.task.spectators;
            this.changingSpectators = false;
            this.savingSpectators = false;
          });
    },

    assignToMe() {
      const self = this;
      if (self.performerIsAssignable) {
        self.$store
            .dispatch("task/assignToMe", {
              task_id: this.task.id
            })
            .then(() => {
            })
            .catch(({error, validationErrors}) => {
              if (validationErrors) {
                for (const field in validationErrors) {
                  const fieldErrorData = validationErrors[field];
                  const error = fieldErrorData.messages.join(' ');
                  const element = document.querySelector(`[name="${field}"]`);
                  element.classList.add('with-error');
                  self.$notify({
                    icon: "fa fa-times",
                    group: "TaskCreate",
                    title: error ? error : 'Ошибка',
                    type: "danger"
                  });
                }
              } else {
                self.$notify({
                  icon: "fa fa-times",
                  group: "TaskCreate",
                  title: error ? error : 'Ошибка',
                  type: "danger"
                });
              }
            });
      }
    },

    /**
     * @param {object} parentTask
     * @param {object} subtaskRule
     * @param {object} subtask
     */
    removeTaskAsSubtask(parentTask, subtaskRule, subtask) {
      if (confirm('Удалить связть с родительской задачей? (Сама задача удалена не будет)')) {
        const self = this;
        self.$store.dispatch("task/removeSubtask", {parentTask, subtaskRule, subtask})
            .then(() => {
              self.$notify({
                icon: "fa fa-check",
                group: "TaskView",
                title: "Задача исключена из списка подзадач",
                type: "success"
              });
            })
            .catch((error) => {
              self.$notify({
                group: "TaskView",
                icon: "fa fa-times",
                title: error,
                type: "danger"
              });
            });
      }
    },

    cancelBrief() {
      this.editingBrief = false;
    },

    editBrief() {
      this.editingBrief = true;
    },

    saveBrief() {
      this.savingBrief = true;
      this.$store.dispatch('task/updateBrief', {
        task_id: this.task.id,
        text: this.task.brief
      }).then(() => {
        this.savingBrief = false;
      }).catch(() => {
        this.savingBrief = false;
      });
      this.editingBrief = false;
    },

    disableEditMode() {
      this.editingExpiredAt = false;
    },

    editExpiredAt() {
      this.expiredAt = this.$moment(this.task.expired_at).toDate();
      this.editingExpiredAt = true;
    },

    saveExpiredAt() {
      this.savingExpiredAt = true;
      if (this.task.expired_at) {
        this.$store.dispatch('task/updateExpiredAt', {
          task_id: this.task.id,
          expired_at: this.$moment(this.expiredAt).format('YYYY-MM-DD HH:mm:ss')
        })
            .then(() => {
              this.task.expired_at = this.$moment(this.expiredAt).toDate();
              this.savingExpiredAt = false;
            })
            .catch();
      } else {
        this.expiredAt = this.$moment(this.task.expired_at).toDate();
        this.savingExpiredAt = false;
      }
      this.editingExpiredAt = false;
    },
  },

  watch: {
    creator_id: "changeCreator",
    performer_id: "changePerformer"
  },

  mounted() {
  }
};
</script>

<style lang="sass">
.row
  position: relative

  .key
    font-size: 80%
    overflow: hidden

    @media (min-width: 770px)
      font-size: 100%
      min-width: 150px

.assign-to-me-btn
  margin-left: 10px
  margin-top: -5px

.pull-left
  float: left

.inner-edit
  margin: -11px 0 0 -13px
  padding-right: 12px
</style>
