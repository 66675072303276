<template>
    <div class="page">
        <div class="card" v-if="loading || activeTasks.length">
            <loading-bar :loading="loading"></loading-bar>
            <div class="card-header pb-3">
                <div class="row">
                    <div class="col">
                        <h4 class="card-title">Выполнение задач</h4>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 pb-3 tab-content"
                 v-for="category of categories"
                 :key="category.id">
                <div v-if="loading || activeTasks.filter(task => task.category_id === category.id).length">
                    <h5 class="category-title pl-3 pt-0">{{ category.title }}</h5>
                    <div class="active-tasks">
                        <tasks-table
                                :tasks="activeTasks.filter(task => task.category_id === category.id)"
                                :default-sort="{prop:'expiring_at', order: 'asc'}"
                                :show-brief-column="false"
                                :show-creator-column="true"
                                :show-performer-column="false"
                                :show-category-column="false"></tasks-table>
                    </div>
                    <div class="card-footer" v-show="!loading && !activePagination.loaded">
                        <button class="btn btn-link btn-block" @click="fetchMoreActiveTasks">Ещё...</button>
                    </div>
                </div>
            </div>
        </div>

        <card class="small text-center" v-else>
            <span class="">Для вас нет активных задач</span>
        </card>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import TasksTable from "../../components/TasksTable/TasksTable";
import _ from "lodash";

export default {
    name: "TasksPerformedByMe",
    components: {
        TasksTable,
        LoadingBar
    },
    data: () => ({
        subscribed: true
    }),
    computed: {
        ...mapGetters("auth", ["userId"]),
        ...mapGetters("tasks", [
            "activeCollection",
            "activePagination",
            "loading"
        ]),
        activeTasks: state => state.activeCollection.filter(task => task.performer_id === state.userId),
        allTasks: state => state.activeCollection,
        categories: state => _.uniqBy(state.allTasks.map(task => task.category), 'id')
    },
    watch: {
        userId: {
            handler: function (userId) {
                if (userId) {
                    this.subscribe(userId);
                }
            },
            initial: true
        }
    },
    methods: {

        /**
         * Subscribe to user updates channels
         */
        subscribe(userId) {
            if (!userId || this.subscribed === userId) {
                return;
            }
            console.log(`Subscribing to user-${userId}-performing`);
            this.$echo.channel(`user-${userId}-performing`)
                    .listen(".TaskCreatedEvent", () => {
                        console.log(`user-${userId}-performing:new`);
                        this.$store.dispatch("tasks/fetchActiveByPerformer").catch();
                    });
            this.subscribed = userId;
        },

        fetchMoreActiveTasks() {
            if (!this.activePagination.loaded) {
                this.$store.dispatch(
                        `tasks/fetchActiveByPerformer`,
                        this.activePagination.currentPage + 1
                ).catch();
            }
        },
    },
    created() {
        this.$store.dispatch("tasks/fetchActiveByPerformer").catch();
        this.subscribe(this.userId);
    }
};
</script>
