<template>
	<li class="dropdown-item  notification-item" :class="{new:!notification.is_read}"
		@mouseover="mouseover(notification)" @mouseout="mouseout">
		<span class="notification-message" v-html="message"></span>
		<span class="notification-time">{{ createdAt(notification) }}</span>
	</li>
</template>
<script>
    import moment from "moment";

    export default {
        name    : "NotificationsDropdownItem",
        props   : ["notification"],
        data    : () => ( {
            hovered: null
        } ),
        computed: {

            message: state => state.notification.message
                              ? state.notification.message
                                     .replace(/\[\[category-(\d+)\|task-(\d+)\|([^\]]+)\]\]/gm, "<a href=\"/category/$1/task/$2\">$3</a>")
                                     .replace(/\[\[category-(\d+)\|([^\]]+)\]\]/gm, "<a href=\"/category/$1\">$2</a>")
                              : ""
        },
        methods : {
            createdAt: notification => moment(notification.created_at)
                .fromNow(),
            mouseover( notification ){
                if( !notification.is_read ) {
                    this.hovered = notification.id;
                    setTimeout(() => {
                        if( this.hovered === notification.id ) {
                            this.markAsRead(notification);
                        }
                    }, 1000);
                }
            },
            mouseout(){
                this.hovered = null;
            },
            markAsRead( notification ){
                this.$store.dispatch("notifications/markAsRead", notification.id).catch();
            }
        }
    };
</script>
