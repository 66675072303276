var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{attrs:{"data":_vm.approvals,"empty-text":"Нет запросов"}},[_c('el-table-column',{attrs:{"label":"Согласует"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.approval_rule ? row.approval_rule.display : "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Запрошено"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(row.is_requested),expression:"row.is_requested"}]},[_c('span',[_vm._v("Запрошено "+_vm._s(_vm._f("moment")(row.requested_at,"D MMM в H:mm")))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!row.is_requested),expression:"!row.is_requested"}]},[(row.approval_rule && row.approval_rule.triggers_manually)?_c('div',[(_vm.isPerformer || _vm.isCreator)?_c('button',{staticClass:"btn btn-block",on:{"click":function($event){return _vm.requestApproval($event, row)}}},[_vm._v("Запросить утверждение")]):_vm._e(),(!_vm.isPerformer && !_vm.isCreator)?_c('span',[_vm._v("Требуется утверждение")]):_vm._e()]):(row.approval_rule && row.approval_rule.triggers_on_transition)?_c('div',[_c('span',[_vm._v("При переходе в статус «"+_vm._s(row.approval_rule.transition.to_state.title)+"»")])]):_c('div',[_c('span',[_vm._v("Требуется утверждение")])])])]}}])}),_c('el-table-column',{attrs:{"label":"Утверждено"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(row.is_requested),expression:"row.is_requested"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(row.is_granted),expression:"row.is_granted"}]},[_c('span',[_vm._v("Утверждено "+_vm._s(_vm._f("moment")(row.granted_at,"D MMM в H:mm")))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!row.is_granted),expression:"!row.is_granted"}]},[(_vm.hasPermissionToGrant(row))?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.grantApproval($event, row)}}},[_vm._v("Утвердить")]):_vm._e(),(!_vm.hasPermissionToGrant(row))?_c('span',[_vm._v("Требуется утверждение")]):_vm._e()])])]}}])}),_c('el-table-column',{attrs:{"label":"Комментарий"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.comment)+" "),_c('em',{directives:[{name:"show",rawName:"v-show",value:(!row.comment),expression:"!row.comment"}],staticStyle:{"color":"gray"}},[_vm._v(_vm._s((row.approval_rule && row.approval_rule.comment_required) ? "обязательно" : "необязательно"))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }