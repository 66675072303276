<template>
    <div class="card" v-if="loggedIn && userData">

        <loading-bar :loading="saving"/>

        <div class="card-header">
            <h4 class="card-title">Редактировать профиль
                <router-link :to="{name:'UserProfile'}" class="btn btn-link btn-xs">Отмена</router-link>
            </h4>
        </div>
        <div class="card-body">
            <fade-render-transition>
                <div class="alert alert-success" v-show="showSuccessMessage"><span>Профиль обновлен</span></div>
            </fade-render-transition>
            <form :disabled="saving" @submit.prevent="updateProfile">

                <div class="row">
                    <div class="col-md-8">

                        <div class="row">
                            <div class="col-md-4">
                                <fg-input type="text"
                                          label="Фамилия"
                                          placeholder="Фамилия"
                                          :disabled="true"
                                          v-model="userData.last_name">
                                </fg-input>
                            </div>
                            <div class="col-md-4">
                                <fg-input type="text"
                                          label="Имя"
                                          placeholder="Имя"
                                          :disabled="true"
                                          v-model="userData.first_name">
                                </fg-input>
                            </div>
                            <div class="col-md-4">
                                <fg-input type="text"
                                          label="Отчество"
                                          placeholder="Отчество"
                                          :disabled="true"
                                          v-model="userData.middle_name">
                                </fg-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <fg-input type="text"
                                          v-if="userData.alias"
                                          label="Отображать как"
                                          :disabled="true"
                                          placeholder="Отображать как"
                                          v-model="userData.alias">
                                </fg-input>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <fg-input type="text"
                                          v-if="userData.department"
                                          label="Отдел"
                                          :disabled="true"
                                          placeholder="Отдел"
                                          v-model="userData.department.name">
                                </fg-input>
                            </div>
                            <div class="col-md-6">

                                <fg-input type="text"
                                          label="Должность"
                                          placeholder="Должность"
                                          :disabled="true"
                                          v-model="userData.title">
                                </fg-input>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-6">
                                <fg-input type="text"
                                          label="Телефон"
                                          placeholder="Телефон"
                                          v-model="userData.phone">
                                </fg-input>
                            </div>

                            <div class="col-md-6">
                                <label for="bd">Дата рождения</label>
                                <el-date-picker format="dd.MM.yyyy" v-model="userData.birth_date" type="date"
                                                placeholder="Выберите дату" id="bd"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <fg-input type="email"
                                          label="Электронная почта"
                                          placeholder="Email"
                                          :disabled="true"
                                          v-model="userData.email">
                                </fg-input>
                            </div>

                            <div class="col-md-6" v-if="userData.telegram_user">
                                <fg-input type="text"
                                          label="Telegram"
                                          placeholder="Telegram"
                                          :disabled="false"
                                          v-model="userData.telegram_user.telegram_username">
                                </fg-input>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <b-form-checkbox v-model="userData.allow_email_notifications">
                                    Разрешить email-уведомления
                                </b-form-checkbox>
                            </div>
                            <div class="col-md-6">
                                <b-form-checkbox v-model="userData.allow_telegram_notifications"
                                                 v-show="userData.telegram_user && userData.telegram_user.telegram_username">
                                    Уведомления в Телеграм
                                    <font-awesome-icon
                                            v-if="userData.telegram_user && userData.telegram_user.telegram_username &&
                                            userData.telegram_user.chat_id === null"
                                            icon="question-circle"
                                            v-b-popover.hover="`Напишите что-нибудь боту @${telegramBotName} чтобы начать получать
                                                       уведомления`"/>
                                </b-form-checkbox>
                            </div>
                        </div>

                        <div class="row">
                            <div :class="{'col-md-9':!changePassword,'col-md-12':changePassword}">
                                <fg-input type="text"
                                          name="password"
                                          label="Новый пароль"
                                          placeholder=""
                                          :disabled="!changePassword"
                                          v-model="password">
                                </fg-input>
                                <em class="small px-1 m-0" v-show="changePassword">Не заполняйте это поле, если хотите
                                    оставить пароль прежним</em>
                            </div>
                            <div class="col-md-3" v-show="!changePassword">
                                <div class="small px-1" style="margin-top: 38px"><a href="#" class="inner"
                                                                                    @click.prevent="showChangePassword">Изменить</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">

                                <div class="form-group has-label">
                                    <label>Фото</label>
                                    <div class="value status-line mb-2" v-if="userData.picture">
                                        <img :src="userData.picture" alt="" class="img-thumbnail img-fluid">
                                    </div>
                                    <b-form-file v-model="picture" id="picture" :disabled="saving"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="btn btn-info btn-fill btn-wd"
                                :disabled="saving" @click.prevent="updateProfile">
                            {{ saving ? "Обновляем данные..." : "Обновить данные"}}
                        </button>

                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";
    import LoadingBar from "../../components/LoadingBar/LoadingBar";
    import FadeRenderTransition from "../../components/Transitions/FadeRenderTransition";
    import _ from "lodash";

    export default {
        components: {
            FadeRenderTransition,
            LoadingBar
        },
        data: () => ({
            showSuccessMessage: false,
            changePassword: false,
            password: "",
            picture: null
        }),
        computed: {
            ...mapGetters("auth", ["userData", "loggedIn"]),
            ...mapGetters("user", ["saving", "user"]),
            telegramBotName: state => state.$telegramConfig.botName
        },
        methods: {
            showChangePassword() {
                this.changePassword = true;
                document.querySelector("[name=password]")
                    .focus();
            },
            updateProfile() {
                const data = new FormData();
                data.append("allow_email_notifications", this.userData.allow_email_notifications ? 1 : 0);
                data.append("allow_telegram_notifications", this.userData.allow_telegram_notifications ? 1 : 0);
                if ( _.has(this.userData, 'telegram_user')
                  && _.has(this.userData.telegram_user, 'telegram_username')
                  && this.userData.telegram_user.telegram_username ) {
                  data.append("telegram_username", this.userData.telegram_user.telegram_username);
                }
                data.append("phone", this.userData.phone);
                if (this.userData.birth_date) {
                    data.append("birth_date", this.$moment(this.userData.birth_date)
                        .format("YYYY-MM-DD"));
                }
                if (this.password) {
                    data.append("password", this.password);
                }
                if (this.picture) {
                    data.append("picture", this.picture);
                }
                this.$store.dispatch("user/update", data)
                    .then(() => {
                        this.password = null;
                        this.picture = null;
                        // refetch auth data
                        // this.$store.dispatch("auth/recoverUser").catch();
                        this.showSuccessMessage = true;
                        setInterval(() => {
                            this.showSuccessMessage = false;
                        }, 3000);
                    })
                    .catch(error => {
                        this.$notify(
                            {
                                component: {
                                    template: `<span>${error}</span>`
                                },
                                icon: "fa fa-times",
                                horizontalAlign: "right",
                                verticalAlign: "top",
                                type: "danger"
                            }
                        );
                    });
            }
        }
    };
</script>
<style>
    .custom-file-label {
        padding: 10px;
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
        padding: 10px;
        content: "Обзор";
    }
</style>
