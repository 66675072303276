<template>
  <el-table :data="approvals" empty-text="Нет запросов">

    <el-table-column label="Согласует">
      <template slot-scope="{row}">
        {{ row.approval_rule ? row.approval_rule.display : "" }}
      </template>
    </el-table-column>

    <el-table-column label="Запрошено">
      <template slot-scope="{row}">

        <!-- Is requested -->
        <div v-show="row.is_requested">
          <span>Запрошено  {{ row.requested_at | moment("D MMM в H:mm") }}</span>
        </div>

        <!-- Is not requested -->
        <div v-show="!row.is_requested">

          <!-- Manual -->
          <div v-if="row.approval_rule && row.approval_rule.triggers_manually">

            <!-- Can request -->
            <button class="btn btn-block" @click="requestApproval($event, row)" v-if="isPerformer || isCreator">Запросить утверждение</button>
            <!-- Can not request -->
            <span v-if="!isPerformer && !isCreator">Требуется утверждение</span>
          </div>

          <!-- Transitional -->
          <div v-else-if="row.approval_rule && row.approval_rule.triggers_on_transition">
            <span>При переходе в статус «{{row.approval_rule.transition.to_state.title}}»</span>
          </div>

          <!-- Auto -->
          <div v-else>
            <span>Требуется утверждение</span>
          </div>

        </div>

      </template>
    </el-table-column>

    <el-table-column label="Утверждено">
      <template slot-scope="{row}">

        <div v-show="row.is_requested">
          <!-- Is granted -->
          <div v-show="row.is_granted">
            <span>Утверждено  {{ row.granted_at | moment("D MMM в H:mm") }}</span>
          </div>

          <!-- Is not granted -->
          <div v-show="!row.is_granted">

            <!-- Can grant -->
            <button class="btn btn-primary" @click="grantApproval($event, row)" v-if="hasPermissionToGrant(row)">Утвердить</button>
            <!-- Can not grant -->
            <span v-if="!hasPermissionToGrant(row)">Требуется утверждение</span>

          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="Комментарий">
      <template slot-scope="{row}">
        {{ row.comment }}
        <em style="color:gray" v-show="!row.comment">{{ (row.approval_rule && row.approval_rule.comment_required) ? "обязательно" : "необязательно" }}</em>
      </template>
    </el-table-column>

  </el-table>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name    : "ApprovalTable",
    props   : ["approvals", "isPerformer", "isCreator"],
    computed: {
      ...mapGetters("auth", ["userData"])
    },

    methods: {

      trigger( approval ){
        if( approval.triggers_manually ) {
          return "Вручную";
        } else if( approval.triggers_on_start ) {
          return "При создании";
        } else if( approval.triggers_on_transition ) {
          return "При переходе";
        } else {
          return approval.approval_rule.trigger;
        }
      },

      hasPermissionToGrant( approval ){
        if( !approval.approval_rule ) {
          return false;
        }
        const type = approval.approval_rule.approver_type;
        const {id, department_id, is_supervisor, roles} = this.userData;
        const approverId = approval.approval_rule.approver_id;
        return (
          ( type === "APPROVAL_RULE_APPROVER_DEPARTMENT" && department_id === approverId ) ||
          ( type === "APPROVAL_RULE_APPROVER_SUPERVISOR" && department_id === approverId && is_supervisor ) ||
          ( type === "APPROVAL_RULE_APPROVER_USER" && id === approverId ) ||
          ( type === "APPROVAL_RULE_APPROVER_ROLE" && approverId in ( roles.length ? roles.map(item => item.id) : [] ) )
        );
      },

      requestApproval( event, approval ){
        const element = event.target;
        this.$store.dispatch("approvals/request", {
              "approval_id": approval.id
            })
            .then(() => {
              element.setAttribute("disabled", true);
              element.innerText = "Запрошено " +  this.$moment().format("D MMM в H:mm");
              element.className = "btn btn-link px-0";
            });
      },

      grantApproval( event, approval ){
        let comment;

        if( approval.approval_rule.comment_required ) {
          comment = prompt("Комментарий");
        }

        if( !approval.approval_rule.comment_required || comment ) {
          const element = event.target;
          const data = {
            approval_id: approval.id,
            comment    : comment
          };
          this.$store.dispatch("approvals/grant", data)
              .then(() => {
                element.setAttribute("disabled", true);
                element.innerText = "Согласовано " +  this.$moment().format("D MMM в H:mm");
                element.className = "btn btn-link px-0";
              });
        }
      }
    }
  };
</script>
