<template>
    <b-tabs class="task-tabs" v-if="category" v-model="taskTabIndex">

        <!-- Comments -->
        <b-tab title="Комментарии" v-if="!category.disable_comments" @click="readTaskCommentsNotifications(task.id)">
            <h5 class="card-title">Комментарии</h5>
            <comment-list v-show="task && task.comments"/>
        </b-tab>

        <!-- Attachments -->
        <b-tab title="Файлы" v-if="!category.disable_attachments" @click="readTaskAttachmentsNotifications(task.id)">
            <h5 class="card-title">Вложения</h5>
            <div class="task-attachments">
                <attachment-table :task="task"/>
                <attachment-create :task="task" class="mt-4"/>
            </div>
        </b-tab>

        <!-- Approvals -->
        <b-tab title="Утверждение" v-if="category.approval_rules && category.approval_rules.length"
               @click="readTaskApprovalsNotifications(task.id)">
            <h5 class="card-title">Утверждение</h5>
            <approval-table :approvals="approvals" :is-performer="isPerformer" :is-creator="isCreator"/>
        </b-tab>

        <!-- Subtasks -->
        <b-tab title="Подзадачи" v-if="subtasks.length">
            <h5 class="card-title">Подзадачи</h5>
            <task-subtasks :subtasks="subtasks" mode="tabbed"/>
        </b-tab>

        <!-- Log -->
        <b-tab title="История">
            <h5 class="card-title">История изменений</h5>
            <task-log :task="task" v-if="task"/>
        </b-tab>

    </b-tabs>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ApprovalTable from "./ApprovalTable/ApprovalTable.vue";
import AttachmentCreate from "./AttachmentCreate/AttachmentCreate.vue";
import AttachmentTable from "./AttachmentTable/AttachmentTable";
import CommentList from "./CommentList/CommentList.vue";
import TaskLog from "./TaskLog/TaskLog.vue";
import TaskSubtasks from "./TaskSubtasks";

export default {
    name: "task-tabs",
    components: {
      ApprovalTable,
      AttachmentCreate,
      AttachmentTable,
      CommentList,
      TaskLog,
      TaskSubtasks
    },
    props: {
      task: Object,
      isCreator: Boolean,
      isPerformer: Boolean,
      subtasks: Array
    },
    data: () => ({
      taskTabIndex: 0
    }),
    computed: {
      ...mapGetters("categories", ["category", "permissions"]),
      approvals: state => state.task ? state.task.approvals : null,
      attachmentsTabIndex: state => {
        if (!state.category || state.disable_attachments) {
          return null;
        }
        return state.category.disable_comments ? 0 : 1;
      },
    },
    methods: {
      ...mapActions("notifications", [
        "readTaskApprovalsNotifications",
        "readTaskAttachmentsNotifications",
        "readTaskCommentsNotifications"
      ]),
      openAttachmentsTab(){
        if (null === this.attachmentsTabIndex) {
          return;
        }
        this.taskTabIndex = this.attachmentsTabIndex
      },
    },
    created() {
      this.$root.$on('openAttachmentsTab', this.openAttachmentsTab);
    }
  };
</script>
``<style>
.task-tabs {
  margin-top: 30px
}
</style>