<template>

  <div v-if="data || data === 0">
    <!-- Check list -->
    <div v-if="field &&  field.type && field.type === 'FIELD_TYPE_CHECKLIST'" class="text-center flex-row">
      <small v-if="data && data.length">{{ data.length }} / {{ field.options.length }}</small>
      <b-progress v-if="data && data.length" :value="100 / field.options.length * data.length" :max="100" class="mb-3"></b-progress>
    </div>

    <!-- Checkbox -->
    <div v-else-if="field &&  field.type && field.type === 'FIELD_TYPE_CHECKBOX'">
      <font-awesome-icon icon="check" v-show="data === true"/>
    </div>

    <!-- Task -->
    <div v-else-if="field &&  field.type && field.type === 'FIELD_TYPE_TASK' && data.subject">
      <router-link :to="{name: 'TaskView', params: {taskId: data.id, categoryId: data.category_id}}">{{ data.subject }}</router-link>
    </div>

    <!-- TaskRelation -->
    <div v-else-if="field && field.type && field.type === 'FIELD_TYPE_TASK_RELATION' && (data.subject || (data.task && data.task.subject))">
      <router-link :to="{name: 'TaskView', params: {taskId: data.task_id || data.task.id, categoryId: data.category_id || data.task.category_id}}">{{
          data.subject || data.task.subject
        }}</router-link>
    </div>

    <!-- User -->
    <div v-else-if="field &&  field.type && field.type === 'FIELD_TYPE_USER'">
      <User :user="data" />
    </div>

    <!-- File -->
    <div v-else-if="field &&  field.type && field.type === 'FIELD_TYPE_FILE'">
      <PreviewLink :file="data" target="_blank" class="word-break">{{ data.name }}</PreviewLink>
      <DownloadLink :file="data" class="btn btn-link btn-small">
        <font-awesome-icon icon="download"/>
        <span class="filesize" style="padding-left: 6px">{{ filesize(data.filesize) }}</span>
      </DownloadLink>
    </div>

    <!-- URL -->
    <div v-else-if="field &&  field.type && field.type === 'FIELD_TYPE_URL'">
      <a :href="data" target="_blank" class="word-break">{{ data }}</a>
    </div>

    <!-- Date -->
    <div v-else-if="field &&  field.type && field.type === 'FIELD_TYPE_DATE'">{{ data | moment("DD.MM.YYYY")}}</div>

    <!-- Number -->
    <div v-else-if="field &&  field.type && field.type === 'FIELD_TYPE_NUMBER'">
      <span class="number">{{ data }}</span>
    </div>

    <!-- Long text -->
    <p v-else-if="field &&  field.type && field.type === 'FIELD_TYPE_LONGTEXT'"
       class="pre-wrap field-longtext"
       v-html="$options.filters.highlight(data, highlightQuery)"
       v-linkified/>

    <!-- Else -->
    <div v-else>
      <span v-html="$options.filters.highlight(data, highlightQuery)"/>
    </div>
  </div>
</template>
<script>
  import User from "../../../User";
  import DownloadLink from "@/components/Links/DownloadLink";
  import PreviewLink from "@/components/Links/PreviewLink";
  export default {
    name   : "field-item-value",
    components: {PreviewLink, DownloadLink, User},
    props  : {
      data: {
        type: [Object, String, Number, Boolean],
        default: ''
      },
      field: {
        type: Object,
        default: () => ''
      },
      hasPermission: {
        type: Boolean,
        default: false
      },
      highlightQuery: {
        type: String,
        default: ''
      },
    },
    methods: {
      filesize( kbytes ){
        return this.$filesize(kbytes * 1024);
      },
    }
  };
</script>
