<template>
    <auth-layout pageClass="login-page">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-lg-4 col-md-6 col-sm-8">
                <form @submit.prevent="login(credentials)">
                    <fade-render-transition>
                        <card v-show="!loading">
                            <div slot="header">
                                <h3 class="card-title text-center">Вход</h3>
                            </div>
                            <div>
                                <fg-input label="Email"
                                          placeholder="Адрес электронной почты"
                                          autocomplete="username"
                                          type="email"
                                          name="email"
                                          :required="true"
                                          v-model="credentials.email">

                                </fg-input>
                                <fg-input label="Пароль"
                                          type="password"
                                          autocomplete="current-password"
                                          name="password"
                                          placeholder="Пароль"
                                          :required="true"
                                          v-model="credentials.password">
                                </fg-input>
                            </div>
                            <div class="text-center">
                                <button @keyup.enter="login(credentials)" class="btn btn-fill btn-info btn-round btn-wd"
                                        :disabled="loading">
                                    {{ loading ? "Входим..." : "Войти" }}
                                </button>
                                <br>
                            </div>
                        </card>
                    </fade-render-transition>

                </form>
            </div>
        </div>
    </auth-layout>
</template>
<script>
import {FadeRenderTransition} from '../components'
import AuthLayout from '../components/Layout/AuthLayout.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
    components: {
        FadeRenderTransition,
        AuthLayout
    },
    data() {
        return {
            credentials: {
                email: '',
                password: ''
            },
            loading: false
        }
    },
    computed: mapGetters('auth', ['loggedIn', 'redirectUrl']),
    activated() {
        if (this.loggedIn) {
            this.$router.push('/')
        }
    },
    methods: {
      ...mapActions("auth", ["resetRedirectUrl"]),

        toggleNavbar() {
            document.body.classList.toggle('nav-open')
        },
        closeMenu() {
            document.body.classList.remove('nav-open')
            document.body.classList.remove('off-canvas-sidebar')
        },

        login(credentials) {
            this.loading = true
            this.$store.dispatch(`auth/authenticate`, credentials)
                    .then(() => {
                        // this.loading = false
                        // this.$router.push('/')
                        const redirectUrl = this.redirectUrl ? this.redirectUrl : '/';
                        this.resetRedirectUrl();
                        location.href = redirectUrl;
                    })
                    .catch(error => {
                        this.loading = false
                        this.loginFailed(error)
                    })
        },

        loginFailed(error) {
            this.$notify(
                    {
                        message: error,
                        icon: "fa fa-times",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    })

            this.error = error
        }
    },
    beforeDestroy() {
        this.closeMenu()
    }
}
</script>
<style>
.navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
}
</style>
