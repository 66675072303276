<template>
    <side-bar>
      <!--<mobile-menu></mobile-menu>-->
      <template slot="links">

        <sidebar-item :link="{name: 'Мои задачи'}" v-if="displayMyTasksBlock">
          <sidebar-item :link="{name: 'Требует реакции', path: '/waiting'}" :counter="counters.inProgress"/>
          <sidebar-item :link="{name: 'Выполнение задач', path: '/working'}"/>
          <sidebar-item :link="{name: 'Контроль задач', path: '/control'}"/>
        </sidebar-item>

        <hr v-if="displayMyTasksBlock">

        <SidebarTree :menu="menu" />

      </template>
    </side-bar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
  import SidebarTree from "../SidebarPlugin/SidebarTree";

  export default {
    components: {SidebarTree},
    computed: {
      ...mapGetters("settings", ["displayMyTasksBlock"]),
      ...mapGetters("menu", ["menu"]),
      ...mapGetters("categories", ["sets", "category"]),
      ...mapGetters("tasks", {taskCounters: "counters"}),
      ...mapGetters("approvals", {approvalCounters: "counters"}),
      counters: (state) => ({
        performedByMe: state.taskCounters.performedByMe,
        createdByMe: state.taskCounters.createdByMe,
        approvedByMe: state.approvalCounters.waitingForMyApproval,
        inProgress: state.taskCounters.inProgress + state.approvalCounters.waitingForMyApproval
      })
    },
    created() {
      // Try to recover User from local storage
      if (!this.loggedIn) {

        // Retrieve user authentication
        this.$store.dispatch(`auth/recoverUser`)
          .then(() => {

            // Get menu (sets and categories)
            // available to user
            this.$store.dispatch("menu/fetch").then().catch();
            // Get the counters c
            this.fetchCountersValues();
          })
          .catch(() => {
            this.setRedirectUrl(this.$route.fullPath);
            this.$router.push("/login");
          });
      } else {

        // Get the counters values
        this.fetchCountersValues();
      }
    },
    methods: {
      ...mapActions("auth", ["setRedirectUrl"]),

      /**
       * Fetch sets  and counters
       * for nav menu
       * and subscribe on user updates
       */
      fetchCountersValues() {
        this.$store.dispatch("tasks/countTasksInProgress").catch();
        this.$store.dispatch("approvals/countMyApprovals").catch();
      },

      hasCategories(set) {
        if (set.categories && set.categories.length > 0) {
          return true;
        }

        let hasCategories = false;
        if (set.children && set.children.length > 0) {
          for (const subSet of set.children) {
            if (this.hasCategories(subSet)) {
              hasCategories = true;
            }
          }
        }

        return hasCategories;
      }
    }
  };
</script>
