<template>
  <div class="task-subject-component">

    <input type="hidden" id="task-link" :value="taskLink">

    <div v-if="task.subject" class="task-title-wrapper">

      <div v-show="!editingSubject">
        <span class="task-subject" v-html="task.subject" @dblclick="copyLinkToClipboard"/>
        <el-tag v-if="task.current_state">{{task.current_state.title}}</el-tag>
      </div>

      <div v-show="editingSubject">
        <input v-show="editingSubject" @keyup.ctrl.enter="saveSubject"
              class="form-control subject-form-control" v-model="task.subject" name="subject" :placeholder="systemFieldsLabels['subject']"/>
      </div>

      <div class="edit-subject-buttons">
        <button v-if="canEditSubject" v-show="editingSubject" @click="saveSubject" class="btn btn-sm btn-primary">
          Сохранить
        </button>

        <button v-if="canEditSubject" v-show="editingSubject" @click="cancelSubject" class="btn btn-sm btn-link">
          Отмена
        </button>

        <button @click="editSubject" v-if="canEditSubject" v-show="!editingSubject" class="btn btn-link btn-sm mx-0">
          <span class="d-none d-sm-inline">Изменить</span>
        </button>

        <button @click="deleteTask" v-show="!editingSubject" v-if="canDelete" class="btn btn-link btn-sm mx-0">
          <span>Удалить</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import {focus} from 'vue-focus';
  import {mapGetters} from "vuex";

  export default {
    name: 'TaskSubject',
    directives: {focus: focus},
    props: {
      task: Object,
      deleteTask: Function,
      canDelete: [Boolean, String],
    },
    data: () => ({
      editingSubject: false
    }),
    computed: {
      ...mapGetters("categories", ["category"]),
      ...mapGetters("auth", ["userId"]),
      iAmCreator: state => state.task.creator_id === state.userId,
      taskLink: state => `${state.task.subject} ${window.location}`,
      currentState: state => state.task.current_state,
      canEditSubject: state => state.iAmCreator && state.currentState && state.currentState.is_initial,
      systemFieldsLabels: state => state.category ? state.category.labels : {},
    },
    methods: {
      cancelSubject() {
        this.editingSubject = false;
      },

      editSubject() {
        this.editingSubject = true;
      },

      saveSubject() {
        this.$store.dispatch('task/updateSubject', {
          task_id: this.task.id,
          text: this.task.subject
        }).catch();
        this.editingSubject = false;
      },

      copyLinkToClipboard() {
        let inputField = document.querySelector('#task-link');
        inputField.setAttribute('type', 'text');
        inputField.select();

        try {
          document.execCommand('copy');
          this.$notify('Ссылка скопирована в буфер обмена');
        } catch (err) {
          this.$notify('Не удалось скопировать ссылку');
        }

        inputField.setAttribute('type', 'hidden');
        window.getSelection().removeAllRanges();
      },
    }
  }
</script>

<style lang="sass">
  .task-title-wrapper
    padding-right: 140px

  .task-subject-component
    position: relative
    min-height: 2em

    .task-subject
      margin: -2px 6px 10px -6px
      color: #333333
      font-weight: 300
      font-size: 22px
      line-height: 25px
      padding: 0 5px
      border-radius: 2px
      border: 1px solid transparent
      z-index: 0

    .el-tag
      margin-top: -2px
      margin-left: 0

    .subject-form-control
      display: inline-block
      width: calc(100% - 160px)
      margin: -1px -6px 10px
      color: #333333
      font-weight: 300
      font-size: 22px
      line-height: 40px
      padding: 10px 5px
      border-radius: 2px
      border: 1px solid lightgray
      z-index: 0

    .edit-subject-buttons
      position: absolute
      right: -9px
      top: 0px
      float: right
      z-index: 1
</style>
