<template>
    <li class="task-field-list-item" v-if="isVisible"
        @dblclick="enableEditMode" @keyup.esc="disableEditMode" @keyup.ctrl.enter="hotSave">
        <b-row>
            <b-col sm="12" md="2" class="key" :class="alignmentClassName">
                <div :class="field.type">{{ field.title }}:</div>
            </b-col>
            <b-col sm="12" md="7" class="value" :class="alignmentClassName">
                <div class="field-value" v-show="!editMode">
                    <div class="value">
                        <field-item-value
                            :highlight-query="highlightQuery"
                            :data="data"
                            :field="field"
                            :has-permission="hasPermission"/>
                    </div>
                </div>
                <div class="edit-field-value"
                     v-if="field.type === 'FIELD_TYPE_CHECKLIST' || (hasPermission && isFillable)" v-show="editMode">
                    <field-item-edit
                            :data="data" :field="field" :value="value" class="mb-0 d-flex align-items-center"
                            :saving="saving" @change="setValue" :disabled="!(hasPermission && isFillable)"/>
                </div>
            </b-col>
            <edit-button-group
                v-show="hasPermission && !readOnly && isFillable && field.type !== 'FIELD_TYPE_CHECKLIST'"
                :custom-class="alignmentClassName"
                @is-editing="updateEditMode"
                :editing="editMode"
                :saving="saving"
                :on-save="edit"
                :on-edit="enableEditMode"
                :on-cancel="disableEditMode"
            />
        </b-row>
    </li>
</template>

<script>
    import {mapGetters} from "vuex";
    import FieldItemValue from "./FieldItemValue";
    import FieldItemEdit from "./FieldItemEdit";
    import EditButtonGroup from "@/components/Controls/EditButtonGroup";

    export default {
        name: "FieldItem",
        components: {
            FieldItemEdit,
            FieldItemValue,
            EditButtonGroup
        },
        props: {
            "task": {
                type: Object,
                default: () => ({})
            },
            "field": {
                type: Object,
                default: () => ({
                    field: {
                        type: null
                    }
                })
            },
            "data": {},
            "readOnly": {
                type: Boolean,
                default: false
            },
            "highlightQuery": {
              type: String,
              default: ''
            },
        },
        data: () => ({
            saving: false,
            editMode: null,
            value: ""
        }),
        computed: {
            ...mapGetters("auth", ["userData"]),

            departments: state => state.users.map(user => user.department),

            alignmentClassName: state => (state.field.type === "FIELD_TYPE_CHECKLIST" || state.field.type === "FIELD_TYPE_LONGTEXT")
                ? "vertical-top" : "vertical-center",

            hasPermission() {
                const {access} = this.field;
                return (access === "FIELD_ACCESS_PERFORMER" && this.task.performer_id === this.userData.id)
                    || (access === "FIELD_ACCESS_CREATOR" && this.task.creator_id === this.userData.id)
                    || access === "FIELD_ACCESS_VIEWER";
            },

            isVisible() {
                return this.field.is_visible;
            },

            isFillable() {
              return this.field.is_fillable;
            }
        },
        methods: {
            setValue({value}) {
                this.value = value;

                if (this.field.type === "FIELD_TYPE_CHECKLIST") {
                    this.edit();
                }
            },

            updateEditMode(editModeValue) {
              this.editMode = editModeValue;
            },

            enableEditMode() {
                if (!this.readOnly && this.hasPermission) {
                    this.editMode = true;
                    this.value = (this.data && typeof this.data === "object" ? this.data.value : this.data);
                }
            },

            initEditMode() {
                this.editMode = (this.field.type === "FIELD_TYPE_CHECKLIST");
            },

            disableEditMode() {
                this.initEditMode();
                this.saving = false;
                // this.value = null;
            },

            /**
             * Save function
             */
            edit() {
                if (this.readOnly){
                  return;
                }

                let value;
                const self = this;

                if (self.field.type === "FIELD_TYPE_CHECKBOX") {
                    value = !!self.value;
                } else if (self.field.type === "FIELD_TYPE_CHECKLIST") {
                    value = JSON.stringify(self.value);
                } else if (self.field.type === "FIELD_TYPE_DATE") {
                    value = self.$moment(self.value).format('YYYY-MM-DD');
                    console.log(value);
                } else {
                    value = self.value;
                }
                const data = new FormData();
                data.append("task_id", self.task.id);
                data.append("field_id", self.field.id);
                data.append("value", value || null);
                self.saving = true;
                self.$store.dispatch("task/updateField", data)
                    .then(() => {
                        self.$store.dispatch("task/silentFetch", self.task.id);
                        self.disableEditMode();
                    },
                      () => {
                        self.disableEditMode();
                        self.$notify({
                          group: "TaskView",
                          icon: "fa fa-times",
                          title: 'Не удалось сохранить значение поля',
                          type: "danger"
                        });
                    });
            },

            /**
             * Save on Ctrl+Enter key press
             */
            hotSave() {
                console.log("Hot save", this.editMode);

                if (this.editMode) {
                    this.edit();
                }
            }
        },
        mounted() {
            this.initEditMode();
        }
    };
</script>

