<template>
	<nav class="navbar navbar-expand-lg ">
		<div class="container-fluid">
			<div class="navbar-minimize mx-0 px-0">
				<button class="btn btn-link btn-round btn-icon d-none d-lg-block" @click="minimizeSidebar">
          <font-awesome-icon :icon="$sidebar.isMinimized ? 'ellipsis-v' : 'bars'"/>
				</button>
			</div>

<!--			<a class="navbar-brand">-->
<!--				{{this.$route.meta.title ? this.$route.meta.title : ""}}-->
<!--			</a>-->

			<button type="button"
					class="navbar-toggler navbar-toggler-right"
					:class="{toggled: $sidebar.showSidebar}"
					aria-expanded="false"
					@click="toggleSidebar">
				<span class="navbar-toggler-bar burger-lines"></span>
				<span class="navbar-toggler-bar burger-lines"></span>
				<span class="navbar-toggler-bar burger-lines"></span>
			</button>
			<div class="collapse navbar-collapse justify-content-end">
				<SearchInput />
				<NotificationsDropdown/>
				<UserBlockDropdown/>
			</div>
		</div>
	</nav>
</template>
<script>
    import NotificationsDropdown from "../Notifications/NotificationsDropdown";
	import UserBlockDropdown from "../UserBlockDropdown";
	import SearchInput from "../SearchInput";

    export default {
        components: {
			SearchInput,
			UserBlockDropdown,
            NotificationsDropdown
        },
        computed  : {
            routeName(){
                const {name} = this.$route;
                return this.capitalizeFirstLetter(name);
            }
        },
        data(){
            return {
                activeNotifications: false
            };
        },
        methods   : {
            toggleNotificationDropDown(){
                this.activeNotifications = !this.activeNotifications;
            },
            closeDropDown(){
                this.activeNotifications = false;
            },
            toggleSidebar(){
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
            },
            hideSidebar(){
                this.$sidebar.displaySidebar(false);
            },
            minimizeSidebar(){
                this.$sidebar.toggleMinimize();
            }
        }
    };

</script>
