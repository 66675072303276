import _ from "lodash";

export default {
    methods: {
        getSummaries(param) {
            const {columns, data} = param;
            const viewColumns = this.view.columns;
            const sums = [];
            columns.forEach((column, index) => {
                if ('FIELD_TYPE_NUMBER' !== column.type) {
                    return false;
                }

                const colIndex = _.findIndex(viewColumns, {name: column.property});
                const col = viewColumns[colIndex];

                if (!col.summable) {
                    return false;
                }

                const values = data.map(item => Number(item.data[column.property]));
                if (!values.every(value => isNaN(value))) {
                    const sum = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = Math.round(sum * 100) / 100;
                } else {
                    sums[index] = '';
                }
            });

            return sums;
        },

        /**
         * Workaround for filtered set pagination issue
         */
        updateLocalPagination() {
            const items = this.getTableWrapperRef().localData;
            const pagination = this.getTableWrapperRef().pagination;
            const total = items.length;
            const currentPage = 1;
            const pageSize = pagination.pageSize;
            const acceptMax = pagination.acceptMax;
            const count = pagination.count;
            const lastPage = Math.round(total / pageSize);
            this.pagination = {currentPage, pageSize, lastPage, acceptMax, total, count};
        },

        /**
         * Workaround for filtered set pagination issue
         */
        updateLocalSelectedFilters() {
            if (undefined === this.view || null === this.view || !_.has(this.view, 'filters')) {
                console.error('The view filters is empty');
                return;
            }

            const filters = this.view.filters;
            const table = this.getTableRef();
            if (!table) {
                return;
            }
            const store = table.store;
            const columns = store.states.columns;

            for (const columnKey in filters) {
                // treat numbers as integers
                let values = filters[columnKey];
                if (typeof values === "object" && typeof values.map === "function") {
                    values = values.map(value => parseInt(value) == value ? parseInt(value) : value);
                }
                const columnIndex = _.findIndex(columns, {columnKey});
                if (store.states.columns[columnIndex] !== undefined) {
                    store.states.columns[columnIndex]["filteredValue"] = values;
                }
            }
        },

        /**
         * Update tasks set (according to mode and current page)
         */
        updateData() {
            let tasks = this.tasks;
            if (tasks !== undefined) {
                this.data = [];
                tasks = _.uniqBy(tasks, 'id');
                if (this.isLocalMode) {
                    const page = this.paginationSet.currentPage;
                    const length = this.paginationSet.pageSize;
                    const end = page * length;
                    const offset = end - length;
                    this.data = [...tasks.slice(offset, end)];
                } else {
                    tasks.map(task => {
                        this.data.push(task)
                    });
                    this.data = tasks;
                    this.updateLocalSelectedFilters();
                }
            }
        },

        /**
         * Get column with by column type
         * @param {string} column
         * @param {string} type
         * @param {string} label
         * @return {int}|{string}
         */
        getColumnWidth(column, type, label) {
            const minWidth = label.length * 12 + 50;
            let width;
            if (['expired_at', 'created_at', 'closed_at', 'expiring_at'].includes(column) ||
                ['FIELD_TYPE_DATE'].includes(type)) {
                width = 150;
            } else if (['creator', 'performer', 'current_state'].includes(column) ||
                ['FIELD_TYPE_USER', 'FIELD_TYPE_OPTION'].includes(type)) {
                width = 180;
            } else if (column === 'id') {
                width = 90;
            }
            return (width && width < minWidth) ? minWidth : width;
        },

        onFilterChangeGenerator(filter) {
            if (this.isLocalMode) {
                return undefined;
            } else {
                return this.onFilterChangeInRemoteMode(filter)
            }
        },

        /**
         * When filter is changed
         * @param {object} filter
         */
        onFilterChangeInRemoteMode(filter) {
            this.states.filter = Object.assign(this.states.filter, filter);
            console.log('Filter', this.states.filter);
            this.fetch(this.states);
            this.updateLocalPagination();
        },

        /**
         * Get table instance
         */
        getTableWrapperRef() {
            return this.$refs['ll-table-wrapper'];
        },

        /**
         * Get table instance
         */
        getTableRef() {
            const wrapper = this.getTableWrapperRef();
            if (wrapper !== undefined) {
                return wrapper.$refs['ll-table'];
            }
            return undefined;
        },

        setTableFilters(filter) {
            const table = this.getTableRef();
            if (table) {
                const columns = table.store.states.columns;
                for (const column in filter) {
                    if (undefined !== column && _.has(filter, column)) {
                        const values = filter[column];
                        const tableColumn = columns.find(item => item.columnKey === column);
                        if (undefined !== tableColumn) {
                            tableColumn.filteredValue = values;
                        }
                    }
                }
            }
        },

        setTableSorting(sorting) {
            const table = this.getTableRef();
            if (table) {
                const columns = table.store.states.columns;
                for (const column in sorting) {
                    if (undefined !== column && _.has(sorting, column)) {
                        const direction = sorting[column];
                        const tableColumn = columns.find(item => item.columnKey === column);
                        if (undefined !== tableColumn) {
                            tableColumn.order = (direction === 'desc' ? 'descending' : 'ascending');
                        }
                    }
                }
            }
        },

        /**
         * When sorting is changed
         * @param {object} sorting
         */
        onSortChange(sorting) {
            if (_.has(this.states.sorting, 'column') && this.states.sorting.column === sorting.prop) {
                this.states.sorting.order = this.states.sorting.order === 'desc' ? 'asc' : 'desc';
            } else {
                this.states.sorting = {
                    order: sorting.order,
                    column: sorting.prop
                };
            }
            if (!this.isLocalMode) {
                this.fetch(this.states);
            }
        },

        onSortChangeInGroups(sorting) {
            console.log(sorting.order, sorting.prop)
            const newData = []
            _.forEach(this.tasksGrouped, group => {
                let reorderedGroup = _.sortBy(group, item => {
                    if (typeof item === 'object') {
                        return item.subject || item.title || item.name || item.id
                    }
                    return item
                })
                if (sorting.order !== 'ascending') {
                    reorderedGroup = _.reverse(reorderedGroup)
                }
                _.forEach(
                    reorderedGroup,
                    group => {
                        newData.push(group)
                    }
                )
            })
                console.log(newData.map(item => item[sorting.prop]))
            if (this.dataSource !== 'prop') {
                this.data = Object.assign([], newData)
            }
            this.updateLocalSelectedFilters()
        },

        /**
         * When page is changed
         * @param {int} page
         */
        onPageCurrentChange(page) {
            if (this.states.page !== page) {
                this.states['page'] = page;
                if (!this.isLocalMode) {
                    this.fetch({
                        page: this.states['page'],
                        filter: this.states['filter'],
                        sorting: this.states['sorting'],
                    });
                } else {
                    this.pagination.currentPage = page;
                    this.updateData();
                }
            }
        },

        /**
         * Get unique column value
         * @param column
         * @param textCallback
         * @param valueCallback
         * @returns {Array}
         */
        uniqueColumnValues(column, textCallback, valueCallback) {
            if (column !== undefined) {
                return _.uniqBy(
                    this.tasks.map(task => {
                        const text = textCallback ? textCallback(task[column]) : task[column];
                        const value = valueCallback ? valueCallback(task[column]) : text;
                        return {text, value};
                    }),
                    "text");
            }
        },

        /**
         * Cell class
         * @param column
         * @return {string}
         */
        cellClassName({column}) {
            return `column-${column.columnKey}`;
        },

        /**
         * Header cell class
         * @param column
         * @return {string}
         */
        headerCellClassName({column}) {
            const classes = [column.id, column.order, column.headerAlign, column.className, column.labelClassName];

            if (!column.children) {
                classes.push("is-leaf");
            }

            if (column.sortable) {
                classes.push("is-sortable");
            }

            const columnName = `column-${column.property}`;
            classes.push(columnName);

            return classes.join(" ");
        },

        /**
         * Row class
         * @param row
         * @param rowIndex
         * @return {string}
         */
        rowClassName({row}) {
            return (row.is_closed ? "closed" : "active")
                + (row.is_expired ? " expired"
                        : (row.is_expiring ? " expiring" : "")
                );
        },
    }
}