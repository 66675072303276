import {directive as vClickOutside} from 'vue-clickaway'
import VTooltip from 'v-tooltip'
import linkify from "vue-linkify";

const Directives = {
    install(Vue) {
        Vue.directive('click-outside', vClickOutside)
        Vue.use(VTooltip)
        Vue.directive('linkified', linkify)
    }
}

export default Directives
