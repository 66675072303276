<template>
    <div @keypress.enter="search" class="navbar-nav col mx-3">
            <el-input
                    placeholder="Поиск"
                    prefix-icon="el-icon-search"
                    :value="query"
                    v-model="searchQuery"
                    class="ml-4"
                    clearable
            >
              <el-select v-model="scope" slot="append"
                         :placeholder="scopeLabel"
                         @change="search"
                        v-if="isCategoryAvailable">
                <el-option value="category" :label="scopes.category"></el-option>
                <el-option value="everywhere" :label="scopes.everywhere"></el-option>
              </el-select>
            </el-input>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: "SearchInput",
    computed: {
      ...mapGetters("search", ["query", "redirectPath", "error"]),
      ...mapGetters("categories", {
        category: "loaded"
      }),
      scopes: state => ({
        category: state.category.title || 'Категория',
        everywhere: 'Везде',
      }),
      scopeLabel: state => state.scopes[state.scope],
      isCategoryAvailable: state => state.category !== undefined && state.category.id !== undefined,
      categoryId: state => !state.category ? null : state.category.id,
    },
    data: () => ({
      searchQuery: null,
      scope: 'category',
    }),
    methods: {
      reset() {
        this.$store.commit("search/SEARCH_RESET");
        this.searchQuery = null;
      },

      search() {
        const query = this.searchQuery;
        if (!query) {
          return;
        }
        const regExp = /^#([\d\w]+)\s*/;
        const matches = query.match(regExp);
        if (matches) {
          const taskId = matches[1];
          console.log(`Found task ID #${taskId} in query`);
          this.gotoTask(taskId);
        } else {
          console.log(`Cannot find task ID in query '${query}'. Treat as search text.`);
          this.gotoSearch(query);
        }
      },

      gotoSearch(query){
        const params = {query}
        if (this.categoryId && this.categoryId !== null && this.scope === 'category') {
          params['categoryId'] = this.categoryId
        }
        this.$store.commit('search/SEARCH_SET_QUERY', query);
        this.$store.dispatch('search/executeSearch', params);
        let baseUrl = '/search'
        if (this.categoryId) {
          baseUrl += `/${this.categoryId}`
        }
        const url = `${baseUrl}#${this.query}`
        this.$router.push(url);
      },

      gotoTask(taskId) {
        const self = this;
        this.$store.dispatch('search/checkTask', taskId)
        .then(({task_id, category_id}) => {
          const redirectPath = `/category/${category_id}/task/${task_id}`;
          self.$router.push(redirectPath);
          self.reset();
        })
        .catch(() => {
          self.$notify({
            icon: "fa fa-times",
            group: "SearchInput",
            title: "Задача с таким номером не найдена или у вас недостаточно прав для её просмотра",
            type: "danger"
          });
        });
      }
    },
    mounted() {
      const queryString = location.hash;
      if (queryString) {
        this.searchQuery = decodeURI(location.hash).replace(/^#/, '');
      }
    }
  }
</script>

<style>
.el-select .el-input .el-input__inner {
  width: auto;
  padding: 0 20px 0 10px;
  max-width: 137px;
  text-align: center;
}
</style>