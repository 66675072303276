import appConfig from "../config/app";
import telegramConfig from "../config/telegram";
import FileSize from "./FileSize";
import Echo from "laravel-echo";
import pusherConfig from "../config/pusher";
import {CoreApi} from "./HttpApi";

export default {
    install(Vue) {
        Vue.prototype.$appName = appConfig.name;
        Vue.prototype.$appVersion = appConfig.version;
        Vue.prototype.$taskRoute = (categoryId, taskId) => `/category/${categoryId}/task/${taskId}`;
        Vue.prototype.$telegramConfig = telegramConfig;

        // Vue.prototype.$moment = moment;

        Vue.prototype.$filesize = FileSize.stringify;

        Vue.prototype.$echo = window.Echo = new Echo({
            broadcaster: 'pusher',
            key: pusherConfig.key,
            cluster: pusherConfig.cluster,
            encrypted: pusherConfig.encrypted,
        });

        Vue.prototype.$api = CoreApi;
    }
}