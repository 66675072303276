<template>
  <div class="card presentation-board-card" @click="() => goto(task)">
    <ul class="list-group list-group-flush">
      <li class="list-group-item task-row" v-for="row in presentation.columns" :key="row.name"
          v-show="(row.type === 'field' && hasField(row.name)) || (row.type !== 'field' && ('closed_at' !== row.name || task.is_closed))"
      >

<!--        <div class="task-row-label" v-if="'brief' !== row.name">-->
<!--          {{ row.label }}-->
<!--        </div>-->

        <div class="task-row-column" :title="row.label">
<!--          {{row.name}}-->
<!--          {{ getFieldValue(task, row.name)}}-->
          <task-row-attribute
              readonly
              :task="task"
              :attribute="row.name"
              date-format="D.MM.Y"
              compact
              v-if="row.source === 'task'"
              :key="row.name"/>

          <task-row-field
              readonly
              :task="task"
              :field="getFieldByName(row.name)"
              v-if="row.source === 'field'"
              :key="row.name"/>
        </div>
      </li>
    </ul>

  </div>
</template>

<script>
import TaskRowAttribute from "../../TaskView/TaskRowAttribute";
import TaskRowField from "../../TaskView/TaskRowField";

export default {
  name: "PresentationBoardCard",
  components: {TaskRowField, TaskRowAttribute},
  props: {
    category: {
      type: Object
    },
    task: {
      type: Object
    },
    presentation: {
      type: Object
    },
    goto: {
      type: Function
    },
    getFieldValue: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    getFieldByName(name) {
      const fields = this.category.fields
      for (const field of fields) {
        if (field.name === name) {
          return field;
        }
      }

      return null;
    },

    hasField(name) {
      return null !== this.getFieldByName(name)
    },
  }
}
</script>

<style scoped>

</style>