import fgInput from '../components/Inputs/formGroupInput.vue'
import DropDown from '../components/Dropdown.vue'
import Card from '../components/Cards/Card.vue'
import {Input, InputNumber} from 'element-ui'
import {FormGroupInput as FgInput} from "../components";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import VueApexCharts from "vue-apexcharts";

const Components = {
  install (Vue) {
    Vue.component('apexchart', VueApexCharts);
    Vue.component('fg-input', fgInput)
    Vue.component('drop-down', DropDown)
    Vue.component('card', Card)
    Vue.component(Input.name, Input)
    Vue.component(InputNumber.name, InputNumber)
    Vue.component(FgInput);
    Vue.component('font-awesome-icon', FontAwesomeIcon);
  }
}

export default Components
