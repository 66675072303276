<template>
  <div class="notifications-page my-0">
    <div class="row">
      <div class="col" style="max-width: 600px">
        <h4 class="my-0">
          Уведомления
          <a class="float-right btn btn-link btn-xs" href="#" @click.prevent="markAllAsRead">Отметить все как прочитанные</a>
        </h4>
      </div>
    </div>
    <div class="row">

      <div class="col" style="max-width: 600px">
        <ul class=" list-group notifications-list">
          <notification-dropdown-item :notification="notification" class="list-group-item"
                                      v-for="notification of notifications" :key="notification.id"/>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col" style="max-width: 600px">
        <div class="col text-center">
          <button class="btn btn-link btn-block" @click="fetchMore" v-show="!fullyLoaded">Ещё...</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
import NotificationDropdownItem from '../components/Notifications/NotificationsDropdownItem'

export default {
    name: 'NotificationsPage',
    components: {NotificationDropdownItem},
    computed: mapGetters('notifications', ['notifications', 'fullyLoaded', 'pagination']),
    methods: {
      markAsRead (notification) {
        this.$store.dispatch('notifications/markAsRead', notification.id).catch();
      },
      markAllAsRead () {
        this.$store.dispatch('notifications/markAllAsRead').catch();
      },
      fetchMore () {
        if (!this.fullyLoaded) {
          this.$store.dispatch('notifications/fetch', this.pagination.currentPage + 1).catch();
        }
      },
      message ({message}) {
        return message.replace(/\[\[category-(\d+)\|task-(\d+)\|([^\]]+)\]\]/gm, '<a href="/#/category/$1/task/$2">$3</a>')
                      .replace(/\[\[category-(\d+)\|([^\]]+)\]\]/gm, '<a href="/#/category/$1">$2</a>')
      }
    },
    mounted () {
      this.$store.dispatch('notifications/fetch').catch();
  }
  }
</script>
