<template>
  <a @click="download(file)" v-bind="$attrs">
    <slot></slot>
  </a>
</template>

<script>
import {CoreApiArrayBuffer} from "@/lib/HttpApi";

export default {
  name: "DownloadLink",
  props: {
    file: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    async download(file) {
      console.log(`Downloading ${file.download_link}`);
      CoreApiArrayBuffer.get(file.download_link)
          .then(response => {
            console.log(response);
            let blob = new Blob([response.data], { type: response.headers['Content-Type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = file.name;
            link.click();
          });
    },
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>