import {CoreApi, handleError} from '../../lib/HttpApi';
import _ from 'lodash';

const NOTIFICATIONS_PAGINATION = 'NOTIFICATIONS_PAGINATION';
const NOTIFICATIONS_PUSHED = 'NOTIFICATIONS_PUSHED';

const NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT';
const NOTIFICATIONS_COUNT_SUCCESS = 'NOTIFICATIONS_COUNT_SUCCESS';
const NOTIFICATIONS_COUNT_FAILED = 'NOTIFICATIONS_COUNT_FAILED';

const NOTIFICATIONS_FETCH = 'NOTIFICATIONS_FETCH';
const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS';
const NOTIFICATIONS_FETCH_FAILED = 'NOTIFICATIONS_FETCH_FAILED';

const NOTIFICATIONS_MARK_AS_READ = 'NOTIFICATIONS_MARK_AS_READ';
const NOTIFICATIONS_MARKED_AS_READ = 'NOTIFICATIONS_MARKED_AS_READ';

const state = {
  collection: [],
  errors: [],
  count: 0,
  pagination: {
    loaded: false,
    perPage: 10,
    currentPage: 1,
    lastPage: null,
    total: null,
    from: null,
    to: null,
  },
};

const getters = {
  notifications: state => {
    return _.orderBy(
        _.uniqBy(state.collection, 'id'),
        ['id'], ['desc'],
    );
  },
  newNotifications: state => {
    return _.orderBy(
        _.uniqBy(
            state.collection.filter(notification => notification && _.has(notification, 'is_read') && !notification.is_read),
            'id',
        ), ['id'], ['desc'],
    );
  },
  count: state => state.count,
  errors: state => state.errors,
  pagination: state => state.pagination,
  fullyLoaded: state => state.pagination.loaded,
};

const actions = {

  subscribe({commit, dispatch}, userId) {
    if (userId) {
      const channel = `user-${userId}-notifications`;
      console.debug(`Event: [${channel}] subscribing...`);
      console.debug(`Subscribing for notifications on ${channel}`);
      const subscription = window.Echo.channel(channel);
      // commit(NOTIFICATIONS_SUBSCRIBED)
      // Listen for new notifications
      console.debug(`Event: [${channel}] waiting for event 'NotificationAddedEvent'...`);
      subscription.listen('.NotificationAddedEvent', notification => {
        console.debug(`Event: [${channel}] NotificationAdded fired`, notification);
        commit(NOTIFICATIONS_PUSHED, notification);
        dispatch('notifications/count', null, {root: true});
      });
      // Listen for read event
      console.debug(`Event: [${channel}] waiting for event 'NotificationReadEvent'...`);
      subscription.listen('.NotificationReadEvent', notification => {
        console.debug(`Event: [${channel}] NotificationReadEvent fired`, notification);
        commit(NOTIFICATIONS_MARKED_AS_READ, notification);
        dispatch('notifications/count', null, {root: true});
      });
    }
  },

  count: ({commit}) => {
    commit(NOTIFICATIONS_COUNT);
    CoreApi.get(`/notification/count`).then(({data}) => {
      commit(NOTIFICATIONS_COUNT_SUCCESS, data.count);
    }).catch(response => {
      const error = handleError(response);
      commit(NOTIFICATIONS_COUNT_FAILED, error);
    });
  },

  fetch: ({commit}, page) => {
    commit(NOTIFICATIONS_FETCH);
    CoreApi.get(`/notification?page=${page || 1}`).then(({data}) => {
      commit(NOTIFICATIONS_FETCH_SUCCESS, data.data);
      commit(NOTIFICATIONS_PAGINATION, data.meta);
    }).catch(response => {
      const error = handleError(response);
      commit(NOTIFICATIONS_FETCH_FAILED, error);
    });
  },

  markAsRead: ({commit, dispatch}, notificationId) => {
    commit(NOTIFICATIONS_MARK_AS_READ);
    CoreApi.post('/notification', {notifications: [notificationId]}).
        then(() => {
          dispatch('notifications/count', null, {root: true});
        });
  },

  markAllAsRead: ({commit, dispatch}) => {
    commit(NOTIFICATIONS_MARK_AS_READ);
    CoreApi.post('/notification').then(() => {
      dispatch('notifications/count', null, {root: true});
    });
  },

  readTaskNotifications: ({commit, dispatch}, taskId) => {
    if (taskId) {
      commit(NOTIFICATIONS_MARK_AS_READ);
      //console.log('[NOTIFICATIONS_MARK_AS_READ] ' + taskId);
      CoreApi.post('/notification/read_task', {task_id: taskId}).then(() => {
        dispatch('notifications/count', null, {root: true});
      });
    }
  },

  readTaskApprovalsNotifications: ({commit, dispatch}, taskId) => {
    if (taskId) {
      commit(NOTIFICATIONS_MARK_AS_READ);
      //console.log('[NOTIFICATIONS_MARK_AS_READ] ' + taskId);
      CoreApi.post('/notification/read_approvals', {task_id: taskId}).
          then(() => {
            dispatch('notifications/count', null, {root: true});
          });
    }
  },

  readTaskAttachmentsNotifications: ({commit, dispatch}, taskId) => {
    if (taskId) {
      commit(NOTIFICATIONS_MARK_AS_READ);
      //console.log('[NOTIFICATIONS_MARK_AS_READ] ' + taskId);
      CoreApi.post('/notification/read_attachments', {task_id: taskId}).
          then(() => {
            dispatch('notifications/count', null, {root: true});
          });
    }
  },

  readTaskCommentsNotifications: ({commit, dispatch}, taskId) => {
    if (taskId) {
      commit(NOTIFICATIONS_MARK_AS_READ);
      //console.log('[NOTIFICATIONS_MARK_AS_READ] ' + taskId);
      CoreApi.post('/notification/read_comments', {task_id: taskId}).
          then(() => {
            dispatch('notifications/count', null, {root: true});
          });
    }
  }
};

const mutations = {

  [NOTIFICATIONS_PAGINATION](state, pagination) {
    if (undefined === pagination) {
      console.log('Cannot find pagination data in response with notifications from API');
      return ;
    }
    const currentPage = pagination.current_page ?? 1;
    const lastPage = pagination.last_page ?? 1;
    const perPage = pagination.per_page ?? 20;
    state.pagination = {
      loaded: currentPage >= lastPage,
      perPage: perPage,
      currentPage: currentPage,
      lastPage: lastPage,
      total: pagination.total,
      from: pagination.from,
      to: pagination.to,
    };
  },

  [NOTIFICATIONS_COUNT](state) {
    state.errors = [];
  },

  [NOTIFICATIONS_COUNT_SUCCESS](state, count) {
    state.count = count;
    state.errors = [];
  },

  [NOTIFICATIONS_COUNT_FAILED](state, error) {
    state.errors.push(error);
  },

  [NOTIFICATIONS_PUSHED](state, notification) {
    state.collection.push(notification);
    state.errors = [];
  },

  [NOTIFICATIONS_FETCH](state) {
    state.errors = [];
  },

  [NOTIFICATIONS_FETCH_SUCCESS](state, notifications) {
    state.collection.push(...notifications);
    state.errors = [];
  },

  [NOTIFICATIONS_FETCH_FAILED](state, error) {
    state.errors.push(error);
  },

  [NOTIFICATIONS_MARK_AS_READ]() {
  },

  [NOTIFICATIONS_MARKED_AS_READ](state, notification) {
    //console.log('NOTIFICATIONS_MARKED_AS_READ', notification);
    const item = _.find(state.collection, {id: notification.id});
    if (item) {
      item['is_read'] = true;
    }
    state.collection = [...state.collection];
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
