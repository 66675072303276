<template>
  <div>
    <h5 class="alert alert-info small" v-if="debugMode">
      suggestMode: {{ suggestMode }}<br>
      selected: {{ selected }}<br>
      query: {{ query }}<br>
      name: {{ name }}<br>
      readonly: {{ readonly }}<br>
      selectedObject: {{ selectedObject }}<br>
      selected: {{ selected }}<br>
    </h5>
    <select v-if="!suggestMode" v-model="selected" class="custom-select" @change="onSelect" :tabindex="tabindex"
            :aria-readonly="disabled || readonly"
            :readonly="disabled || readonly"
            :disabled="disabled || readonly">
      <option value="" selected="selected">{{ placeholder ? placeholder : "" }}</option>
      <option v-for="task of sortedTasksOfCategory" :key="task.id" :value="task.id">{{ task.subject }}
        (#{{ task.id }})
      </option>
    </select>

    <div v-else class="autocomplete">
      <div class="input">
        <input v-model="query" :placeholder="placeholder"
               autocomplete="off"
               @keyup="search"
               class="custom-select"
               :tabindex="tabindex"
               :name="name"
               :aria-readonly="disabled || readonly"
               :readonly="disabled || readonly"
               :disabled="disabled || readonly"
               :required="required"/>

        <div class="input-group-icon" style="background: none">
          <i class="fa fa-spin fa-spinner" v-show="isLoading"/>
        </div>
      </div>

      <div
          v-show="showDropdown && !isQueryEmpty() && results.length"
          v-click-outside="onClickOutside"
          class="dropdown-menu show"
          style="margin-top: -1px;"
      >
        <a
            class="dropdown-item"
            v-for="task in results"
            :key="task.id"
            @click="pick(task)">
          {{task.subject }} (#{{ task.id }})
        </a>
        <a class="dropdown-item disabled" v-if="hasMore">...</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "TaskSelect",
  props: ["categoryId", "placeholder", "default", "sortBy", "predefinedValue", "filterByStates",
    "activeTasksOnly", "tabindex", "required", "name", "disabled"],
  data: () => ({
    query: null,
    selected: "",
    selectedObject: null,
    isLoading: false,
    readonly: false,
    hasMore: false,
    meta: {},
    results: [],
    totalCount: null,
    suggestMode: null,
    showDropdown: false,
  }),
  computed: {
    ...mapGetters("tasks", ["tasks"]),
    debugMode: state => state.$route.query.debug,
    tasksOfCategory: state => state.tasks.filter(task => task.category_id === state.categoryId),
    sortedTasksOfCategory: state => _.uniq(state.tasksOfCategory).sort((a, b) => {
      const field = state.sortBy;
      const aValue = _.has(a, field) ? a[field] : a.id;
      const bValue = _.has(b, field) ? b[field] : b.id;
      if (aValue > bValue) {
        return 1;
      }
      if (aValue < bValue) {
        return -1;
      }
      return 0;
    }),
  },
  methods: {
    ...mapActions("task", ["silentFetch"]),

    isQueryEmpty() {
      return this.query === '' || this.query === null
    },

    onSelect() {
      const selected = _.find(this.tasks, {
        id: this.selected
      });
      if (selected) {
        this.emitResult(selected.id);
      }
    },

    search() {
      if (!this.query || this.query.length < 2) {
        return;
      }
      this.results = [];
      this.fetch((data, meta, context) => {
        if (meta.count > 0) {
          context.showDropdown = true;
        }
      });
    },

    fetch(callback) {
      const self = this;
      if (!self.categoryId) {
        return;
      }

      self.isLoading = true;

      const params = {category_id: self.categoryId};

      if (self.activeTasksOnly) {
        params['only'] = 'active';
      }

      if (self.filterByStates) {
        params['current_states_ids'] = self.filterByStates.map(state => state.id);
      }

      if (self.query) {
        params['query'] = self.query;
      }
      console.log(params);

      self.$store.dispatch("tasks/fetchCategoryTasks", params)
          .then(({data, meta}) => {
            self.results = data;
            self.meta = meta;
            if (typeof callback === "function") {
              callback(data, meta, self);
            }
            self.isLoading = false;
          })
          .catch(() => {
            self.isLoading = false;
          });
    },

    setQueryToSelectedObject() {
      this.query = `${this.selectedObject.subject} (#${this.selected})`;
    },

    pick(task) {
      this.selectedObject = task;
      this.selected = task.id;
      this.emitResult(this.selected);
      this.setQueryToSelectedObject();
      this.isLoading = false;
      this.showDropdown = false;
    },

    emitResult(result) {
      this.$emit('input', result);
      this.$emit('selected', result);
    },

    reset() {
      this.isLoading = false;
      this.readonly = false;
      this.hasMore = false;
      this.query = null;
    },

    onClickOutside() {
      if (this.showDropdown && !this.disabled && !this.readonly) {
        this.reset()
      }
    },

    async init() {
      if (this.predefinedValue) {
        this.selected = this.predefinedValue;
        this.selectedObject = await this.silentFetch(this.predefinedValue)
        this.setQueryToSelectedObject()
        this.emitResult(this.predefinedValue)
      } else {
        this.selected = this.default ? this.default.id : null;
      }
    }
  },

  async created() {
    this.fetch((data, meta, context) => {
      context.totalCount = meta.total_count;
      context.suggestMode = meta.total_count > meta.count;
      context.init()
    });
  },
  mounted() {
    this.init()
  }
};
</script>
