<template>
    <ul class="task-field-list">
      <field-item v-for="field of items"
                    :field="field" :data="data(field)"
                    :read-only="!allowEdit || isReadOnly(field)"
                    :key="field.name"
                    :task="task"
                    :highlight-query="highlightQuery"/>
    </ul>
</template>

<script>
  import FieldItem from "./FieldItem/FieldItem.vue";
  import _ from "lodash";
  export default {
    name: "FieldList",
    components: {FieldItem},
    props: {
      task: {},
      allowEdit: {
        type: Boolean,
        default: () => true
      },
      highlightQuery: {
        type: String,
        default: ''
      },
    },
    computed: {
      items: state => state.task.fields
    },
    methods: {
      data(field) {
        return _.has(field, 'name') && _.has(this.task, 'data') && _.has(this.task.data, field.name)
            ? this.task.data[field.name]
            : null;
      },

      isReadOnly(field) {
        if (field.type === 'FIELD_TYPE_TASK_RELATION') {
          const subtaskRule = field.subtask_rule;
          if (subtaskRule.multiple === false) {
            return true;
          }
          if (undefined !== subtaskRule.subtask_category_states && null !== subtaskRule.subtask_category_states) {
            const currentStateId = this.task.current_state_id;
            let subtaskStateIsCompatible = false;
            for (const state of subtaskRule.subtask_category_states) {
              if (state.id === currentStateId) {
                subtaskStateIsCompatible = true;
              }
            }
            if (!subtaskStateIsCompatible) {
              return true; // readonly
            }
          }
        }

        return false;
      }
    }
  };
</script>
