// fontawesome
import {library} from "@fortawesome/fontawesome-svg-core";
import { dom } from '@fortawesome/fontawesome-svg-core'
import {
    faBell,
    faCheck,
    faDownload,
    faEdit,
    faFile,
    faFileAlt,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faGlobe,
    faInfo,
    faPen,
    faTimes,
    faPlus,
    faPlusSquare,
    faSave, faSearch,
    faSignOutAlt,
    faSpinner,
    faTrash,
    faQuestionCircle,
    faUndo,
    faBars,
    faEllipsisV,
    faChevronRight,
    faChevronDown
} from '@fortawesome/free-solid-svg-icons';

// set up font awesome
library.add(faPlusSquare);
library.add(faPlus);
library.add(faPen);
library.add(faTimes);
library.add(faEdit);
library.add(faSave);
library.add(faSignOutAlt);
library.add(faCheck);
library.add(faBell);
library.add(faSpinner);
library.add(faGlobe);
library.add(faInfo);
library.add(faTrash);
library.add(faDownload);
library.add(faFile);
library.add(faFileAlt);
library.add(faFileWord);
library.add(faFileExcel);
library.add(faFileVideo);
library.add(faFileImage);
library.add(faFilePowerpoint);
library.add(faFilePdf);
library.add(faSearch);
library.add(faQuestionCircle);
library.add(faUndo);
library.add(faBars);
library.add(faEllipsisV);
library.add(faChevronRight);
library.add(faChevronDown);

dom.watch();
