export default {
    methods: {
        makeTransition(transitionId, taskId, successCallback, errorCallback) {
            const self = this;
            self.$store.dispatch("task/transit", {
                task_id: taskId,
                transition_id: transitionId
            })
                .then(task => {
                    const message = `Задача переведена в статус «${task.current_state.title}»`;
                    self.$notify({
                        icon: "fa fa-check",
                        group: "TaskTransitionActions",
                        title: message,
                        type: "success"
                    });
                    if (typeof successCallback === 'function') {
                        successCallback()
                    }
                })
                .catch(({error, validationErrors}) => {
                    if (validationErrors) {
                        for (const field in validationErrors) {
                            const fieldErrorData = validationErrors[field];
                            const error = fieldErrorData.messages.join(' ');
                            self.$notify({
                                icon: "fa fa-times",
                                group: "TaskTransitionActions",
                                title: error,
                                type: "danger"
                            });
                        }
                    } else {
                        self.$notify({
                            icon: "fa fa-times",
                            group: "TaskTransitionActions",
                            title: error,
                            type: "danger"
                        });

                        self.$store.dispatch("task/silentFetch", taskId).catch();
                    }
                    if (typeof errorCallback === 'function') {
                        errorCallback()
                    }
                });
        },
    }
}