<template>
  <div class="content">
    <div class="container-fluid px-1">
<!--      <transition name="router-transition">-->
        <!-- your content here -->
        <router-view :key="$route.fullPath"/>
<!--      </transition>-->
    </div>
  </div>
</template>
<script>
  export default {}
</script>

<style>
  .router-transition-enter-active {
    animation: coming .025s ease-in-out;
    animation-delay: .025s;
    opacity: 0;
  }

  .router-transition-leave-active {
    animation: going .025s ease-in-out;
  }

  @keyframes going {
    from {
    }
    to {
      opacity: 0;
    }
  }

  @keyframes coming {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

</style>
